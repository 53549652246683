import {
  Badge,
  Box,
  Button,
  Checkbox,
  Divider,
  Flex,
  FormControl,
  Heading,
  IconButton,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  StackDivider,
  Text,
  Textarea,
  Tooltip,
  useDisclosure,
  useToast,
  VStack,
} from '@chakra-ui/react'
import moment from 'moment'
import { ChangeEvent, useCallback, useEffect, useRef, useState } from 'react'
import { BsTrash } from 'react-icons/bs'
import { FiExternalLink } from 'react-icons/fi'
import { MdCall, MdDateRange, MdEdit } from 'react-icons/md'
import { NavLink, useHistory } from 'react-router-dom'
import { DEFAULT_MOOD_SCORE } from '../../app/constants'

import {
  useDeleteEventMutation,
  useGetSessionNotesMutation,
  usePostSessionNoteMutation,
  useUpdateSessionNoteMutation,
} from '../../app/services/api'
import { useLocale } from '../../hooks/useLocale'
import { PostSessionNoteRequest } from '../../types/api'
import { parseText } from '../../utils/string'
import MoodInput from '../profile/MoodInput'
import CallLink from './CallLink'
import GoogleMeetLink from './GoogleMeetLink'
import MicrosoftMeetLink from './MicrosoftMeetLink'

/**
 * Event component shown in the calendar when slot is selected
 */
const Event = ({
  userId,
  isOpen,
  onClose,
  event,
  onCallOpen,
  handleReschedule,
  guest,
  setGuest,
  timezone,
}: any) => {
  const { t } = useLocale()
  const history = useHistory()
  const toast = useToast()
  const {
    isOpen: isNoteOpen,
    onOpen: onNoteOpen,
    onClose: onNoteClose,
  } = useDisclosure()
  const [formState, setFormState] = useState<PostSessionNoteRequest>({
    userId: '',
    eventId: event && event.id,
    body: '',
    userShowedUp: true,
    userMoodScore: DEFAULT_MOOD_SCORE,
  })
  const [updating, setUpdating] = useState(false)
  const [userShowedUp, setUserShowedUp] = useState(false)
  const [noteId, setNoteId] = useState<string | null>(null)
  const [deleteEvent] = useDeleteEventMutation()
  const [getSessionNotes, { data: getSessionNotesData, isLoading }] =
    useGetSessionNotesMutation()
  const [postSessionNotes] = usePostSessionNoteMutation()
  const [updateSessionNotes] = useUpdateSessionNoteMutation()
  const [deleteSessionNotes] = useDeleteEventMutation()

  const initialRef = useRef() as any

  const retrieveSessionNotes = useCallback(async () => {
    if (guest) await getSessionNotes({ userId: guest._id, eventId: event.id })
  }, [getSessionNotes, guest, event])

  useEffect(() => {
    if (!event || !event.user) return
    const guest = event.user
    setFormState((prev) => ({
      ...prev,
      eventId: event.id,
      userId: guest._id,
      userShowedUp: event.userShowedUp,
    }))
    setUserShowedUp(event.userShowedUp)
    if (guest && guest.profile) setGuest(guest)
    retrieveSessionNotes()
  }, [event, userId, setGuest, retrieveSessionNotes])

  const parseEventTime = (start: Date, end: Date) => {
    const startMoment = moment(start)
    const endMoment = moment(end)
    return `${startMoment.format('dddd, MMMM D ⋅ h:mm')} - ${endMoment.format(
      'h:mma'
    )}`
  }

  const onCancel = useCallback(
    async (ev: any) => {
      try {
        await deleteEvent(event.id).unwrap()
        toast({
          status: 'success',
          title: 'Success',
          description: 'The session is successfully cancelled!',
          isClosable: true,
        })
        history.go(0)
      } catch (err) {
        console.log(err)
        toast({
          status: 'error',
          title: t('error'),
          description: t('there_was_an_error'),
          isClosable: true,
        })
      }
    },
    [event, history, toast, t, deleteEvent]
  )

  const onEdit = useCallback(
    (e: any) => {
      if (getSessionNotesData) {
        setNoteId(getSessionNotesData[0]._id)
        setFormState((prev: any) => ({
          ...prev,
          userShowedUp,
          userMoodScore: getSessionNotesData[0].userMoodScore,
          body: getSessionNotesData[0].body.text,
        }))
        setUpdating(true)
        onNoteOpen()
      }
    },
    [onNoteOpen, getSessionNotesData, userShowedUp]
  )

  const handleNoteClose = useCallback(() => {
    setNoteId(null)
    setFormState((prev) => ({
      ...prev,
      body: '',
      userMoodScore: DEFAULT_MOOD_SCORE,
      userShowedUp: true,
    }))
    setUpdating(false)
    onNoteClose()
  }, [onNoteClose, setUpdating, setFormState, setNoteId])

  const onNoteSubmit = useCallback(
    async (event) => {
      event.preventDefault()
      try {
        if (updating) {
          const updatedNote = {
            id: noteId!,
            body: formState,
          }
          await updateSessionNotes(updatedNote).unwrap()
        } else {
          await postSessionNotes(formState).unwrap()
        }
        setUserShowedUp(formState.userShowedUp || false)
        await retrieveSessionNotes()
        handleNoteClose()
        toast({
          status: 'success',
          description:
            'The note is successfully ' + (updating ? 'updated' : 'added'),
          isClosable: true,
        })
        setUpdating(false)
      } catch (err) {
        console.log(err)
        toast({
          status: 'error',
          title: 'Error',
          description: 'Error while saving a note',
          isClosable: true,
        })
      }
    },
    [
      noteId,
      retrieveSessionNotes,
      setUpdating,
      toast,
      formState,
      updating,
      postSessionNotes,
      updateSessionNotes,
      handleNoteClose,
    ]
  )

  const handleChange = ({ target: { name, value } }: ChangeEvent<any>) =>
    setFormState((prev: any) => ({ ...prev, [name]: value }))

  const onShowedUpChange = useCallback(
    (ev: ChangeEvent<any>) => {
      setFormState((prev: any) => ({
        ...prev,
        userShowedUp: ev.target.checked,
      }))
    },
    [setFormState]
  )

  const onMoodChange = useCallback(
    (mood) => {
      setFormState((prev: any) => ({ ...prev, userMoodScore: mood }))
    },
    [setFormState]
  )

  useEffect(() => {
    if (getSessionNotesData && getSessionNotesData.length > 0) {
      const note = getSessionNotesData[0]
      setFormState((prev: any) => ({
        ...prev,
        body: note.body.text,
        userShowedUp,
        userMoodScore: note.userMoodScore,
      }))
    }
  }, [getSessionNotesData, setFormState, userShowedUp])

  if (!event) {
    return null
  }

  const notesExist = getSessionNotesData && getSessionNotesData.length > 0
  const isPast = event.start < new Date()

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      isCentered
      motionPreset="scale"
      size={'lg'}
      trapFocus={false}
    >
      <ModalOverlay />
      <ModalContent>
        <Tooltip label="Reschedule this event">
          <IconButton
            onClick={handleReschedule}
            icon={<MdEdit />}
            aria-label="edit"
            variant="ghost"
            size={'lg'}
            position="absolute"
            top={'0px'}
            right={'72px'}
            _hover={{
              borderRadius: '40%',
              bg: 'gray.200',
            }}
          />
        </Tooltip>
        <Tooltip label="Remove this from calendar">
          <IconButton
            onClick={onCancel}
            icon={<BsTrash />}
            aria-label="delete"
            variant="ghost"
            size={'lg'}
            position="absolute"
            top={'1px'}
            right={'40px'}
            _hover={{
              borderRadius: '40%',
              bg: 'gray.200',
            }}
          />
        </Tooltip>
        <Tooltip label="Close">
          <ModalCloseButton />
        </Tooltip>
        <ModalBody pb={0}>
          <Box maxW="md" overflow="hidden">
            <Box m="2" as="a">
              <Heading m="5" mb="0" as="h4" size="md">
                <Tooltip label="Click to view customer profile and notes">
                  <NavLink
                    to={`/coach/customer/${guest && guest._id}`}
                    target="_blank"
                  >
                    <Flex>
                      <Text
                        _hover={{
                          textDecoration: 'underline',
                        }}
                      >
                        {event.title}
                      </Text>
                      <FiExternalLink
                        size="1.1rem"
                        style={{
                          marginTop: '3px',
                          marginLeft: '5px',
                        }}
                      />
                    </Flex>
                  </NavLink>
                </Tooltip>
              </Heading>
              <Text m="5" mt="3px" fontSize={'sm'}>
                {parseEventTime(event.start, event.end)}
              </Text>
              <Box ml={5}>
                <CallLink event={event} buttonSize={'sm'} />
              </Box>
              {/* List session notes with header */}
              {isPast && (
                <Box ml="5" mt="10">
                  <Flex
                    width={'full'}
                    my={0}
                    justifyContent={'space-between'}
                    mb={1}
                  >
                    <Heading size={'md'} mt={1}>
                      Session notes (shared with client)
                    </Heading>
                    {/* Disable button if event.start date is in the future */}
                    <Button
                      mr={0}
                      variant="outline"
                      onClick={notesExist ? onEdit : onNoteOpen}
                    >
                      {notesExist ? 'Edit' : 'Add'}
                    </Button>
                  </Flex>
                  <Divider />
                  {getSessionNotesData && getSessionNotesData.length > 0 ? (
                    <VStack
                      divider={<StackDivider borderColor="gray.200" />}
                      spacing={4}
                      align="stretch"
                    >
                      {getSessionNotesData.map((note: any) => (
                        <Flex my={5} mx={2} key={note._id}>
                          <Text>{parseText(note.body.text)}</Text>
                        </Flex>
                      ))}
                    </VStack>
                  ) : (
                    <Box textAlign="center" py={10} px={6}>
                      <Text fontSize="18px" mt={3} mb={2}>
                        No session notes yet.
                      </Text>
                    </Box>
                  )}
                </Box>
              )}
            </Box>
          </Box>
        </ModalBody>
      </ModalContent>
      <Modal
        initialFocusRef={initialRef}
        isOpen={isNoteOpen}
        onClose={handleNoteClose}
        size="lg"
      >
        <form onSubmit={onNoteSubmit}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>
              {updating
                ? t('update_session_note')
                : t('create_new_session_note')}
            </ModalHeader>
            <ModalCloseButton />
            <ModalBody pb={6}>
              <FormControl>
                <Checkbox
                  name="userShowedUp"
                  checked={formState.userShowedUp}
                  defaultChecked={formState.userShowedUp}
                  onChange={onShowedUpChange}
                >
                  User showed up
                </Checkbox>
              </FormControl>
              {formState.userShowedUp && (
                <>
                  <FormControl my={5}>
                    <MoodInput
                      name="userMoodScore"
                      label="How was the user's mood?"
                      min={1}
                      max={5}
                      step={1}
                      defaultValue={
                        formState.userMoodScore || DEFAULT_MOOD_SCORE
                      }
                      onChange={onMoodChange}
                    />
                  </FormControl>
                  <FormControl>
                    <Textarea
                      ref={initialRef}
                      value={formState.body}
                      placeholder={`Session notes (shared with client) ...`}
                      name="body"
                      onChange={handleChange}
                      size="lg"
                      height={'full'}
                      rows={8}
                    />
                  </FormControl>
                </>
              )}
            </ModalBody>

            <ModalFooter>
              <Button onClick={handleNoteClose} mr={3}>
                Cancel
              </Button>
              <Button colorScheme="green" type="submit" onClick={onNoteSubmit}>
                {updating ? 'Update' : 'Save'}
              </Button>
            </ModalFooter>
          </ModalContent>
        </form>
      </Modal>
    </Modal>
  )
}

export default Event
