import { Box } from "@chakra-ui/react"

import { MyCalendar } from "./MyCalendar"

export const CalendarConnection = () => {
  return (
    <Box>
      <MyCalendar />
    </Box>
  )
}
