import { Redirect, Route, RouteProps } from 'react-router-dom'
import { useAuth } from '../hooks/useAuthCoach'

export function PrivateRouteCoach({ children, ...rest }: RouteProps) {
  const { coach } = useAuth()

  return (
    <Route
      {...rest}
      render={({ location }) =>
        coach ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: '/coach-login',
              state: { from: location },
            }}
          />
        )
      }
    />
  )
}
