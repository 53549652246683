import {
  Button,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Input,
  Link,
  Stack,
  Text,
  useColorModeValue,
  useToast,
  VStack,
} from '@chakra-ui/react'
import { ChangeEvent, useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import * as yup from 'yup'

import {
  api,
  useLazyGetOnboardingQuestionsQuery,
} from '../../../app/services/api'
import { useLocale } from '../../../hooks/useLocale'
import { useMixpanel } from '../../../utils/MixpanelContext'
import { setOnboardingData, setPreOnboardingData } from '../onboardingSlice'

interface BasicInfo {
  name: string
  email: string
}

const InitialStepBasicInfo = ({
  language,
  companyId,
  setTotalSteps,
  onProceedNextStep,
}: any) => {
  const { t } = useLocale()
  const dispatch = useDispatch()
  const { name, email } = useSelector((state: any) => state.onboarding)

  const toast = useToast()
  const mixpanel = useMixpanel()

  const formSchema = yup.object({
    name: yup.string().required(t('name_required')),
    email: yup.string().email(t('invalid_email')).required(t('email_required')),
  })
  const [errorMessage, setErrorMessage] = useState('')
  const [showError, setShowError] = useState(false)

  const [formState, setFormState] = useState<BasicInfo>({
    name,
    email,
  })
  const [isButtonDisabled, setIsButtonDisabled] = useState<boolean>(false)

  const [
    triggerGetSurveyQuestions,
    { data: surveyResult, error: surveyError, isLoading: surveyLoading },
  ] = useLazyGetOnboardingQuestionsQuery()

  useEffect(() => {
    mixpanel.track('onboarding_survey_questions_open', {
      page: 'basic_info',
      step: 1,
    })
  }, [mixpanel])

  const handleChange = ({
    target: { name, value },
  }: ChangeEvent<HTMLInputElement>) =>
    setFormState((prev) => ({ ...prev, [name]: value }))

  useEffect(() => {
    console.log('surveyResult:', surveyResult)
    if (!surveyResult) return
    if (surveyResult.isError) {
      toast({
        status: 'error',
        title: t('survey_fetch_error'),
        description: t('survey_fetch_error_detail'),
        isClosable: true,
      })
      mixpanel.track('onboarding_survey_fetch_error', {
        message: surveyResult.error?.message,
      })
      console.log(surveyResult.error)
    }
    const { questions } = surveyResult
    if (questions?.length === 0) {
      console.log('No questions found')
      mixpanel.track('onboarding_survey_fetch_error', { questionsCount: 0 })
    }
    setTotalSteps(questions.length + 1)
    dispatch(setOnboardingData(surveyResult))
    onProceedNextStep()
    mixpanel.track('onboarding_survey_fetch_success', {
      questionCount: questions?.length,
    })
  }, [surveyResult, surveyError, surveyLoading])

  const onSubmit = useCallback(
    async (event) => {
      event.preventDefault()
      if (isButtonDisabled) {
        return
      }
      try {
        setShowError(false)
        formSchema.validateSync(formState)
      } catch (err: any) {
        const errorMessage = err.errors.join(', ')
        setErrorMessage(errorMessage)
        setShowError(true)
        return
      }
      try {
        setIsButtonDisabled(true)

        const userExistsPromise: any = dispatch(
          api.endpoints.userExists.initiate(formState.email)
        )
        const userExistsResult = await userExistsPromise
        userExistsPromise.unsubscribe()

        console.log('userExistsResult:', userExistsResult)

        // if userExistsResult returns 400 error toast message
        if (userExistsResult.error) {
          // Scenario #0: Email is invalid
          toast({
            status: 'error',
            title: t('error'),
            description: userExistsResult.error.data?.message,
            isClosable: true,
          })
        } else if (userExistsResult.data?.exists) {
          // Scenario #1: User with that email already exists.
          toast({
            status: 'error',
            title: t('error'),
            description: t('user_already_registered'),
            isClosable: true,
          })
          mixpanel.track('onboarding_email_already_registered', {
            source: '/user_exists endpoint',
          })
        } else {
          dispatch(
            setPreOnboardingData({
              name: formState.name,
              email: formState.email,
              language,
              companyId,
            })
          )
          triggerGetSurveyQuestions({
            companyId,
            language,
          })
        }
      } catch (err) {
        toast({
          status: 'error',
          title: t('error'),
          description: t('there_was_an_error'),
          isClosable: true,
        })
      }
      setIsButtonDisabled(false)
    },
    [
      triggerGetSurveyQuestions,
      formState,
      isButtonDisabled,
      toast,
      companyId,
      t,
      dispatch,
      language,
      formSchema,
      mixpanel,
    ]
  )
  return (
    <Flex flexDir={'column'} align={'center'} h="100vh">
      <VStack my={6} mx={12} maxW={'2xl'}>
        <Heading fontSize={'2xl'} textAlign={'center'}>
          {t('full-onboarding-step1-welcome1')}
        </Heading>
        <Text py={2} fontSize={'lg'} color={'gray.600'} align={'center'}>
          {t('full-onboarding-step1-welcome2')}
        </Text>
      </VStack>
      <Flex py={4} maxW={'2xl'} bg={useColorModeValue('gray.50', 'gray.800')}>
        <form onSubmit={onSubmit}>
          <Stack spacing={4} mx={'auto'} maxW={'lg'} py={6} px={6}>
            <FormControl id="name">
              <FormLabel>{t('name')}</FormLabel>
              <Input
                onChange={handleChange}
                name="name"
                value={formState.name}
                type="text"
                minW={'sm'}
                placeholder={t('name')}
                bgColor="white"
              />
            </FormControl>
            <FormControl id="email">
              <FormLabel>{t('email')}</FormLabel>
              <Input
                onChange={handleChange}
                name="email"
                value={formState.email}
                type="text"
                placeholder={t('email')}
                bgColor="white"
              />
            </FormControl>
          </Stack>
          <Stack spacing={5} pt={5} pb={3} align={'center'}>
            {showError && errorMessage ? (
              <Text color={'red.600'}>{errorMessage}</Text>
            ) : (
              <></>
            )}
            <Button
              disabled={isButtonDisabled}
              colorScheme="primary"
              onClick={onSubmit}
              minW={'sm'}
              type="submit"
            >
              {t('continue')}
            </Button>
            {/* Ask user if already have an account */}
            <Text fontSize={'md'} color={'gray.600'}>
              {t('already_have_account')}{' '}
              <Link color={'blue.500'} href="/login">
                {t('sign_in_here')}
              </Link>
            </Text>
          </Stack>
        </form>
      </Flex>
    </Flex>
  )
}

export default InitialStepBasicInfo
