export const GroupedCoachingTags = [
  {
    label: 'Work',
    options: [
      { label: 'Managing Others', value: 'managing_others' },
      { label: 'Career Goals', value: 'career_goals' },
      { label: 'Burnout', value: 'burnout' },
      { label: 'Time Management', value: 'time_management' },
      { label: 'Focus/Productivity', value: 'focus_productivity' },
      { label: 'Work-Life Balance', value: 'work_life_balance' },
      { label: 'Role Transition', value: 'role_transition' },
      { label: 'Workplace Pressure', value: 'workplace_pressure' },
      { label: 'Staying Motivated', value: 'staying_motivated' },
    ],
  },
  {
    label: 'Relationships',
    options: [
      { label: 'Communication', value: 'communication' },
      { label: 'Conflict Resolution', value: 'conflict_resolution' },
      { label: 'Romantic Breakup', value: 'romantic_breakup' },
      { label: 'Marriage Conflict', value: 'marriage_conflict' },
      {
        label: 'Improving Relationship(s)',
        value: 'improving_relationships',
      },
    ],
  },
  {
    label: 'Self',
    options: [
      { label: 'Self-Esteem', value: 'self_esteem' },
      { label: 'Personal Growth', value: 'personal_growth' },
      { label: 'Self-Criticism', value: 'self_criticism' },
      { label: 'Procrastination', value: 'procrastination' },
      { label: 'Perfectionism', value: 'perfectionism' },
      { label: 'Coping Skills', value: 'coping_skills' },
      { label: 'Personal Stressors', value: 'personal_stressors' },
      { label: 'Identity Struggles', value: 'identity_struggles' },
      { label: 'Life Transitions', value: 'life_transitions' },
      { label: 'Sleep Problems', value: 'sleep_problems' },
      { label: 'Self-care', value: 'self_care' },
    ],
  },
  {
    label: 'Emotional Health',
    options: [
      { label: 'Emotion Regulation', value: 'emotion_regulation' },
      { label: 'Anxiety', value: 'anxiety' },
      { label: 'Low Mood', value: 'low_mood' },
      { label: 'Emotional Exhaustion', value: 'emotional_exhaustion' },
    ],
  },
  {
    label: 'Family',
    options: [
      {
        label: 'Caregiving Responsibilities',
        value: 'caregiving_responsibilities',
      },
      { label: 'Parenting', value: 'parenting' },
      {
        label: 'Balancing Different Roles',
        value: 'balancing_different_roles',
      },
    ],
  },
]

export const UsedTechniques = [
  { label: 'Cognitive restructuring', value: 'cognitive_restructuring' },
  {
    label: 'Challenging unhelpful thoughts',
    value: 'challenging_unhelpful_thoughts',
  },
  { label: 'Positive self talk', value: 'positive_self_talk' },
  { label: 'Psychoeducation', value: 'psychoeducation' },
  { label: 'Emotional validation', value: 'emotional_validation' },
  { label: 'Breathing exercises', value: 'breathing_exercises' },
  { label: 'Grounding exercises', value: 'grounding_exercises' },
  {
    label: 'SWOT: Strengths, Weaknesses, Opportunities, Threats',
    value: 'swot_strengths_weaknesses_opportunities_threats',
  },
  {
    label: 'WOOP: Wish, Outcome, Obstacles, Plan',
    value: 'woop_wish_outcome_obstacles_plan',
  },
  { label: 'Perspective shifts', value: 'perspective_shifts' },
  { label: 'SMART goal setting', value: 'smart_goal_setting' },
  { label: 'Meditation exercises', value: 'meditation_exercises' },
  { label: 'NVC skills', value: 'nvc_skills' },
  { label: 'Visualization exercises', value: 'visualization_exercises' },
  { label: 'Self-care planning', value: 'self_care_planning' },
  { label: 'Open-ended questioning', value: 'open_ended_questioning' },
  { label: 'Cognitive defusion', value: 'cognitive_defusion' },
]

export const FlatCoachingTags = GroupedCoachingTags.map(
  (item) => item.options
).flat()
