import {
  Box,
  Button,
  Collapse,
  Heading,
  List,
  ListItem,
  SimpleGrid,
  Stack,
  StackDivider,
  Text,
  VStack,
} from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'

import { APP_NAME } from '../../app/constants'
import { useGetCoachBioMutation } from '../../app/services/api'
import { useLocale } from '../../hooks/useLocale'
import CoachAvatar from './CoachAvatar'

const CoachBio = (props: any) => {
  const { user } = props
  const { t } = useLocale()
  const [getCoachBio, { data: coachBio }] = useGetCoachBioMutation()
  const [showFull, setShowFull] = useState<boolean>(false)
  const handleToggle = () => setShowFull(!showFull)

  useEffect(() => {
    const loadCoachBio = async () => {
      await getCoachBio()
    }
    if (user) {
      loadCoachBio()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  console.log('coachBio', coachBio)

  if (!coachBio) {
    return null
  }

  return (
    <SimpleGrid
      columns={1}
      spacing={{ base: 4, md: 5 }}
      py={{ base: 1, md: 3 }}
    >
      <Stack spacing={{ base: 1, md: 4 }}>
        <SimpleGrid columns={{ base: 2, sm: 1, md: 2, lg: 2 }} spacing={5}>
          <Box
            textAlign={{
              base: 'right',
              sm: 'center',
              md: 'right',
              lg: 'right',
            }}
            verticalAlign="center"
          >
            <CoachAvatar src={coachBio.profile.picture} />
          </Box>
          {/* <Center textAlign={'left'}> */}
          <Box as={'header'} textAlign="left" verticalAlign="center" mt={'5'}>
            <Heading
              lineHeight={1.1}
              fontWeight={600}
              fontSize={{ base: 'lg', sm: 'xl', lg: '2xl' }}
            >
              {coachBio.profile.name}
            </Heading>
            <Text
              color={'gray.900'}
              fontWeight={300}
              fontSize={{ base: 'sm', sm: 'md', lg: 'lg' }}
            >
              {t('coach')} @ {APP_NAME}
            </Text>
          </Box>
          {/* </Center> */}
        </SimpleGrid>
        {coachBio.bio && (
          <Stack
            spacing={{ base: 4, sm: 6 }}
            direction={'column'}
            divider={<StackDivider borderColor={'gray.200'} />}
          >
            <Collapse startingHeight={150} in={showFull}>
              <VStack spacing={{ base: 4, sm: 6 }}>
                <Text fontSize={'md'}>
                  {coachBio.bio.full.split('\\n').map((str: string) => (
                    <p>{str}</p>
                  ))}
                </Text>
                {coachBio.bio.firstCall && (
                  <>
                    <Text fontWeight={'bold'}>
                      {t('what_will_my_first_call_with_', {
                        coachName: coachBio.profile.name,
                      })}
                    </Text>
                    <Text fontSize={'md'}>{coachBio.bio.firstCall}</Text>
                  </>
                )}
                {coachBio.bio.coachingType && (
                  <>
                    <Text fontWeight={'bold'}>
                      {t('what_does_coaching_with_coach_look_like_', {
                        coachName: coachBio.profile.name,
                      })}
                    </Text>
                    <Text fontSize={'md'}>{coachBio.bio.coachingType}</Text>
                  </>
                )}
              </VStack>
              <SimpleGrid columns={{ base: 1, lg: 2 }} spacing={5}>
                <Box mt={4}>
                  <Text
                    fontSize={{ base: '16px', lg: '18px' }}
                    color={'blue.500'}
                    fontWeight={'500'}
                    textTransform={'uppercase'}
                    mb={'4'}
                  >
                    {t('specializes_in')}
                  </Text>
                  <List spacing={2}>
                    {coachBio.bio.specilizations.map((specialty: any) => (
                      <ListItem>
                        <Text fontSize={'md'}>{specialty.specialization}</Text>
                      </ListItem>
                    ))}
                  </List>
                </Box>
                <Box mt={4}>
                  <Text
                    fontSize={{ base: '16px', lg: '18px' }}
                    color={'blue.500'}
                    fontWeight={'500'}
                    textTransform={'uppercase'}
                    mb={'4'}
                  >
                    {t('qualified')}
                  </Text>

                  <List spacing={2}>
                    {coachBio.bio.qualifications.map((qualification: any) => (
                      <ListItem>
                        <Text fontSize={'md'}>{qualification}</Text>
                      </ListItem>
                    ))}
                  </List>
                </Box>
              </SimpleGrid>
              <SimpleGrid columns={{ base: 1, lg: 2 }} spacing={5}>
                <Box mt={4}>
                  <Text
                    fontSize={{ base: '16px', lg: '18px' }}
                    color={'blue.500'}
                    fontWeight={'500'}
                    textTransform={'uppercase'}
                    mb={'4'}
                  >
                    {t('languages_of_practice')}
                  </Text>

                  <List spacing={2}>
                    {coachBio.bio.languages.map((language: any) => (
                      <ListItem>
                        <Text fontSize={'md'}>{language}</Text>
                      </ListItem>
                    ))}
                  </List>
                </Box>
              </SimpleGrid>
            </Collapse>
            <Button size="sm" onClick={handleToggle} mt="1rem">
              {showFull ? t('show_less') : t('show_more')}
            </Button>
          </Stack>
        )}
      </Stack>
    </SimpleGrid>
  )
}

export default CoachBio
