import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Box,
  Button,
  Center,
  Flex,
  Heading,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Spacer,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  useColorModeValue,
  useDisclosure,
  useToast,
  VStack,
} from '@chakra-ui/react'
import moment from 'moment-timezone'
import {
  MutableRefObject,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react'
import { useHistory } from 'react-router'

import { APP_NAME } from '../../app/constants'
import {
  useDeleteBookingCoachRequestMutation,
  useDeleteEventMutation,
  useGetUpcomingEventsQuery,
} from '../../app/services/api'
import { useLocale } from '../../hooks/useLocale'
import { User } from '../../types/api'
import { useMixpanel } from '../../utils/MixpanelContext'
import Book from '../scheduler/Book'
import CallLink from '../scheduler/CallLink'
import GoogleMeetLink from '../scheduler/GoogleMeetLink'
import MicrosoftMeetLink from '../scheduler/MicrosoftMeetLink'

interface Props {
  user: User | null
  coach: any
}

export const Booking = ({ event, user, coach }: any) => {
  const { t } = useLocale()
  const isPendingEvent = typeof event?.resolved === 'boolean' && !event.resolved
  const [guest, setGuest] = useState<any>(null)
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const history = useHistory()
  const toast = useToast()
  const [deletePendingEvent] = useDeleteBookingCoachRequestMutation()
  const [deleteEvent] = useDeleteEventMutation()
  const onClose = () => setIsOpen(false)
  const cancelRef = useRef() as MutableRefObject<HTMLInputElement>
  const {
    isOpen: isModalOpen,
    onOpen: onModalOpen,
    onClose: onModalClose,
  } = useDisclosure()
  const mixpanel = useMixpanel()

  useEffect(() => {
    if (user) {
      mixpanel.track('appointment_screen_open')
    }
  }, [user, mixpanel])

  const onEventUpdate = useCallback(async () => {
    await onModalClose()
    toast({
      status: 'success',
      title: t('success'),
      description: t('the_session_is_successfully_updated'),
      isClosable: true,
    })
  }, [onModalClose, toast, t])

  const onDeletePendingEvent = useCallback(
    async (id) => {
      mixpanel.track('cancel_booking_request_confirmed')
      try {
        await deletePendingEvent({ requestId: id }).unwrap()
        toast({
          status: 'success',
          title: t('success'),
          description: t('booking_request_cancelled'),
          isClosable: true,
        })
      } catch (error) {}
    },
    [deletePendingEvent, mixpanel, t, toast]
  )

  const onCancel = useCallback(
    async (ev: any) => {
      if (isPendingEvent) {
        onDeletePendingEvent(event.id || event._id)
        history.go(0)
        return
      }

      try {
        await deleteEvent(event.id).unwrap()
        toast({
          status: 'success',
          title: t('success'),
          description: t('session_is_successfully_cancelled'),
          isClosable: true,
        })
        if (user) {
          mixpanel.track('appointment_cancel_success')
        }
        history.go(0)
      } catch (err) {
        toast({
          status: 'error',
          title: t('error'),
          description: t('there_was_an_error'),
          isClosable: true,
        })
        if (user) {
          mixpanel.track('appointment_cancel_fail')
        }
      }
    },
    [
      event,
      deleteEvent,
      history,
      mixpanel,
      toast,
      user,
      t,
      isPendingEvent,
      onDeletePendingEvent,
    ]
  )

  useEffect(() => {
    if (isPendingEvent) return

    const guest = user.id === event.user._id ? event.coach : event.user
    if (guest && guest.profile) setGuest(guest)
  }, [event, user, isPendingEvent])

  const onClickCancel = useCallback(() => {
    setIsOpen(true)
  }, [setIsOpen])

  const onClickReschedule = useCallback(() => {
    onModalOpen()
  }, [onModalOpen])

  const parseEventTime = (start: Date, end: Date) => {
    let timezone = user?.timezone
    if (!timezone) {
      timezone = 'Asia/Singapore'
    }
    const startMoment = moment(start).tz(timezone)
    const endMoment = moment(end).tz(timezone)
    return `${startMoment.format('dddd, MMMM D ⋅ h:mm')} - ${endMoment.format(
      'h:mma ([GMT]Z)'
    )}`
  }
  let guestName = `${guest?.profile && guest?.profile.name} @ ${APP_NAME}`
  if (coach) {
    guestName = `${coach?.profile?.name} @ ${APP_NAME}`
  }

  return (
    <Flex>
      <Box p="0">
        <Heading m="5" mb="0" as="h4" size="md">
          {guestName}
        </Heading>
        <Text ml="5" mt="1" mb="1" fontSize="sm">
          {isPendingEvent && t('pending_coach_confirmation')}
        </Text>
        <Text ml="5" mt="1" mb="1" fontSize="sm">
          {parseEventTime(event.start, event.end)}
        </Text>
        <Flex mt={2} ml={5}>
          <CallLink event={event} />
        </Flex>
      </Box>
      <Spacer />
      <Box p="5" alignContent={'right'}>
        <Button colorScheme="red" mr={2} onClick={onClickCancel}>
          {t('cancel')}
        </Button>
        {!isPendingEvent && (
          <Button
            onClick={onClickReschedule}
            bg={'blue.400'}
            color={'white'}
            _hover={{
              bg: 'blue.500',
            }}
          >
            {t('reschedule')}
          </Button>
        )}
      </Box>
      <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={onClose}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              {t('cancel_session')}
            </AlertDialogHeader>

            <AlertDialogBody>
              {isPendingEvent
                ? t('cancel_booking_request_confirmation')
                : t('are_you_sure_warning')}
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRef as any} onClick={onClose}>
                {isPendingEvent ? t('keep') : t('cancel')}
              </Button>
              <Button colorScheme="red" onClick={onCancel} ml={3}>
                {isPendingEvent ? t('cancel') : t('continue')}
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
      <Modal isOpen={isModalOpen} onClose={onModalClose} size="lg">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{t('reschedule')}</ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>
            <Book
              user={user}
              coach={coach}
              event={event}
              onEventUpdate={onEventUpdate}
            />
          </ModalBody>
        </ModalContent>
      </Modal>
    </Flex>
  )
}

const Bookings = ({ user, coach }: Props) => {
  const { t } = useLocale()
  const history = useHistory()
  const [futureEvents, setFutureEvents] = useState<any>([])
  const { data: upcomingEvents } = useGetUpcomingEventsQuery()

  useEffect(() => {
    const events: any = [...(upcomingEvents?.data ?? [])]

    // Include unconfirmed events
    if (upcomingEvents?.extra?.length) {
      const pendingEvent = upcomingEvents.extra[0]
      events.push(pendingEvent)
    }

    setFutureEvents(events)
  }, [upcomingEvents])

  const onClickBook = useCallback(() => {
    history.push('/book')
  }, [history])

  return (
    <Flex
      w="full"
      flexDirection="column"
      bg={useColorModeValue('white', 'gray.900')}
    >
      <Flex px={6} overflowY="auto" flexDirection="column" flex={1}>
        <Tabs mt={8} minH="100vh" isFitted variant="enclosed">
          <TabList mb="1em">
            <Tab>{t('upcoming')}</Tab>
            <Tab isDisabled>{t('past')}</Tab>
          </TabList>
          <TabPanels>
            <TabPanel mx={0} px={0}>
              {futureEvents &&
                futureEvents.map((event: any) => {
                  return (
                    <Booking
                      key={event._id}
                      event={event}
                      user={user}
                      coach={event.coach}
                    />
                  )
                })}
              {!futureEvents ||
                (futureEvents.length === 0 && (
                  <Center>
                    <VStack spacing={4} align="stretch">
                      <Flex>
                        <Heading size="md">
                          {t('you_dont_have_any_sessions_coming_up')}
                        </Heading>
                      </Flex>
                      <Center>
                        <Button
                          onClick={onClickBook}
                          // flex={1}
                          fontSize={'sm'}
                          rounded={'full'}
                          bg={'primary.500'}
                          color={'white'}
                          boxShadow={
                            '0px 1px 25px -5px rgb(66 153 225 / 48%), 0 10px 10px -5px var(--chakra-colors-primary-400)'
                          }
                          _hover={{
                            bg: 'primary.400',
                          }}
                          _focus={{
                            bg: 'primary.600',
                          }}
                        >
                          {t('make_appointment')}
                        </Button>
                      </Center>
                    </VStack>
                  </Center>
                ))}
            </TabPanel>
            <TabPanel>
              <p>two!</p>
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Flex>
    </Flex>
  )
}

export default Bookings
