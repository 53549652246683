import { Flex, IconButton, Textarea } from '@chakra-ui/react'
import React, { useCallback, useState } from 'react'
import { IoMdSend } from 'react-icons/io'
import { useSelector } from 'react-redux'
import { RootState } from '../../app/store'

import { socket } from '../../hooks/socket'
import { useLocale } from '../../hooks/useLocale'

const ChatInput = ({
  userId,
  sendTypingStatus,
  stopTypingStatus,
  mixpanel,
  onSendNewMessage,
}: any) => {
  const { t } = useLocale()
  const [message, setMessage] = useState('')
  // State handlers for typing
  const [altKey, setAltKey] = useState(false)
  const socketId = useSelector((state: RootState) => state.socket.socketId)

  // Function to call sendTypingStatus and stopTypingStatus based on keypress
  const onKeyPress = useCallback(
    (e) => {
      if (e.key !== 'Enter') {
        sendTypingStatus()
        setTimeout(() => {
          stopTypingStatus()
        }, 3000)
      }
    },
    [sendTypingStatus, stopTypingStatus]
  )

  const onKeyUp = useCallback(
    (e) => {
      if (e.key === 'Alt' || e.code === 'MetaLeft' || e.code === 'MetaRight') {
        setAltKey(false)
      }
    },
    [setAltKey]
  )

  const onKeyDown = (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
    // on enter send message, if shift and enter or alt and enter are pressed don't send message
    if (
      event.altKey ||
      event.code === 'MetaLeft' ||
      event.code === 'MetaRight'
    ) {
      setAltKey(true)
    }
    // Add a newline if alt and enter are pressed for mac
    if (event.key === 'Enter' && altKey) {
      event.preventDefault()
      setMessage(`${message}\n`)
    } else if (event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault()
      onMessage(event)
    }
  }

  const onChange = useCallback((e) => {
    setMessage(e.target.value)
  }, [])

  const onMessage = useCallback(
    (e) => {
      e.preventDefault()
      if (message !== '') {
        socket.emit('room:chat', {
          socketId,
          receiverId: userId,
          text: message,
        })
        mixpanel.track('chat_message_sent')
        setMessage('')
        onSendNewMessage && onSendNewMessage()
      }
    },
    [message, userId, mixpanel, socketId, onSendNewMessage]
  )

  return (
    <form>
      <Flex pl={4} py={2} borderTopColor="gray.100" borderTopWidth={1}>
        <Textarea
          autoFocus={true}
          minH="unset"
          overflow="hidden"
          w="100%"
          resize="none"
          rows={1}
          variant="unstyled"
          placeholder={t('type_your_message')}
          value={message}
          onChange={onChange}
          onKeyPress={onKeyPress}
          onKeyDown={onKeyDown}
          onKeyUp={onKeyUp}
        />
        <IconButton
          colorScheme="blue"
          aria-label={t('send_message')}
          variant="ghost"
          className="send_button"
          onClick={onMessage}
          icon={<IoMdSend />}
        />
      </Flex>
    </form>
  )
}

export default ChatInput
