import {
  Button,
  Center,
  FormControl,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  useToast,
} from '@chakra-ui/react'
import { useCallback, useEffect, useState } from 'react'
import { FaUserAlt } from 'react-icons/fa'
import { NavLink } from 'react-router-dom'

import {
  useCreateBlockEventMutation,
  useCreateEventMutation,
  useGetCompaniesMutation,
  useGetUsersMutation,
  useUpdateEventMutation,
} from '../../app/services/api'
import { useLocale } from '../../hooks/useLocale'
import Autocomplete from './Autocomplete'
import DatePicker from './DatePicker'

const TimeUpdater = ({
  event,
  start,
  onStartChange,
  end,
  onEndChange,
  onSubmit,
  isSubmitDisabled,
  isSubmitLoading,
}: any) => {
  const { t } = useLocale()

  return (
    <>
      <FormControl>
        <FormLabel htmlFor="start-date">{t('start')}:</FormLabel>
        <DatePicker
          id="start-date"
          selectedDate={start}
          onChange={onStartChange}
          showPopperArrow={true}
          dateFormat="d MMM ⋅ h:mm a"
          showTimeSelect
        />
      </FormControl>
      <FormControl>
        <FormLabel htmlFor="end-date">{t('end')}:</FormLabel>
        <DatePicker
          id="end-date"
          selectedDate={end}
          onChange={onEndChange}
          showPopperArrow={true}
          dateFormat="d MMM ⋅ h:mm a"
          showTimeSelect
          filterTime={(time: any) => {
            const currentTime = new Date(start)
            const selectedTime = new Date(time)
            return selectedTime.getTime() > currentTime.getTime()
          }}
        />
      </FormControl>

      <Button
        onClick={onSubmit}
        bg={'blue.400'}
        disabled={event ? false : isSubmitDisabled}
        color={'white'}
        _hover={{
          bg: 'blue.500',
        }}
        isFullWidth
        mt={5}
        isLoading={isSubmitLoading}
      >
        {t(event ? 'update' : 'create')}
      </Button>
    </>
  )
}

const NewEvent = ({
  isOpen,
  onClose,
  start,
  end,
  setStart,
  setEnd,
  event,
  onEventModalClose,
  guest,
  bookingFlow,
  setBookingFlow,
}: any) => {
  const { t } = useLocale()
  const [userId, setUserId] = useState('')
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(true)
  const [isSubmitLoading, setIsSubmitLoading] = useState(false)
  const [isCompanySelected, setIsCompanySelected] = useState(false)
  const [isUserSelected, setIsUserSelected] = useState(false)
  const [isBlocking, setIsBlocking] = useState(false)
  const [selectedCompanies, setSelectedCompanies] = useState([])
  const [selectedCompanyName, setSelectedCompanyName] = useState('')
  const [selectedUsers, setSelectedUsers] = useState([])
  const [selectedUserName, setSelectedUserName] = useState('')
  const [createEvent] = useCreateEventMutation() as any
  const [createBlockEvent] = useCreateBlockEventMutation()

  const [getCompanies, { data: getCompaniesData }] = useGetCompaniesMutation()
  const [getUsers, { data: getUsersData }] = useGetUsersMutation()
  const [updateEvent] = useUpdateEventMutation()

  const toast = useToast()

  useEffect(() => {
    const loadCompanies = async () => {
      await getCompanies()
    }
    loadCompanies()
  }, [getCompanies])

  const onModalClose = useCallback(() => {
    setUserId('')
    setIsSubmitDisabled(true)
    setIsCompanySelected(false)
    setIsUserSelected(false)
    setSelectedCompanies([])
    setSelectedCompanyName('')
    setSelectedUsers([])
    setSelectedUserName('')
    setIsBlocking(false)
    onClose()
  }, [onClose])

  const onSubmit = useCallback(
    async (ev: any) => {
      ev.preventDefault()
      const newEvent = {
        eventId: event?.id,
        userId,
        start,
        end,
        experimentEnabled: !!bookingFlow,
        experimentFlowId: bookingFlow,
      }

      try {
        setIsSubmitLoading(true)
        event
          ? await updateEvent(newEvent).unwrap()
          : await createEvent(newEvent).unwrap()
        setIsSubmitLoading(false)
        // Being that the result is handled in extraReducers in authSlice,
        // we know that we're authenticated after this, so the user
        // and token will be present in the store
        onModalClose()
        event && onEventModalClose()
        toast({
          title: 'Success',
          description: event
            ? 'Event is successfully updated.'
            : 'Event is successfully created.',
          status: 'success',
          duration: 9000,
          isClosable: true,
        })
        setBookingFlow('')
      } catch (err) {
        toast({
          status: 'error',
          title: t('error'),
          description: t('there_was_an_error'),
          isClosable: true,
        })
      }
    },
    [
      createEvent,
      userId,
      start,
      end,
      toast,
      onModalClose,
      event,
      onEventModalClose,
      updateEvent,
      bookingFlow,
      setBookingFlow,
      t,
    ]
  )

  const onBlockSubmit = useCallback(
    async (ev: any) => {
      ev.preventDefault()
      const newEvent = {
        start,
        end,
      }
      try {
        setIsSubmitLoading(true)
        await createBlockEvent(newEvent).unwrap()
        setIsSubmitLoading(false)
        // Being that the result is handled in extraReducers in authSlice,
        // we know that we're authenticated after this, so the user
        // and token will be present in the store
        onModalClose()
        toast({
          title: 'Success',
          description: 'Time slot is successfully blocked',
          status: 'success',
          duration: 9000,
          isClosable: true,
        })
      } catch (err) {
        toast({
          status: 'error',
          title: t('error'),
          description: t('there_was_an_error'),
          isClosable: true,
        })
      }
    },
    [createBlockEvent, start, end, toast, onModalClose, t]
  )

  const onStartChange = useCallback(
    (newStart: any) => {
      setStart(newStart)
    },
    [setStart]
  )

  const onEndChange = useCallback(
    (newEnd: any) => {
      setEnd(newEnd)
    },
    [setEnd]
  )

  const onCompanySelectionChange = useCallback(
    (changes: any) => {
      if (changes.selectedItems && changes.selectedItems.length !== 0) {
        const loadUsers = async () => {
          await getUsers(changes.selectedItems[0].value)
        }
        loadUsers()
        setIsCompanySelected(true)
        setSelectedCompanies(changes.selectedItems)
        setSelectedCompanyName(changes.selectedItems[0].label)
      }
    },
    [getUsers]
  )

  const onUserSelectionChange = useCallback((changes: any) => {
    if (changes.selectedItems && changes.selectedItems.length !== 0) {
      setIsUserSelected(true)
      setSelectedUsers(changes.selectedItems)
      setSelectedUserName(changes.selectedItems[0].label)
      setUserId(changes.selectedItems[0].value)
      setIsSubmitDisabled(false)
    }
  }, [])

  const listStyleProps = {
    overflowY: 'auto',
    maxHeight: '40vh',
  }

  if (!start || !end || !getCompaniesData || (event && event.isBlock)) {
    return null
  }
  return (
    <>
      <Modal
        isOpen={isOpen}
        onClose={onModalClose}
        isCentered
        motionPreset="scale"
      >
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton />
          {event && <ModalHeader>{t('reschedule_the_session')}</ModalHeader>}
          <ModalBody pb={1}>
            {event ? (
              <form onSubmit={onSubmit}>
                <Center>
                  <NavLink
                    to={`/coach/customer/${guest && guest._id}`}
                    target="_blank"
                  >
                    <Button
                      size="lg"
                      height="48px"
                      width="200px"
                      variant="ghost"
                      color="blue.500"
                      _hover={{ border: '2px solid #1C6FEB' }}
                      leftIcon={<FaUserAlt color="blue.500" size="24px" />}
                    >
                      {event.title}
                    </Button>
                  </NavLink>
                </Center>
                <TimeUpdater
                  event={event}
                  start={start}
                  onStartChange={onStartChange}
                  end={end}
                  onEndChange={onEndChange}
                  onSubmit={onSubmit}
                  isSubmitDisabled={isSubmitDisabled}
                  isSubmitLoading={isSubmitLoading}
                />
              </form>
            ) : (
              <Tabs isFitted>
                <TabList>
                  <Tab>{t('create_a_new_session')}</Tab>
                  {!event && <Tab>{t('block_the_timeslot')}</Tab>}
                </TabList>
                <TabPanels>
                  <TabPanel>
                    <form onSubmit={onSubmit}>
                      <>
                        {!isCompanySelected ? (
                          <FormControl>
                            <Autocomplete
                              label={`${t('company')}:`}
                              placeholder={t('choose_company')}
                              onSelectedItemsChange={onCompanySelectionChange}
                              selectedItems={selectedCompanies}
                              items={getCompaniesData.data}
                              listStyleProps={listStyleProps}
                            />
                          </FormControl>
                        ) : (
                          <FormControl>
                            <FormLabel htmlFor="company-name">
                              {t('company')}:
                            </FormLabel>
                            <Input
                              id="company-name"
                              value={selectedCompanyName}
                              isDisabled
                            />
                          </FormControl>
                        )}
                        {isCompanySelected &&
                        !isUserSelected &&
                        getUsersData ? (
                          <FormControl>
                            <Autocomplete
                              label={`${t('user')}:`}
                              placeholder={t('choose_user')}
                              onSelectedItemsChange={onUserSelectionChange}
                              selectedItems={selectedUsers}
                              items={getUsersData.data}
                              listStyleProps={listStyleProps}
                            />
                          </FormControl>
                        ) : (
                          <FormControl>
                            <FormLabel htmlFor="user-name">{t('user')}:</FormLabel>
                            <Input
                              id="user-name"
                              value={selectedUserName}
                              isDisabled
                            />
                          </FormControl>
                        )}
                      </>

                      <TimeUpdater
                        event={event}
                        start={start}
                        onStartChange={onStartChange}
                        end={end}
                        onEndChange={onEndChange}
                        onSubmit={onSubmit}
                        isSubmitDisabled={isSubmitDisabled}
                        isSubmitLoading={isSubmitLoading}
                      />
                    </form>
                  </TabPanel>
                  <TabPanel>
                    <form onSubmit={onBlockSubmit}>
                      <FormControl>
                        <FormLabel htmlFor="start-date">{t('start')}:</FormLabel>
                        <DatePicker
                          id="start-date"
                          selectedDate={start}
                          onChange={onStartChange}
                          showPopperArrow={true}
                          dateFormat="d MMM ⋅ h:mm a"
                          showTimeSelect
                        />
                      </FormControl>
                      <FormControl>
                        <FormLabel htmlFor="end-date">{t('end')}:</FormLabel>
                        <DatePicker
                          id="end-date"
                          selectedDate={end}
                          onChange={onEndChange}
                          showPopperArrow={true}
                          dateFormat="d MMM ⋅ h:mm a"
                          showTimeSelect
                          filterTime={(time: any) => {
                            const currentTime = new Date(start)
                            const selectedTime = new Date(time)
                            return (
                              selectedTime.getTime() > currentTime.getTime()
                            )
                          }}
                        />
                      </FormControl>
                      <Button
                        onClick={onBlockSubmit}
                        bg={'blue.400'}
                        color={'white'}
                        isFullWidth
                        mt={5}
                        _hover={{
                          bg: 'blue.500',
                        }}
                        isLoading={isSubmitLoading}
                      >
                        {t('block')}
                      </Button>
                    </form>
                  </TabPanel>
                </TabPanels>
              </Tabs>
            )}
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  )
}

export default NewEvent
