import {
  Box,
  Button,
  Center,
  Checkbox,
  Flex,
  Heading,
  IconButton,
  Image,
  Input,
  Radio,
  RadioGroup,
  Stack,
  Text,
  Textarea,
  useColorModeValue,
  VStack,
  Wrap,
} from '@chakra-ui/react'
import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { HiArrowLeft } from 'react-icons/hi'

import { useLocale } from '../../../hooks/useLocale'

interface IProps {
  question: any
  answer?: any
  onProceed: (step?: number) => void
  itemSelect: (item: any) => void
  errorMessage: string
  setErrorMessage: (message: string) => void
  showError: boolean
  setShowError: (show: boolean) => void
  onGoBack?: () => void
  canGoBack?: boolean
  type?: 'survey' | 'onboarding'
}

const processText = (text: string) => text.replace('(', '\n(')
const decideFlexDirection = (label: string) => {
  if (label === 'No') return 'column'
  return label.length < 3 ? 'row' : 'column'
}

const CheckBoxItem = ({
  option,
  checkedBoxes,
  setCheckedBoxes,
  question,
  itemSelect,
  setIsOtherSelected,
  type,
}: any) => {
  const dispatch = useDispatch()
  if (option.children.length > 0) {
    return (
      <VStack py={2} alignItems={'flex-start'}>
        <Text fontWeight={'bold'}>{processText(option.label)}</Text>
        {option.children.map((childOption: any) => (
          <CheckBoxItem
            option={childOption}
            question={question}
            key={childOption._id}
            checkedBoxes={checkedBoxes}
            setCheckedBoxes={setCheckedBoxes}
            itemSelect={itemSelect}
            setIsOtherSelected={setIsOtherSelected}
            type={type}
          />
        ))}
      </VStack>
    )
  } else {
    return (
      <Box my={2}>
        <Checkbox
          isChecked={checkedBoxes.has(
            type === 'onboarding' ? option.value : option._id
          )}
          onChange={(event: React.ChangeEvent<any>) => {
            if (option.value === 'other') {
              setIsOtherSelected(event.target.checked)
              return
            }
            const answers = new Set(checkedBoxes)

            if (event.target.checked) {
              answers.add(type === 'onboarding' ? option.value : option._id)
            } else {
              answers.delete(type === 'onboarding' ? option.value : option._id)
            }
            dispatch(
              itemSelect({
                questionId:
                  type === 'onboarding' ? question.friendlyID : question._id,
                answers: Array.from(answers),
              })
            )
            setCheckedBoxes(answers)
          }}
        >
          {processText(option.label)}
        </Checkbox>
      </Box>
    )
  }
}

const SurveyQuestion = ({
  question,
  answer,
  itemSelect,
  otherOption,
  setOtherOption,
  type: surveyType,
}: any) => {
  const { t } = useLocale()
  const dispatch = useDispatch()
  const type = question.type
  const options = question.options
    .slice()
    .sort((a: any, b: any) => a.order - b.order)

  const radioAnswerFromState =
    answer &&
    options
      .map((option: any) => option.value)
      .find((val: any) => val === answer[0])

  const oldAnswer = useSelector(
    ({ survey }: any) => survey.questions[question._id]
  )

  const [isOtherSelected, setIsOtherSelected] = useState(false)
  const [selectedRadioValue, setSelectedRadioValue] =
    useState(radioAnswerFromState)
  const [checkedBoxes, setCheckedBoxes] = useState(new Set(answer))

  let optionsElement = <></>
  if (type === 'multiselect') {
    optionsElement = (
      <>
        {options.map((option: any) => (
          <CheckBoxItem
            option={option}
            question={question}
            key={option._id}
            itemSelect={itemSelect}
            checkedBoxes={checkedBoxes}
            setCheckedBoxes={setCheckedBoxes}
          />
        ))}
      </>
    )
  } else if (type === 'select') {
    // select is only for one choice
    optionsElement = (
      <>
        {options.map((option: any) => (
          <CheckBoxItem
            type={surveyType}
            key={option._id}
            option={option}
            question={question}
            checkedBoxes={checkedBoxes}
            setCheckedBoxes={setCheckedBoxes}
            itemSelect={itemSelect}
            setIsOtherSelected={setIsOtherSelected}
          />
        ))}
        {isOtherSelected && (
          <Input
            placeholder={'Please specify'}
            value={otherOption}
            onChange={(event: React.ChangeEvent<any>) => {
              setOtherOption(event.target.value)
            }}
          />
        )}
      </>
    )
  } else if (type === 'radio') {
    // deprecated
    optionsElement = (
      <>
        <RadioGroup
          key={question._id}
          defaultValue={selectedRadioValue}
          onChange={(value) => {
            const [id, slug] = value.split('___')
            const isOther = slug === 'other'

            setIsOtherSelected(isOther)

            if (isOther) {
              setSelectedRadioValue('')
              return
            }

            dispatch(
              itemSelect({
                questionId:
                  surveyType === 'onboarding'
                    ? question.friendlyID
                    : question._id,
                answers: [surveyType === 'onboarding' ? value : id],
              })
            )
            setSelectedRadioValue(value)
          }}
        >
          <Stack direction={decideFlexDirection(options[0].label)}>
            {options.map((option: any, index: number) => (
              <Box key={option._id} my={3} m={index === 0 ? 0 : undefined}>
                <Radio
                  value={
                    surveyType === 'onboarding'
                      ? option.value
                      : `${option._id}___${option.value}`
                  }
                  name={option.value}
                >
                  {processText(option.label)}
                </Radio>
              </Box>
            ))}
          </Stack>
        </RadioGroup>
        {isOtherSelected && (
          <Input
            mt={2}
            placeholder={'Please specify'}
            value={oldAnswer?.other || ''}
            onChange={(event: React.ChangeEvent<any>) => {
              setOtherOption(event.target.value)
              dispatch(
                itemSelect({
                  questionId: question._id,
                  answer: event.target.value.trim(),
                  answers: [],
                  other: surveyType === 'survey',
                })
              )
            }}
          />
        )}
      </>
    )
  } else if (type === 'textarea') {
    optionsElement = (
      <Box>
        <Text>{t('your_answer')}:</Text>
        <Textarea
          minW={450}
          minH={150}
          placeholder={t('your_answer_placeholder')}
          defaultValue={oldAnswer?.other || ''}
          onChange={(event: React.ChangeEvent<any>) => {
            const answer = event.target.value
            dispatch(
              itemSelect({
                questionId: question._id,
                answer: answer,
                answers: [],
                other: surveyType === 'survey',
              })
            )
          }}
        />
      </Box>
    )
  } else if (type === 'text') {
    optionsElement = (
      <Box>
        <Text>{t('your_answer')}:</Text>
        <Input
          type="text"
          placeholder={t('your_answer_placeholder')}
          defaultValue={oldAnswer?.other || ''}
          onChange={(event: React.ChangeEvent<any>) => {
            const answer = event.target.value
            // setOtherOption(answer)
            dispatch(
              itemSelect({
                questionId: question._id,
                answer: answer,
                answers: [],
                other: surveyType === 'survey',
              })
            )
          }}
        />
      </Box>
    )
  }
  return (
    <Flex flexDir={'column'} m={2}>
      {optionsElement}
    </Flex>
  )
}

export const Question: React.FC<IProps> = ({
  itemSelect,
  onProceed,
  question,
  answer,
  errorMessage,
  setErrorMessage,
  showError,
  setShowError,
  onGoBack,
  canGoBack,
  type = 'survey',
}) => {
  const { t } = useLocale()
  const [otherOption, setOtherOption] = useState('')

  let descriptionElement = <></>
  if (question.attachment) {
    descriptionElement = <Image src={question.attachment} maxW={'lg'} />
  } else if (question.description) {
    descriptionElement = (
      <Text py={2} fontSize={'lg'} color={'gray.600'} align={'center'}>
        {question.description}
      </Text>
    )
  }

  const proceed = () => {
    if (otherOption.length > 0) {
      setOtherOption('')
    }
    onProceed()
  }

  return (
    <Flex flexDir={'column'} align={'center'} h="100vh">
      <Box maxW={'2xl'}>
        <VStack my={6} mx={12}>
          <Heading fontSize={'2xl'} textAlign={'center'}>
            {question.title}
            {question.required && (
              <Text as="span" color={'red.700'}>
                *
              </Text>
            )}
          </Heading>
          {descriptionElement}
        </VStack>
        <Center>
          <Flex
            maxW={'lg'}
            flexDir={'column'}
            bg={useColorModeValue('gray.50', 'gray.800')}
          >
            <Stack spacing={4} mx={'auto'} maxW={'lg'} px={6}>
              <SurveyQuestion
                type={type}
                key={question._id}
                itemSelect={itemSelect}
                question={question}
                answer={answer}
                setErrorMessage={setErrorMessage}
                setShowError={setShowError}
                otherOption={otherOption}
                setOtherOption={setOtherOption}
              />
            </Stack>
            <Stack spacing={10} pt={10} pb={6} align={'center'}>
              {errorMessage && showError ? (
                <Text color={'red.600'}>{errorMessage}</Text>
              ) : null}
              <Wrap>
                {canGoBack && (
                  <IconButton
                    aria-label="Back"
                    variant="outline"
                    colorScheme="primary"
                    onClick={() => (onGoBack ? onGoBack() : onProceed(-1))}
                    icon={<HiArrowLeft />}
                  />
                )}
                <Button
                  colorScheme="primary"
                  onClick={proceed}
                  minW={'sm'}
                  type="submit"
                >
                  {t('next')}
                </Button>
              </Wrap>
            </Stack>
          </Flex>
        </Center>
      </Box>
    </Flex>
  )
}
