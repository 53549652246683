import {
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
  Flex,
  Slider,
  SliderTrack,
  SliderFilledTrack,
  SliderThumb,
  FormControl,
  FormLabel,
  Box,
  Tooltip,
} from '@chakra-ui/react'
import { useState } from 'react'
import {
  MdSentimentDissatisfied,
  MdSentimentNeutral,
  MdSentimentSatisfied,
  MdSentimentVeryDissatisfied,
  MdSentimentVerySatisfied,
} from 'react-icons/md'
import { DEFAULT_MOOD_SCORE } from '../../app/constants'

const MoodInput = (props: any) => {
  // Get params from props
  const { name, label, min, max, step, defaultValue, onChange } = props
  const [value, setValue] = useState(defaultValue ?? DEFAULT_MOOD_SCORE)

  const handleChange = (value: string | number) => {
    setValue(Number(value))
    onChange(Number(value))
  }

  const renderEmotion = (value: number) => {
    const size = '2rem'
    switch (value) {
      case 1:
        return <MdSentimentVeryDissatisfied size={size} />
      case 2:
        return <MdSentimentDissatisfied size={size} />
      case 3:
        return <MdSentimentNeutral size={size} />
      case 4:
        return <MdSentimentSatisfied size={size} />
      case 5:
        return <MdSentimentVerySatisfied size={size} />
    }
  }

  return (
    <FormControl>
      <FormLabel htmlFor={name} textAlign="center">
        {label}
      </FormLabel>
      <Flex>
        <Slider
          // flex="1"
          name={name}
          focusThumbOnChange={false}
          value={value}
          onChange={handleChange}
          min={min}
          max={max}
          step={step}
          defaultValue={defaultValue}
        >
          <SliderTrack>
            <Box position="relative" right={10} />
            <SliderFilledTrack />
          </SliderTrack>
          <SliderThumb
            fontSize="sm"
            boxSize="32px"
            children={renderEmotion(value)}
          />
        </Slider>
      </Flex>
    </FormControl>
  )
}

export default MoodInput
