import { Box, HStack, useRadio, useRadioGroup } from '@chakra-ui/react'
import React, { useCallback } from 'react'

import { useLocale } from '../../hooks/useLocale'

// 1. Create a component that consumes the `useRadio` hook
const RadioCard = (props: any): any => {
  const { getInputProps, getCheckboxProps } = useRadio(props)

  const input = getInputProps()
  const checkbox = getCheckboxProps()

  return (
    <Box as="label">
      <input {...input} />
      <Box
        {...checkbox}
        cursor="pointer"
        borderWidth="1px"
        borderRadius="md"
        boxShadow="md"
        _checked={{
          bg: 'teal.600',
          color: 'white',
          borderColor: 'teal.600',
        }}
        _focus={{
          boxShadow: 'outline',
        }}
        px={5}
        py={3}
      >
        {props.children}
      </Box>
    </Box>
  )
}

// Step 2: Use the `useRadioGroup` hook to control a group of custom radios.
const Duration = ({ options, setDuration, onNext }: any) => {
  const { t } = useLocale()
  const onChange = useCallback(
    (duration: string) => {
      console.log('onDurationChange')
      setDuration(duration)
      onNext()
    },
    [setDuration, onNext]
  )

  const { getRootProps, getRadioProps } = useRadioGroup({
    name: 'duration',
    onChange,
  })

  const group = getRootProps()

  return (
    <HStack {...group}>
      {options.map((option: any) => {
        const radio = getRadioProps(option)
        return (
          <RadioCard key={option.value} {...radio}>
            {t(option.label)}
          </RadioCard>
        )
      })}
    </HStack>
  )
}

export default Duration
