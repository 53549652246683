import {
  Box,
  Container,
  Divider,
  Grid,
  Spinner,
  Text,
  useDisclosure,
  useToast,
} from '@chakra-ui/react'
import { FC, useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  useGetCoachOptionsQuery,
  useMatchWithCoachMutation,
} from '../../app/services/api'
import CoachInfo from '../coaches/CoachInfo'
import CoachSelectCard from './CoachCard'

const ChangeCoach: FC<any> = ({ usersCoach }) => {
  const {
    data: coaches,
    isLoading,
    isError,
    error,
  } = useGetCoachOptionsQuery(null, {
    refetchOnFocus: true,
    refetchOnMountOrArgChange: true,
  })
  const [currentCoach, setCurrentCoach] = useState<any>(null)
  const { isOpen, onOpen, onClose } = useDisclosure()
  const { t } = useTranslation()
  const [matchWithCoach] = useMatchWithCoachMutation()
  const toast = useToast()

  useEffect(() => {
    if (error) {
      console.log('Error: ', error)
    }
  }, [error])

  const handleSubmit = useCallback(
    async (coach: any) => {
      if (usersCoach && coach.coach._id === usersCoach._id) {
        toast({
          status: 'error',
          title: 'Error',
          description: t('coach_already_selected'),
          isClosable: true,
          duration: 3000,
        })
      } else {
        await matchWithCoach({ coachId: coach.coach._id })
        toast({
          status: 'success',
          title: 'Success',
          description: t('coach_updated'),
          isClosable: true,
          duration: 3000,
        })
        window.location.href = '/'
        try {
        } catch (error: any) {
          toast({
            status: 'error',
            title: 'Error',
            description: t('coach_update_error'),
            isClosable: true,
            duration: 3000,
          })
        }
      }
    },
    [matchWithCoach, t, toast, usersCoach]
  )

  return (
    <Container maxWidth="1200px">
      <Box>
        {isOpen && (
          <CoachInfo isOpen={isOpen} onClose={onClose} coach={currentCoach} />
        )}
        {isLoading ? (
          <Spinner />
        ) : isError ? (
          <Text>{t('load_coach_options_error')}</Text>
        ) : (
          <Grid rowGap="30px">
            <Box>
              <Text fontSize="3xl" fontWeight="700">
                {t('select_coach')}
              </Text>
              <Divider mt="20px" />
            </Box>
            <Grid
              gridGap="20px"
              gridTemplateColumns={[
                '1fr',
                '1fr',
                '1fr',
                '1fr 1fr',
                '1fr 1fr 1fr',
              ]}
            >
              {coaches.map((coachBio: any) => (
                <CoachSelectCard
                  coachBio={coachBio}
                  key={coachBio._id}
                  onReadMore={(coach: any) => {
                    setCurrentCoach(coach)
                    onOpen()
                  }}
                  onSelect={handleSubmit}
                />
              ))}
            </Grid>
          </Grid>
        )}
      </Box>
    </Container>
  )
}

export default ChangeCoach
