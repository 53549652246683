import { Box } from '@chakra-ui/react'
import { MutableRefObject, useEffect, useRef } from 'react'

import ChatBubble from './ChatBubble'

interface Props {
  messages: any[]
  username?: string
  onMessageRead?: any
}

export const ChatMessages: React.FC<Props> = ({
  messages,
  username,
  onMessageRead,
}) => {
  const messagesEndRef = useRef() as MutableRefObject<HTMLDivElement>

  const scrollToBottom = () => {
    if (messagesEndRef?.current) {
      messagesEndRef?.current.scrollIntoView({ behavior: 'smooth' })
    }
  }

  useEffect(() => {
    scrollToBottom()
  }, [messages])

  return (
    <Box overflowY="scroll" h="calc(100vh - 250px)">
      {messages.map(({ id, message, from, dateSent, read, type }) => (
        <ChatBubble
          key={id}
          id={id}
          me={username}
          message={message}
          from={from}
          read={read}
          dateSent={dateSent}
          onMessageRead={onMessageRead}
          type={type}
        />
      ))}
      <span ref={messagesEndRef}></span>
    </Box>
  )
}
