import {
  Button,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Input,
  Stack,
  useToast,
} from '@chakra-ui/react'
import { useCallback, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import TimezoneSelect from 'react-timezone-select'

import { updateCoach } from '../auth/authSlice'
import {
  useGetCoachProfileQuery,
  usePutCoachProfileMutation,
} from '../../app/services/api'
import { useLocale } from '../../hooks/useLocale'
import { useMixpanel } from '../../utils/MixpanelContext'
import CoachBio from './CoachBio'
import { PutCoachProfileRequest } from '../../types/api'

interface IProps {
  coach: any
}

export const CoachProfile: React.FC<IProps> = ({ coach }) => {
  const mixpanel = useMixpanel()
  const toast = useToast()
  const dispatch = useDispatch()
  const { t } = useLocale()

  const [updateProfile] = usePutCoachProfileMutation()
  const { data } = useGetCoachProfileQuery()
  const [isButtonDisabled, setIsButtonDisabled] = useState(true)
  const [formState, setFormState] = useState<PutCoachProfileRequest>({
    name: '',
    gmail: '',
    timezone: '',
    language: '',
  })

  useEffect(() => {
    mixpanel.track('profile_edit_open_coach')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    setIsButtonDisabled(!(formState?.name && formState?.timezone))
  }, [formState])

  useEffect(() => {
    if (data) {
      console.log("coach profile:", data)
      setFormState({
        name: data.profile.name,
        gmail: data.profile.gmail,
        timezone: data.timezone || '',
        language: data.language || '',
      })
    }
  }, [data])

  const resetForm = useCallback(() => {
    setFormState({
      name: data ? data.profile.name : '',
      gmail: data ? data.profile.gmail : '',
      timezone: data ? data.timezone || '' : '',
      language: data ? data.language || '' : '',
    })
  }, [data])

  const handleChange = useCallback((e: React.FormEvent<HTMLInputElement>) => {
    const { name, value } = e.currentTarget

    setFormState((prev) => ({ ...prev, [name]: value }))
  }, [])

  const handleTimezoneChange = useCallback((timezone: any) => {
    const value = timezone.value

    setFormState((prev) => ({ ...prev, timezone: value }))
  }, [])

  const onSubmit = useCallback(
    async (e: React.FormEvent) => {
      e.preventDefault()

      try {
        const updatedData = await updateProfile(formState).unwrap()

        dispatch(updateCoach(updatedData))

        toast({
          status: 'success',
          title: t('success'),
          description: t('your_profile_is_updated'),
          isClosable: true,
        })

        mixpanel.track('profile_edit_coach_success')
      } catch (err: any) {
        toast({
          status: 'error',
          title: t('error'),
          description: t('there_was_an_error'),
          isClosable: true,
        })
        mixpanel.track('profile_edit_coach_fail', {
          message: err?.data?.message,
        })
      }
    },
    [dispatch, formState, t, mixpanel, toast, updateProfile]
  )

  return (
    <form onSubmit={onSubmit}>
      <Flex minH="50vh" align="flex-start" justify="space-around" bg="gray.50">
        <Stack
          spacing={4}
          w="full"
          maxW="md"
          bg="white"
          rounded="xl"
          boxShadow="lg"
          p={6}
          my={12}
          // Add mr for smaller screens
          mr={{ base: 2, xl: 0 }}
        >
          <Heading lineHeight={1.1} fontSize={{ base: '2xl', sm: '3xl' }}>
            {t('edit_profile')}
          </Heading>
          <FormControl id="name">
            <FormLabel>{t('name')}</FormLabel>
            <Input
              value={formState.name}
              name="name"
              _placeholder={{ color: 'gray.500' }}
              type="text"
              onChange={handleChange}
            />
          </FormControl>
          <FormControl id="gmail">
            <FormLabel>{t('gmail')}</FormLabel>
            <Input
              value={formState.gmail}
              name="gmail"
              _placeholder={{ color: 'gray.500' }}
              type="email"
              onChange={handleChange}
            />
          </FormControl>
          <FormControl id="timezone">
            <FormLabel>{t('timezone')}</FormLabel>
            <TimezoneSelect
              name="timezone"
              value={formState.timezone}
              onChange={handleTimezoneChange}
            />
          </FormControl>

          <Stack spacing={6} direction={['column', 'row']}>
            <Button colorScheme="red" w="full" onClick={resetForm}>
              {t('reset')}
            </Button>
            <Button
              colorScheme="green"
              w="full"
              disabled={isButtonDisabled}
              onClick={onSubmit}
            >
              {t('save')}
            </Button>
          </Stack>
        </Stack>
        <Stack
          spacing={4}
          w="full"
          maxW="xl"
          // maxW="md"
          bg="white"
          rounded="xl"
          boxShadow="lg"
          p={6}
          my={12}
        >
          <Heading lineHeight={1.1} fontSize={{ base: '2xl', sm: '3xl' }}>
            {t('your_bio')}
          </Heading>
          <CoachBio user={coach} />
        </Stack>
      </Flex>
    </form>
  )
}
