import {
  Avatar,
  Box,
  Divider,
  Flex,
  Text,
  useDisclosure,
} from '@chakra-ui/react'
import { FC } from 'react'
import { MdKeyboardArrowRight } from 'react-icons/md'
import { useLocale } from '../../hooks/useLocale'
import { VideoModal } from '../onboarding/components/VideoModal'

const CoachSelectCard: FC<any> = ({
  coachBio,
  selected,
  onReadMore,
  onSelect,
}) => {
  const { t } = useLocale()
  const { isOpen, onClose, onOpen } = useDisclosure()

  return (
    <Box
      overflow="hidden"
      key={coachBio.coach._id}
      bg={
        selected === coachBio.coach.email
          ? 'var(--chakra-colors-primary-100)'
          : '#fff'
      }
      boxShadow="xl"
      display="flex"
      flexDirection={'column'}
      justifyContent="space-between"
      w={'full'}
      maxHeight="fit-content"
      borderRadius="2xl"
    >
      <Box>
        <Flex alignItems="center" p={5} gridColumnGap={3}>
          <Avatar size="lg" src={coachBio?.coach?.profile?.picture} />
          <Flex direction="column" alignItems="flex-start">
            <Text fontWeight="700">{coachBio.coach.profile.name} </Text>
            <Text fontSize="14px">{coachBio.coach.profile.title} </Text>
          </Flex>
        </Flex>
        <Divider />
        <Box p={5}>
          <Text noOfLines={4}> {coachBio?.coachBio?.bio?.summary} </Text>
        </Box>
      </Box>
      <Box>
        <Box p={5}>
          <Flex
            style={{ cursor: 'pointer' }}
            _hover={{ opacity: 0.4 }}
            alignItems="center"
            justifyContent="space-between"
            userSelect="none"
            transition=".1s"
            _active={{
              transform: 'scaleX(.9)',
            }}
            onClick={() => onReadMore(coachBio)}
          >
            <Text fontWeight="700">
              Read more about {coachBio.coach.profile.name}
            </Text>
            <MdKeyboardArrowRight fontSize="30px" />
          </Flex>

          {coachBio.coach?.profile?.video && (
            <Flex
              style={{ cursor: 'pointer' }}
              _hover={{ opacity: 0.4 }}
              alignItems="center"
              justifyContent="space-between"
              userSelect="none"
              my="2"
              transition=".1s"
              _active={{
                transform: 'scaleX(.9)',
              }}
              onClick={onOpen}
            >
              <Text fontWeight="700">{t('watch_coach_intro')}</Text>
              <MdKeyboardArrowRight fontSize="30px" />
              <VideoModal
                url={coachBio.coach.profile.video}
                onClose={onClose}
                isOpen={isOpen}
              />
            </Flex>
          )}
        </Box>
        <Divider />
        <Flex
          p={5}
          _hover={{ background: '#eee' }}
          cursor="pointer"
          justifyContent="center"
          _active={{ background: '#ddd' }}
          userSelect="none"
          onClick={() => onSelect(coachBio)}
        >
          <Text
            fontWeight="600"
            style={{ color: 'var(--chakra-colors-primary-500)' }}
          >
            Select
          </Text>
        </Flex>
      </Box>
    </Box>
  )
}

export default CoachSelectCard
