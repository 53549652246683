import {
  Alert,
  AlertIcon,
  AlertTitle,
  Center,
  Flex,
  IconButton,
  Spinner,
  Stack,
  Stat,
  StatLabel,
  StatNumber,
  Tag,
  useColorModeValue,
} from '@chakra-ui/react'
import {
  MutableRefObject,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react'
import { Trans } from 'react-i18next'
import { IoIosReturnLeft } from 'react-icons/io'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import { useParams } from 'react-router-dom'

import { APP_NAME } from '../../app/constants'
import {
  api,
  useGetMessagesQuery,
  useGetUserMutation,
} from '../../app/services/api'
import { RootState, store } from '../../app/store'
import { socket } from '../../hooks/socket'
import { useLocale } from '../../hooks/useLocale'
import { Coach, User } from '../../types/api'
import { ErrorResponse } from '../../types/error'
import { useMixpanel } from '../../utils/MixpanelContext'
import { updateSocket } from '../connection/socketSlice'
import './Chat.css'
import ChatInput from './ChatInput'
import { ChatMessages } from './ChatMessages'
import { addMessage, addMessages, Message } from './chatSlice'

interface Props {
  user: User | null
  coach: Coach | null
  usersCoach?: any
  onSendNewMessage?: any
  onMessageRead?: any
}

const Chat = ({
  user,
  coach,
  usersCoach,
  onSendNewMessage,
  onMessageRead,
}: Props) => {
  const { t } = useLocale()
  const history = useHistory()
  const dispatch = useDispatch()
  const [typingUsername, setTypingUsername] = useState('')
  const [stopTypingTimeout, setStopTypingTimeout] =
    useState<NodeJS.Timeout | null>(null)
  const [typingStatusSent, setTypingStatusSent] = useState(false)
  const mixpanel = useMixpanel()

  const messages = useSelector((state: RootState) => state.chat.messages)
  const socketId = useSelector((state: RootState) => state.socket.socketId)
  const [getUser, { data: getUserData, isLoading: getUserLoading }] =
    useGetUserMutation()

  const onReturnClick = useCallback(() => {
    history.goBack()
  }, [history])

  useEffect(() => {
    mixpanel.track('chat_screen_open')
  }, [mixpanel])

  useEffect(() => {
    scrollToBottom()
  }, [messages])

  useEffect(() => {
    console.log(
      'socket.connected:',
      socket.connected,
      ', socket.id:',
      socket.id
    )
    if (!socket.connected) {
      socket.connect()
    } else if (!socketId && socket.id) {
      store.dispatch(updateSocket(socket.id))
    }
  }, [socketId])

  const username = user ? user.id : coach?.id
  let { userId } = useParams() as any

  let roomname = `${username && username.slice(0, 10)}_${
    userId && userId.slice(0, 10)
  }`
  if (user) {
    // If logged in user is customer
    userId = usersCoach?._id
    roomname = `${userId && userId.slice(0, 10)}_${
      username && username.slice(0, 10)
    }`
  }

  useEffect(() => {
    const loadUser = async () => {
      await getUser(userId)
    }
    loadUser()
  }, [userId, getUser])

  const { data, error, isLoading } = useGetMessagesQuery({
    user: userId,
  })

  useEffect(() => {
    if (!socket.connected) {
      socket.connect()
    }
  }, [])

  useEffect(() => {
    if (!socketId) return
    socket.emit('room:join', { socketId, username, roomname })
  }, [socketId, username, roomname, userId])

  useEffect(() => {
    if (data) {
      dispatch(addMessages(data.data))
      scrollToBottom()
    }
  }, [dispatch, data])

  useEffect(() => {
    const listener = (data: any) => {
      const dateSent = new Date().toUTCString()
      const newMessage: Message = {
        id: data.id,
        from: data.username,
        message: data.text,
        dateSent: dateSent,
        read: data.read,
      }
      dispatch(addMessage(newMessage))
      scrollToBottom()
    }
    // Function to receive typing events
    const typingListener = (data: any) => {
      if (data.username === username) return
      if (data.typing) {
        setTypingUsername(data.username)
      } else {
        setTypingUsername('')
      }
    }

    const refreshListener = (data: any) => {
      console.log(
        'refreshListener',
        data,
        ', userMeId:',
        username,
        ', userOtherId:',
        userId
      )
      if (data.senderId === userId) {
        dispatch(api.util.invalidateTags(['messages']))
      }
    }

    socket.on('message', listener)
    socket.on('typing', typingListener)
    socket.on('refresh', refreshListener)
    return () => {
      socket.off('message', listener)
      socket.off('typing', typingListener)
      socket.off('refresh', refreshListener)
    }
  }, [dispatch, username, userId])

  const messagesEndRef = useRef() as MutableRefObject<HTMLDivElement>
  const scrollToBottom = () => {
    if (messagesEndRef?.current) {
      console.log('messagesEndRef?.current', messagesEndRef?.current)
      setTimeout(() => {
        console.log('Scrolling to bottom...')
        messagesEndRef.current.scrollIntoView({ behavior: 'auto' })
      }, 1000)
    }
  }

  // Function to send stop typing status to socket
  const stopTypingStatus = useCallback(() => {
    setStopTypingTimeout(null)
    socket.emit('room:stop-typing', {
      socketId,
      receiverId: userId,
    })
    setTypingStatusSent(false)
  }, [socketId, userId])

  // Function to send typing status to socket
  const sendTypingStatus = useCallback(() => {
    if (typingStatusSent) return
    if (stopTypingTimeout) {
      setStopTypingTimeout(null)
    }
    socket.emit('room:typing', {
      socketId,
      receiverId: userId,
    })
    setTypingStatusSent(true)
  }, [socketId, userId, stopTypingTimeout, typingStatusSent])

  const renderSwitch = (messages: Array<Message>, isLoading: boolean) => {
    if ((!isLoading && error) || (!getUserLoading && !getUserData)) {
      const errorResponse = error as ErrorResponse
      return (
        <Alert
          status="error"
          variant="subtle"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          textAlign="center"
          height="calc(100vh - 250px)"
        >
          <AlertIcon boxSize="40px" mr={0} />
          <AlertTitle mt={4} mb={1} fontSize="lg">
            {errorResponse?.data?.message ?? t('there_was_an_error')}
          </AlertTitle>
        </Alert>
      )
    } else if (isLoading) {
      return (
        <Spinner
          thickness="4px"
          speed="0.65s"
          emptyColor="primary.200"
          color="primary.500"
          size="xl"
        />
      )
    } else if (messages && messages.length === 0) {
      return <Center h="calc(100vh - 250px)">{t('no_messages_yet')}</Center>
    } else {
      return (
        <>
          <ChatMessages
            onMessageRead={onMessageRead}
            messages={messages}
            username={username}
          />
        </>
      )
    }
  }

  let personName = `${getUserData?.profile.name}`
  let personCompany = `${
    getUserData?.company ? getUserData?.company.name : APP_NAME
  }`
  if (usersCoach) {
    personName = `${usersCoach.profile.name}`
    personCompany = APP_NAME
  }

  return (
    <Flex
      w="full"
      flexDirection="column"
      bg={useColorModeValue('white', 'gray.900')}
    >
      <Flex px={6} overflowY="auto" flexDirection="column" flex={1}>
        <Stack mt={8} direction={'row'} spacing={4}>
          <IconButton
            onClick={onReturnClick}
            icon={<IoIosReturnLeft />}
            aria-label="Go back"
          />
          <Stat mt={6}>
            <Trans
              i18nKey="chatting_with"
              values={{ personName, personCompany }}
            >
              <StatLabel color="gray.500">Chatting with</StatLabel>
              <StatNumber>
                {personName} @ {personCompany}
              </StatNumber>
            </Trans>{' '}
            {typingUsername && (
              <Tag size={'md'} variant="solid" colorScheme="teal">
                {t('typing')}
              </Tag>
            )}
          </Stat>
        </Stack>
        {renderSwitch(messages, isLoading)}
      </Flex>
      <ChatInput
        userId={userId}
        sendTypingStatus={sendTypingStatus}
        stopTypingStatus={stopTypingStatus}
        mixpanel={mixpanel}
        socketId={socketId}
        onSendNewMessage={onSendNewMessage}
      />
    </Flex>
  )
}

export default Chat
