import { Center, Flex, Spinner, useToast } from '@chakra-ui/react'
import { FC, useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import {
  HUPO_CARE_ANDROID_APK_LOCATION_LINK,
  HUPO_CARE_ANDROID_PLAY_STORE_LINK,
  HUPO_CARE_IOS_APP_STORE_LINK,
} from '../../app/constants'
import { useGetUnifiedLinkQuery } from '../../app/services/api'
import { useLocale } from '../../hooks/useLocale'

const UnifiedLink: FC<any> = () => {
  const { t } = useLocale()
  const toast = useToast()
  const { replace } = useHistory()
  const { companyFriendlyId } = useParams() as any
  const [errorMessage, setErrorMessage] = useState()

  const { data, isSuccess, isLoading, error } = useGetUnifiedLinkQuery({
    companyFriendlyId,
  })
  console.log(
    'UnifiedLink, companyFriendlyId:',
    companyFriendlyId,
    ', data:',
    data,
    ', isLoading:',
    isLoading,
    ', isSuccess:',
    isSuccess
  )
  useEffect(() => {
    if (error) {
      setErrorMessage((error as any).data?.message)
    }
  }, [error])

  useEffect(() => {
    if (errorMessage) {
      toast({
        status: 'error',
        title: t('error'),
        description: errorMessage,
        isClosable: true,
      })
    }
  }, [t, toast, errorMessage])

  useEffect(() => {
    if (data) {
      if (data.os === 'web') {
        replace(data.redirect)
      } else if (data.os === 'ios') {
        window.location.replace(HUPO_CARE_IOS_APP_STORE_LINK)
      } else if (data.os === 'android') {
        const linkToNavigate =
          data.redirect_type === 'apk'
            ? HUPO_CARE_ANDROID_APK_LOCATION_LINK
            : HUPO_CARE_ANDROID_PLAY_STORE_LINK
        window.location.replace(linkToNavigate)
      }
    }
  }, [data, replace])

  return (
    <Flex
      alignItems="center"
      justifyContent="center"
      padding={10}
      direction="column"
    >
      {isLoading ? (
        <Center>
          <Spinner />
        </Center>
      ) : (
        <></>
      )}
    </Flex>
  )
}

export default UnifiedLink
