import { Center, Link, Spinner, Text } from '@chakra-ui/react'
import React, { useEffect } from 'react'
import { useHistory, useParams } from 'react-router-dom'

import { useAcceptEventMutation } from '../../app/services/api'
import Result from '../../Result'

export interface Item {
  label: string
  value: string
}

const REDIRECT_SECONDS = 10
const HOST = `${window.location.protocol}//${window.location.host}`

const Accept = () => {
  const { userId, eventId, isCoach } = useParams() as any
  const history = useHistory()
  const [counter, setCounter] = React.useState(REDIRECT_SECONDS)

  const [acceptEvent, { data, error, isLoading }] = useAcceptEventMutation()

  console.log('isCoach')
  console.log(isCoach)
  const redirectUrl = isCoach ? `${HOST}/coach` : HOST
  console.log(redirectUrl)

  useEffect(() => {
    const submitAcceptEvent = async (userId: string, eventId: string) => {
      await acceptEvent({ userId, eventId })
    }
    submitAcceptEvent(userId, eventId)
  }, [userId, eventId, acceptEvent])

  useEffect(() => {
    setTimeout(() => {
      window.location.href = redirectUrl
    }, REDIRECT_SECONDS * 1000)
  }, [history, redirectUrl])

  useEffect(() => {
    counter > 0 && setTimeout(() => setCounter(counter - 1), 1000)
  }, [counter])

  if (isLoading) {
    return (
      <Center h="200px" color="white">
        <Spinner
          thickness="4px"
          speed="0.65s"
          emptyColor="gray.200"
          color="blue.500"
          size="xl"
        />
      </Center>
    )
  }

  if (error || (!isLoading && !data)) {
    return (
      <Result
        type="error"
        headline="Failed to accept"
        description={
          <Text>
            Please email us at bek@hupo.co. Redirecting into{' '}
            <Link color="teal.500" href={redirectUrl}>
              Hupo
            </Link>{' '}
            in {counter} seconds.
          </Text>
        }
      />
    )
  } else if (data?.result === 'Already accepted') {
    return (
      <Result
        type="info"
        headline="The invitation is already accepted"
        description={
          <Text>
            We hope you will get the most out of the session! Redirecting into{' '}
            <Link color="teal.500" href={redirectUrl}>
              Hupo
            </Link>{' '}
            in {counter} seconds.
          </Text>
        }
      />
    )
  }

  return (
    <Result
      type="success"
      headline="Invitation is accepted"
      description={
        <Text>
          We hope you will get the most out of the session! Redirecting into{' '}
          <Link color="teal.500" href={redirectUrl}>
            Hupo
          </Link>{' '}
          in {counter} seconds.
        </Text>
      }
    />
  )
}

export default Accept
