import AgoraRTC, { ClientConfig } from 'agora-rtc-sdk'
import { useState } from 'react'

import { AGORA_APP_ID } from '../../app/constants'
import { useGetRTCTokenMutation } from '../../app/services/api'

export const config: ClientConfig = {
  mode: 'rtc',
  codec: 'vp8',
}

export const AUDIO_MEDIA = 'audio'
export const VIDEO_MEDIA = 'video'

export type MediaType = typeof AUDIO_MEDIA | typeof VIDEO_MEDIA

export const EVENT_ERROR = 'error'
export const EVENT_EXCEPTION = 'exception'
export const EVENT_STREAM_TYPE_CHANGED = 'stream-type-changed'
export const EVENT_STREAM_ADDED = 'stream-added'
export const EVENT_STREAM_SUBSCRIBED = 'stream-subscribed'
export const EVENT_CONNECTION_STATE_CHANGE = 'connection-state-change'
export const EVENT_STREAM_REMOVED = 'stream-removed'
export const EVENT_PEER_LEAVE = 'peer-leave'

export const client = AgoraRTC.createClient(config)
client.init(AGORA_APP_ID)

export const leaveChannel = async (client: any) => {
  await client.leave()
}

export function useAudioSdk(callerId: string, receiverId: string) {
  const [getRTCToken] = useGetRTCTokenMutation()
  const [stream, setStream] = useState<any>(null)

  if (!receiverId) {
    return { startCall: null }
  }

  const handleError = (err: any) => {
    console.log('Error: ', err)
  }

  const startCall = async (args: any) => {
    const {
      handleStreamAdded,
      handleStreamSubscribed,
      handleConnectionStateChange,
      handleStreamRemoved,
      handlePeerLeave,
      handleErr,
      handleException,
      handleStreamTypeChanged,
    } = args
    client.on(EVENT_STREAM_ADDED, handleStreamAdded)
    client.on(EVENT_STREAM_SUBSCRIBED, handleStreamSubscribed)
    client.on(EVENT_CONNECTION_STATE_CHANGE, handleConnectionStateChange)
    client.on(EVENT_STREAM_REMOVED, handleStreamRemoved)
    client.on(EVENT_PEER_LEAVE, handlePeerLeave)
    client.on(EVENT_EXCEPTION, handleException)
    client.on(EVENT_ERROR, handleErr)
    client.on(EVENT_STREAM_TYPE_CHANGED, handleStreamTypeChanged)

    console.log('useAudioSdk() receiverId:', receiverId)
    const response: any = await getRTCToken({ callerId, receiverId })

    if (!response || !response.data) {
      return
    }
    console.log('client.join (token, channelName, uid)')
    console.log(
      response.data.token,
      response.data.channelName,
      response.data.uid
    )
    await client.join(
      response.data.token,
      response.data.channelName,
      response.data.uid,
      'null',
      (userId: number) => {
        console.log('client.join SUCCESS')
        console.log(userId)
        const localStream = AgoraRTC.createStream({
          audio: true,
          video: false,
        })
        setStream(localStream)

        console.log('client and localStream')
        console.log(client)
        console.log(localStream)
        console.log(response.data.channelName)
        localStream.init(() => {
          try {
            localStream.play('me')
          } catch (err) {
            handleError(err)
          }
          client.publish(localStream, handleError)
        }, handleError)
      }
    )
    return response.data.channelName
  }

  return { startCall, stream }
}
