import React from 'react'
import { CUIAutoComplete } from 'chakra-ui-autocomplete'

export interface Item {
  label: string
  value: string
}

const Autocomplete = (props: any) => {
  const { label, placeholder, onSelectedItemsChange, selectedItems, items, listStyleProps } = props

  return (
    <CUIAutoComplete
      listStyleProps={listStyleProps}
      label={label}
      placeholder={placeholder}
      items={items}
      selectedItems={selectedItems}
      disableCreateItem={true}
      onSelectedItemsChange={onSelectedItemsChange}
    />
  )
}

export default Autocomplete
