import {
  CheckCircleIcon,
  CloseIcon,
  InfoIcon,
  WarningTwoIcon,
} from '@chakra-ui/icons'
import { Box, Flex, Heading } from '@chakra-ui/react'

type Type = 'success' | 'info' | 'warning' | 'error'
interface Props {
  type: Type
  headline: string
  description: JSX.Element
}
const Result = (props: Props) => {
  const { type, headline, description } = props

  const renderIcon = (type: Type) => {
    switch (type) {
      case 'success':
        return <CheckCircleIcon boxSize={'50px'} color={'green.500'} />
      case 'info':
        return <InfoIcon boxSize={'50px'} color={'blue.500'} />
      case 'warning':
        return <WarningTwoIcon boxSize={'50px'} color={'orange.300'} />
      case 'error':
        return (
          <Box display="inline-block">
            <Flex
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
              bg={'red.500'}
              rounded={'50px'}
              w={'55px'}
              h={'55px'}
              textAlign="center"
            >
              <CloseIcon boxSize={'20px'} color={'white'} />
            </Flex>
          </Box>
        )
      default:
        return <></>
    }
  }

  return (
    <Box textAlign="center" py={10} px={6}>
      {renderIcon(type)}
      <Heading as="h2" size="xl" mt={6} mb={2}>
        {headline}
      </Heading>
      {description}
    </Box>
  )
}

export default Result
