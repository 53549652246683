import OneSignal from 'react-onesignal'
import { ONE_SIGNAL_APP_ID, ONE_SIGNAL_SAFARI_WEB_ID } from '../app/constants'

export default async function runOneSignal(id: string) {
  await OneSignal.init({
    appId: ONE_SIGNAL_APP_ID,
    safari_web_id: ONE_SIGNAL_SAFARI_WEB_ID,
    allowLocalhostAsSecureOrigin: process.env.NODE_ENV === 'development',
    promptOptions: {
      slidedown: {
        enabled: true,
        actionMessage: 'Do you want to enable notifications?',
        acceptButtonText: 'Yes, please',
        cancelButtonText: 'No Thanks',
      },
    },
  }).catch(err => console.error(err))

  await OneSignal.showSlidedownPrompt().catch(err => console.error(err))
  await OneSignal.setExternalUserId(id).catch(err => console.error(err))
  console.log('OneSignal initialized. UserID', id)
}
