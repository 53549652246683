import GoogleMeetLink from './GoogleMeetLink'
import MicrosoftMeetLink from './MicrosoftMeetLink'
import ZoomLink from './ZoomLink'

const CallLink = ({ event, buttonSize = 'sm' }: any) => {
  return (
    <>
      {event.teamsMeetingLink && (
        <MicrosoftMeetLink event={event} buttonSize={buttonSize} />
      )}
      {event.zoomLink && <ZoomLink event={event} buttonSize={buttonSize} />}
      {!event.teamsMeetingLink && !event.zoomLink && (
        <GoogleMeetLink event={event} buttonSize={buttonSize} />
      )}
    </>
  )
}

export default CallLink
