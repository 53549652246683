import { IconButton } from '@chakra-ui/react'
import { BiArrowBack } from 'react-icons/bi'

export const GoBackButton = ({ onGoBack }: { onGoBack: () => void }) => {
  return (
    <IconButton
      aria-label="Go back"
      variant="outline"
      colorScheme="red"
      icon={<BiArrowBack />}
      onClick={onGoBack}
    />
  )
}
