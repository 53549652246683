import {
  Box,
  Heading,
  IconButton,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Text,
  Tooltip,
  useToast,
} from '@chakra-ui/react'
import moment from 'moment'
import { useCallback, useState } from 'react'
import { BsTrash } from 'react-icons/bs'
import { useHistory } from 'react-router-dom'

import { useDeleteBlockEventMutation } from '../../app/services/api'
import { useLocale } from '../../hooks/useLocale'

const BlockEvent = ({
  isOpen,
  onClose,
  event,
  onCallOpen,
  handleReschedule,
  guest,
  setGuest,
}: any) => {
  const { t } = useLocale()
  const history = useHistory()
  const toast = useToast()
  const [updating, setUpdating] = useState(false)
  const [deleteBlockEvent] = useDeleteBlockEventMutation()

  const parseEventTime = (start: Date, end: Date) => {
    const startMoment = moment(start)
    const endMoment = moment(end)
    return `${startMoment.format('dddd, MMMM D ⋅ h:mm')} - ${endMoment.format(
      'h:mma'
    )}`
  }

  const onCancel = useCallback(
    async (ev: any) => {
      try {
        await deleteBlockEvent(event.id).unwrap()
        toast({
          status: 'success',
          title: 'Success',
          description: 'The block is successfully deleted',
          isClosable: true,
        })
        onClose()
      } catch (err) {
        console.log(err)
        toast({
          status: 'error',
          title: t('error'),
          description: t('there_was_an_error'),
          isClosable: true,
        })
      }
    },
    [event, toast, t, onClose, deleteBlockEvent]
  )

  if (!event) {
    return null
  }

  const isPast = event.start < new Date()

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      isCentered
      motionPreset="scale"
      size={'lg'}
      trapFocus={false}
    >
      <ModalOverlay />
      <ModalContent>
        {/* {!isPast && ( */}
        <Tooltip label="Remove this from calendar">
          <IconButton
            onClick={onCancel}
            icon={<BsTrash />}
            aria-label="delete"
            variant="ghost"
            size={'lg'}
            position="absolute"
            top={'1px'}
            right={'40px'}
            _hover={{
              borderRadius: '40%',
              bg: 'gray.200',
            }}
          />
        </Tooltip>
        {/* )} */}
        <Tooltip label="Close">
          <ModalCloseButton />
        </Tooltip>
        <ModalBody pb={0}>
          <Box maxW="md" overflow="hidden">
            <Box m="2" as="a">
              <Heading m="5" mb="0" as="h4" size="md">
                Block
              </Heading>
              <Text m="5" mt="3px" fontSize={'sm'}>
                {parseEventTime(event.start, event.end)}
              </Text>
            </Box>
          </Box>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

export default BlockEvent
