import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Input,
  InputGroup,
  InputRightElement,
  Stack,
  Text,
  useToast,
} from '@chakra-ui/react'
import React, { ChangeEvent, useCallback, useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import TimezoneSelect from 'react-timezone-select'

import {
  useGetInvitationMutation,
  useSignupMutation,
} from '../../app/services/api'
import { useAuth } from '../../hooks/useAuth'
import { useLocale } from '../../hooks/useLocale'
import { SignupRequest } from '../../types/api'

function PasswordInput({
  name,
  onChange,
}: {
  name: string
  onChange: (event: ChangeEvent<HTMLInputElement>) => void
}) {
  const { t } = useLocale()
  const [show, setShow] = useState(false)
  const handleClick = () => setShow(!show)

  return (
    <InputGroup size="md">
      <Input
        pr="4.5rem"
        type={show ? 'text' : 'password'}
        placeholder={t('enter_password')}
        name={name}
        onChange={onChange}
      />
      <InputRightElement width="4.5rem">
        <Button h="1.75rem" size="sm" onClick={handleClick}>
          {show ? t('hide') : t('show')}
        </Button>
      </InputRightElement>
    </InputGroup>
  )
}

export default function Signup({
  invitation,
  isModal,
  onModalClose,
  ref = null,
}: any) {
  const { t } = useLocale()
  const { push } = useHistory()
  const toast = useToast()
  const { user } = useAuth()
  const { invitationId } = useParams() as any

  const [invitationData, setInvitationData] = useState<any>(invitation)
  const [isButtonDisabled, setIsButtonDisabled] = useState<boolean>(true)
  const [selectedTimezone, setSelectedTimezone] = useState<any>(
    Intl.DateTimeFormat().resolvedOptions().timeZone
  )

  const [signup] = useSignupMutation()
  const [getInvitation, { data: getInvitationData }] =
    useGetInvitationMutation()

  const [formState, setFormState] = useState<SignupRequest>({
    email: '',
    password: '',
    confirmPassword: '',
    name: '',
    coach: '',
    company: '',
    timezone: selectedTimezone,
  })

  useEffect(() => {
    if (user) {
      !isModal && push('/')
    }
  }, [push, user, isModal])

  useEffect(() => {
    const loadInvitation = async () => {
      await getInvitation(invitationId)
    }
    !invitation && loadInvitation()
  }, [invitation, invitationId, getInvitation])

  useEffect(() => {
    !invitation && getInvitationData && setInvitationData(getInvitationData)
  }, [getInvitationData, invitation])

  useEffect(() => {
    console.log('invitationData', invitationData)

    // If user is already registered show toast and redirect to login
    if (invitationData?.registered) {
      toast({
        status: 'warning',
        title: t('user_already_registered'),
        description: t('please_login'),
        isClosable: true,
      })
      push('/login')
    }

    // Set email, name, coach, company
    const changes = {
      email: invitationData?.email,
      name: invitationData?.name,
      coach: invitationData?.coach,
      company: invitationData?.company._id,
    }
    Object.entries(changes).forEach(([key, value]) =>
      setFormState((prev) => ({ ...prev, [key]: value }))
    )
  }, [invitationData, push, t, toast])

  useEffect(() => {
    if (selectedTimezone) {
      const name = 'timezone'
      const value = selectedTimezone
      setFormState((prev) => ({ ...prev, [name]: value }))
    }
  }, [selectedTimezone])

  useEffect(() => {
    setIsButtonDisabled(!(formState?.password && formState?.confirmPassword))
  }, [formState])

  const handleChange = ({
    target: { name, value },
  }: ChangeEvent<HTMLInputElement>) =>
    setFormState((prev) => ({ ...prev, [name]: value }))

  const onSubmit = useCallback(
    async (event) => {
      event.preventDefault()
      try {
        await signup(formState).unwrap()
        // Being that the result is handled in extraReducers in authSlice,
        // we know that we're authenticated after this, so the user
        // and token will be present in the store
        !isModal ? push('/') : onModalClose()
      } catch (err) {
        toast({
          status: 'error',
          title: t('error'),
          description: t('there_was_an_error'),
          isClosable: true,
        })
      }
    },
    [formState, signup, toast, push, isModal, onModalClose, t]
  )

  useEffect(() => {
    const myTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone
    invitationData &&
      toast({
        status: 'info',
        title: myTimezone,
        description: t('timezone_detected_description', {
          timezone: myTimezone,
        }),
        isClosable: true,
      })
  }, [t, toast, invitationData])

  if (!invitationData) return null

  return (
    <Flex
      minH={isModal ? '10vh' : '100vh'}
      align={'center'}
      justify={'center'}
      bg={'gray.50'}
    >
      <form onSubmit={onSubmit}>
        <Stack spacing={8} mx={'auto'} maxW={'lg'} py={12} px={6}>
          {!isModal ? (
            <Stack align={'center'}>
              <Heading fontSize={'4xl'}>{t('sign_up')}</Heading>
              <Text fontSize={'lg'} color={'gray.600'} align={'center'}>
                {t('ami_is_100%_secure_and_confidential_so')}
              </Text>
            </Stack>
          ) : (
            <Stack align={'center'}>
              <Heading alignItems="center" fontSize={'xl'}>
                {t('set_password_to_complete_your_booking')}
              </Heading>
            </Stack>
          )}
          <Box rounded={'lg'} bg={'white'} boxShadow={'lg'} p={8}>
            <Stack spacing={4}>
              <FormControl id="email">
                <FormLabel>{t('email')}</FormLabel>
                <Input
                  name="email"
                  value={invitationData.email}
                  type="text"
                  placeholder={t('email')}
                  disabled
                />
              </FormControl>
              <FormControl id="password">
                <FormLabel>{t('password')}</FormLabel>
                <PasswordInput onChange={handleChange} name="password" />
              </FormControl>
              <FormControl id="confirm_password">
                <FormLabel>{t('confirm_password')}</FormLabel>
                <PasswordInput onChange={handleChange} name="confirmPassword" />
              </FormControl>
              <FormControl id="timezone">
                <FormLabel>{t('timezone')}</FormLabel>
                <TimezoneSelect
                  name="timezone"
                  value={selectedTimezone}
                  onChange={setSelectedTimezone}
                />
              </FormControl>
              <Stack spacing={10}>
                <Button
                  disabled={isButtonDisabled}
                  colorScheme="primary"
                  onClick={onSubmit}
                  type="submit"
                >
                  {!isModal ? t('sign_up') : t('save')}
                </Button>
              </Stack>
            </Stack>
          </Box>
        </Stack>
      </form>
    </Flex>
  )
}
