import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Box,
  ButtonGroup,
  IconButton,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
} from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { FaMicrophone, FaMicrophoneSlash } from 'react-icons/fa'
import { ImPhone, ImPhoneHangUp } from 'react-icons/im'
import useSound from 'use-sound'

import {
  CALL_JOIN_AUDIO_URL,
  CALL_RINGING_AUDIO_URL,
  CALL_WAITING_AUDIO_URL,
} from '../../app/constants'
import { socket } from '../../hooks/socket'
import { useMixpanel } from '../../utils/MixpanelContext'
import { client, leaveChannel, useAudioSdk } from './audioSdk'

interface Props {
  isOpen: boolean
  onClose: () => void
  event: any
  user: any
  coach: any
}
const Call = (props: Props) => {
  const { isOpen, onClose, event, user, coach } = props
  const [isMuted, setIsMuted] = useState<boolean>(false)
  const [isStarted, setIsStarted] = useState<boolean>(false)
  const [hasMicPermissions, setHasMicPermissions] = useState(true)
  const [connectionState, setConnectionState] = useState('')
  const [customerJoined, setCustomerJoined] = useState(false)
  const [userId, setUserId] = useState('')
  const [coachId, setCoachId] = useState('')
  const [caller, setCaller] = useState<any>(null)
  const [receiver, setReceiver] = useState<any>(null)
  const mixpanel = useMixpanel()

  const [playRinging] = useSound(CALL_RINGING_AUDIO_URL)
  const [playWaiting, { stop: waitingSoundStop }] = useSound(
    CALL_WAITING_AUDIO_URL,
    { loop: true }
  )
  const [playCallJoin] = useSound(CALL_JOIN_AUDIO_URL)

  console.log('event', event)
  console.log('isOpen:', isOpen)

  const receiverId = coach ? userId : coachId
  const callerId = coach ? coachId : userId

  const { startCall, stream } = useAudioSdk(callerId, receiverId)

  useEffect(() => {
    if (!isOpen) return
    coach ? handleInitCall() : playRinging()
  }, [isOpen])

  useEffect(() => {
    if (!event) return

    let userId = event?.user?._id
    let coachId = event?.coach?._id
    if (coach) {
      setCaller(event.bookedByUser ? event.user : event.coach)
      setReceiver(event.bookedByUser ? event.coach : event.user)
    } else if (user) {
      if (event.bookedByUser) {
        setCaller(event.coach)
        setReceiver(event.user)
      } else {
        setCaller(event.user)
        setReceiver(event.coach)
      }
    }
    setUserId(userId)
    setCoachId(coachId)
    console.log('userId', userId)
    console.log('coachId', coachId)
  }, [event])

  useEffect(() => {
    if (!isOpen) return

    const onAcceptCall = ({ acceptedUserId }: any) => {
      console.log(`${acceptedUserId} accepted the call`)
      mixpanel.track('inapp_call_accepted')
    }
    socket.on('onAcceptCall', onAcceptCall)

    const onRejectCall = async ({ rejectedUserId }: any) => {
      console.log(`${rejectedUserId} rejected the call`)
      await handleRejectCall()
    }
    socket.on('onRejectCall', onRejectCall)

    const onLeaveCall = async ({ droppedUserId }: any) => {
      console.log(`${droppedUserId} left the call`)
      await handleRejectCall() // intentional: we don't want to send call:leave
    }
    socket.on('onLeaveCall', onLeaveCall)
  }, [isOpen])

  const handleToggleMute = async () => {
    if (stream) isMuted ? stream.unmuteAudio() : stream.muteAudio()
    setIsMuted(!isMuted)
  }

  const handleErr = (err: any) => {
    console.log('handleErr, err:', err)
  }
  const handleException = (evt: any) => {
    console.log('handleException, exception:', evt)
  }
  const handleStreamTypeChanged = (evt: any) => {
    console.log('handleStreamTypeChanged, evt:', evt)
  }

  const handleInitCall = async () => {
    let channelName
    console.log('handleInitCall, startCall:', startCall)
    if (startCall) {
      console.log('startCall')
      channelName = await startCall({
        handleStreamAdded,
        handleStreamSubscribed,
        handleConnectionStateChange,
        handleStreamRemoved,
        handlePeerLeave,
        handleErr,
        handleException,
        handleStreamTypeChanged,
      })
      if (coach) {
        playWaiting()
        socket.emit('call:join', { userId: coachId, channelName: userId })
        socket.emit('call:request', { userId, eventId: event.id })
        console.log('emit call:join, call:request')
      }
      setIsStarted(true)
      setConnectionState(client.getConnectionState())
    }
  }

  // Leave: when Coach initiated it
  const handleLeaveCall = async () => {
    console.log('handleLeaveCall')
    waitingSoundStop()
    try {
      await leaveChannel(client)
      mixpanel.track('inapp_call_finished')
    } catch (error) {
      console.log(error)
    }
    socket.emit('call:leave', { userId: coachId, channelName: userId })
    console.log('emit call:leave')
    setIsStarted(false)
    setCustomerJoined(false)
    onClose()
  }

  // Leave: when the other party (Client) initiated it
  const handleRejectCall = async () => {
    console.log('handleRejectCall')
    waitingSoundStop()
    try {
      await leaveChannel(client)
    } catch (error) {
      console.log(error)
    }
    setIsStarted(false)
    setCustomerJoined(false)
    onClose()
  }

  async function addVideoStream(elementId: string) {
    const remoteContainer = document.getElementById('remote')
    // Creates a new div for every stream
    const streamDiv = document.createElement('div')
    // Assigns the elementId to the div.
    streamDiv.id = elementId
    // Takes care of the lateral inversion
    streamDiv.style.transform = 'rotateY(180deg)'
    // Adds the div to the container.
    remoteContainer?.appendChild(streamDiv)
  }

  async function removeVideoStream(elementId: string) {
    const remoteDiv = document.getElementById(elementId)
    if (remoteDiv) {
      remoteDiv?.parentNode?.removeChild(remoteDiv)
    }
  }

  async function handleStreamAdded(evt: any) {
    console.log('handleStreamAdded')
    console.log(evt)
    client.subscribe(evt.stream)
  }

  async function handleStreamSubscribed(evt: any) {
    console.log('handleStreamSubscribed')
    console.log(evt)
    const stream = evt.stream
    const streamId = String(stream?.getId())
    console.log(streamId)
    addVideoStream(streamId)
    setCustomerJoined(true)
    waitingSoundStop()
    stream.play(streamId)
  }

  async function handleConnectionStateChange(evt: any) {
    console.log('handleConnectionStateChange, event:', evt)
    setConnectionState(evt.curState)
  }

  async function handleStreamRemoved(evt: any) {
    console.log('handleStreamRemoved')
    const stream = evt.stream
    const streamId = String(stream?.getId())
    console.log(streamId)
    stream.close()
    removeVideoStream(streamId)
  }

  async function handlePeerLeave(evt: any) {
    waitingSoundStop()
    console.log('handlePeerLeave')
    coach ? setCustomerJoined(false) : handleLeaveCall()
    const stream = evt.stream
    const streamId = String(stream?.getId())
    console.log(streamId)
    stream.close()
    removeVideoStream(streamId)
  }

  if (!event || !caller) {
    return null
  }

  return (
    <Modal
      closeOnOverlayClick={false}
      isOpen={isOpen}
      onClose={onClose}
      isCentered
      motionPreset="scale"
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          {coach
            ? `Calling with ${receiver.profile.name} @ ${
                receiver.company ? receiver.company.name : 'Hupo'
              }`
            : `Incoming call from ${caller.profile.name} @ Hupo`}
        </ModalHeader>
        <ModalCloseButton onClick={handleLeaveCall} />
        <ModalBody pb={0}>
          {hasMicPermissions ? (
            <Box maxW="sm" overflow="hidden">
              <Box m="5" as="a">
                <Text m="5" mt="3">
                  {coach
                    ? !customerJoined
                      ? 'Waiting for the customer'
                      : 'Customer is on the call'
                    : !customerJoined
                    ? 'Answer the call to start your session'
                    : 'You are on the call'}
                </Text>
              </Box>
            </Box>
          ) : (
            <Alert
              status="error"
              variant="subtle"
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
              textAlign="center"
              height="200px"
            >
              <AlertIcon boxSize="40px" mr={0} />
              <AlertTitle mt={4} mb={1} fontSize="lg">
                Microphone access is disabled :(
              </AlertTitle>
              <AlertDescription maxWidth="sm">
                You must allow your browser to access your microphone and
                speakers before being able to accept or make a call in Hupo.
              </AlertDescription>
            </Alert>
          )}
          <div id="me"></div>
          <div id="remote"></div>
        </ModalBody>
        <ModalFooter alignSelf="center">
          <ButtonGroup variant="solid">
            {isStarted ? (
              <>
                <IconButton
                  className={!isMuted ? 'on' : ''}
                  onClick={handleToggleMute}
                  colorScheme="blackAlpha"
                  aria-label="Toggle microphone"
                  icon={!isMuted ? <FaMicrophone /> : <FaMicrophoneSlash />}
                  padding={4}
                />
                <IconButton
                  onClick={handleLeaveCall}
                  colorScheme="red"
                  aria-label="hangup call"
                  icon={<ImPhoneHangUp />}
                  padding={4}
                />
              </>
            ) : (
              <>
                <IconButton
                  onClick={handleLeaveCall}
                  colorScheme="red"
                  aria-label="hangup call"
                  icon={<ImPhoneHangUp />}
                  padding={4}
                />
                <IconButton
                  onClick={handleInitCall}
                  colorScheme="green"
                  aria-label="answer call"
                  icon={<ImPhone />}
                  padding={4}
                />
              </>
            )}
          </ButtonGroup>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export default Call
