import {
  Button,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Input,
  InputGroup,
  InputRightElement,
  Stack,
  Text,
  useColorModeValue,
  useToast,
  VStack,
} from '@chakra-ui/react'
import { E164Number } from 'libphonenumber-js'
import { ChangeEvent, useCallback, useEffect, useState } from 'react'
import { Trans } from 'react-i18next'
import PhoneInput, {
  formatPhoneNumber,
  formatPhoneNumberIntl,
} from 'react-phone-number-input'
import 'react-phone-number-input/style.css'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import TimezoneSelect from 'react-timezone-select'
import * as yup from 'yup'

import { usePostOnboardingDataMutation } from '../../../app/services/api'
import { RootState } from '../../../app/store'
import { useLocale } from '../../../hooks/useLocale'
import { useMixpanel } from '../../../utils/MixpanelContext'
import { GoBackButton } from '../components/GoBackButton'

const LANGUAGE_PREFERENCE_QUESTION = 'coaching-language-preferences'

function PasswordInput({
  name,
  onChange,
}: {
  name: string
  onChange: (event: ChangeEvent<HTMLInputElement>) => void
}) {
  const { t } = useLocale()
  const [show, setShow] = useState(false)
  const handleClick = () => setShow(!show)

  return (
    <InputGroup size="md">
      <Input
        pr="4.5rem"
        type={show ? 'text' : 'password'}
        placeholder={t('enter_password')}
        name={name}
        onChange={onChange}
        bgColor="white"
      />
      <InputRightElement width="4.5rem">
        <Button h="1.75rem" size="sm" onClick={handleClick}>
          {show ? t('hide') : t('show')}
        </Button>
      </InputRightElement>
    </InputGroup>
  )
}

interface ProfileInfo {
  password: string
  timezone: string
  phonenumber: string
}

const FinalizeSetupStep = (props: any) => {
  const { step, onGoBack } = props
  const { t } = useLocale()
  const { push } = useHistory()
  const [errorMessage, setErrorMessage] = useState('')
  const [showError, setShowError] = useState(false)
  const mixpanel = useMixpanel()
  const toast = useToast()
  const onboarding = useSelector((state: RootState) => state.onboarding)
  const [selectedTimezone, setSelectedTimezone] = useState<any>(
    Intl.DateTimeFormat().resolvedOptions().timeZone
  )
  const askPhoneNumber = onboarding.onboardingData.askPhoneNumber

  const [phonenumber, setPhonenumber] = useState<E164Number>()
  const [defaultCountryCode, setDefaultCountryCode] = useState()

  useEffect(() => {
    fetch('https://ipapi.co/json/')
      .then((res) => res.json())
      .then((response) => {
        console.log('Country is : ', response)
        setDefaultCountryCode(response.country_code)
      })
      .catch((data) => {
        console.log('Request failed:', data)
      })
  }, [])

  const [postOnboardingData] = usePostOnboardingDataMutation()

  const formSchema = yup.object({
    password: yup
      .string()
      .required(t('password_required'))
      .min(8, t('password_requirements')),
    timezone: yup.string().required(t('timezone_required')),
  })
  const [formState, setFormState] = useState<ProfileInfo>({
    phonenumber: '',
    password: '',
    timezone: selectedTimezone,
  })

  useEffect(() => {
    if (selectedTimezone) {
      const name = 'timezone'
      console.log('Selected timezone: ', selectedTimezone)
      // Set value to selectedTimezone if it is string, otherwise get the timezone from the object
      const value =
        typeof selectedTimezone === 'string'
          ? selectedTimezone
          : selectedTimezone.value
      setFormState((prev) => ({ ...prev, [name]: value }))
    }
  }, [selectedTimezone])
  const [isButtonDisabled, setIsButtonDisabled] = useState<boolean>(false)

  useEffect(() => {
    mixpanel.track('onboarding_survey_questions_open', {
      page: 'setup_profile',
      step: step + 1,
      email: onboarding.email,
    })
  }, [mixpanel, step, onboarding])

  const onSubmit = useCallback(
    async (event) => {
      event.preventDefault()
      if (isButtonDisabled) {
        return
      }
      try {
        setShowError(false)
        formSchema.validateSync(formState)
      } catch (err: any) {
        const errorMessage = err.errors.join(', ')
        setErrorMessage(errorMessage)
        setShowError(true)
        return
      }
      try {
        setIsButtonDisabled(true)
        let onboardingLanguage
        if (LANGUAGE_PREFERENCE_QUESTION in onboarding.questions) {
          onboardingLanguage =
            onboarding.questions[LANGUAGE_PREFERENCE_QUESTION][0]
        } else {
          onboardingLanguage = onboarding.language
        }
        console.log('onboardingLanguage', onboardingLanguage)

        const onboardingData = {
          email: onboarding.email,
          password: formState.password,
          name: onboarding.name,
          timezone: formState.timezone,
          companyId: onboarding.companyId,
          language: onboardingLanguage,
          answers: onboarding.questions,
          phone: formatPhoneNumber(phonenumber!),
          fullPhone: formatPhoneNumberIntl(phonenumber!),
          version: '2',
        }
        console.log('onboardingData', onboardingData)
        const payload = await postOnboardingData(onboardingData).unwrap()
        if (payload.message) {
          toast({
            status: 'error',
            title: t('error'),
            description: payload.message,
            isClosable: true,
          })
          mixpanel.track('onboarding_response_fail', {
            message: payload.message,
          })
          setIsButtonDisabled(false)
        } else {
          mixpanel.track('onboarding_success')
          // Being that the result is handled in extraReducers in authSlice,
          // we know that we're authenticated after this, so the user
          // and token will be present in the store
          push('/')
        }
      } catch (err) {
        toast({
          status: 'error',
          title: t('error'),
          description: t('there_was_an_error'),
          isClosable: true,
        })
        setIsButtonDisabled(false)
      }
    },
    [
      formState,
      isButtonDisabled,
      toast,
      t,
      formSchema,
      onboarding,
      mixpanel,
      postOnboardingData,
      phonenumber,
      push,
    ]
  )

  const handleChange = ({
    target: { name, value },
  }: ChangeEvent<HTMLInputElement>) =>
    setFormState((prev) => ({ ...prev, [name]: value }))

  return (
    <Flex flexDir={'column'} align={'center'} h="100vh">
      <VStack my={6} mx={12} maxW={'2xl'}>
        <Heading fontSize={'2xl'} textAlign={'center'}>
          {t('full-onboarding-laststep-title')}
        </Heading>
      </VStack>
      <Flex py={4} maxW={'2xl'} bg={useColorModeValue('gray.50', 'gray.800')}>
        <form onSubmit={onSubmit}>
          <Stack spacing={4} mx={'auto'} maxW={'lg'} py={6} px={6}>
            <FormControl id="email">
              <FormLabel>{t('email')}</FormLabel>
              <Input
                name="email"
                value={onboarding.email}
                disabled={true}
                type="text"
                minW={'sm'}
              />
            </FormControl>
            <FormControl id="password">
              <FormLabel>{t('password')}</FormLabel>
              <PasswordInput onChange={handleChange} name="password" />
            </FormControl>
            {askPhoneNumber ? (
              <FormControl id="phonenumber">
                <FormLabel>{t('phonenumber')}</FormLabel>
                <PhoneInput
                  placeholder={t('phonenumber_placeholder')}
                  defaultCountry={defaultCountryCode}
                  value={phonenumber}
                  onChange={setPhonenumber}
                />
              </FormControl>
            ) : (
              <></>
            )}
            <FormControl id="timezone">
              <FormLabel>{t('timezone')}</FormLabel>
              <TimezoneSelect
                name="timezone"
                value={selectedTimezone}
                onChange={setSelectedTimezone}
              />
            </FormControl>
          </Stack>
          <Stack spacing={0} pt={5} pb={5} align={'center'}>
            {showError && errorMessage ? (
              <Text mb={4} color={'red.600'}>
                {errorMessage}
              </Text>
            ) : (
              <></>
            )}
            <Flex gridGap={1} justify="space-between">
              <GoBackButton onGoBack={onGoBack} />
              <Button
                disabled={isButtonDisabled}
                colorScheme="primary"
                onClick={onSubmit}
                minW="xs"
                type="submit"
                mb={5}
              >
                {t('continue')}
              </Button>
            </Flex>
            <Trans i18nKey="user_agreement_label">
              By submitting this form you agree to our
              <a
                style={{ color: 'royalblue' }}
                href="https://www.hupo.co/care/user-agreement"
                target="_blank"
                rel="noopener noreferrer"
              >
                Link to user agreement
              </a>
            </Trans>
          </Stack>
        </form>
      </Flex>
    </Flex>
  )
}

export default FinalizeSetupStep
