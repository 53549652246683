import { Box, Button, Center, Flex, Heading, Spinner, Stack, Text, Textarea, useToast, VStack } from '@chakra-ui/react'
import moment from 'moment-timezone'
import { useState } from 'react'
import { AiOutlineClockCircle } from 'react-icons/ai'
import { MdDateRange } from 'react-icons/md'
import { useParams } from 'react-router-dom'
import { DURATION_LABEL_MAP } from '../../app/constants'
import { useGetBookingRequestQuery, usePostBookingRequestMutation } from '../../app/services/api'
import { useLocale } from '../../hooks/useLocale'
import Result from '../../Result'

const BookingRequestConfirmation = () => {
  const { requestId } = useParams() as any
  const { t } = useLocale()
  const toast = useToast()
  const [submitText, setSubmitText] = useState<string>()

  const { data, isLoading, error } = useGetBookingRequestQuery({requestId})
  const [postBookingRequest] = usePostBookingRequestMutation()
  const [value, setValue] = useState('')

  if (submitText) {
    return (
      <Result
        type="success"
        headline={submitText}
        description={
          <Text>
            You can now safely close this window.
          </Text>
        }
      />
    )
  }

  if (isLoading) {
    return (
      <Center h="200px" color="white">
        <Spinner
          thickness="4px"
          speed="0.65s"
          emptyColor="gray.200"
          color="blue.500"
          size="xl"
        />
      </Center>
    )
  }

  if (error) {
    return (
      <Result
        type="error"
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        headline={error.data.message}
        description={<Text></Text>}
      />
    )
  }

  if (error || (!isLoading && !data)) {
    return (
      <Result
        type="error"
        headline="Failed to load booking request"
        description={
          <Text>
            Error occurred while loading booking request.
          </Text>
        }
      />
    )
  }

  const onAccept = async () => {
    try {
      await postBookingRequest({requestId, accepted: true}).unwrap()
      setSubmitText('Booking request accepted')
    } catch (err: any) {
      console.log(err)
      toast({
        status: 'error',
        title: 'Error while accepting booking request',
        description: err,
        isClosable: true,
      })
    }
  }

  const onSuggestAlternative = async () => {
    if (!value) {
      return toast({
        status: 'error',
        title: 'Error',
        description: 'Please enter alternative suggested time.',
        isClosable: true,
      })
    }
    try {
      await postBookingRequest({requestId, recommendedSlots: value}).unwrap()
      setSubmitText('Alternative time suggested.\n Hupo team will handle the booking request from here.')
    } catch (err: any) {
      console.log(err)
      toast({
        status: 'error',
        title: 'Error while submitting alternative time',
        description: err,
        isClosable: true,
      })
    }
  }

  console.log('data:', data)

  return (
    <Box textAlign="center" py={10} px={6}>
      <Heading size="lg" fontWeight={600} mb={2}>
        {'Booking Request from ' + data.user.profile.name 
        + ' for ' + t(DURATION_LABEL_MAP[moment(data.end).diff(moment(data.start), 'minutes')])}
      </Heading>
      <Stack direction={'row'} justify={'center'} spacing={0} mt={6}>
        <Stack spacing={0} align={'center'}>
          <Flex alignItems="center">
            <Text fontSize="23px">
              <MdDateRange />
            </Text>
            <Text fontSize="md" pl={2} mr={5}>
              {moment(data.start).format('DD MMM')}
            </Text>
            <Text fontSize="23px">
              <AiOutlineClockCircle />
            </Text>
            <Text fontSize="md" pl={2}>
              {moment(data.start)
                .tz(data.user?.timezone)
                .format('HH:mm ([GMT]Z)')}
            </Text>
          </Flex>
        </Stack>
      </Stack>
      <Button
        bg={'primary.500'}
        color={'white'}
        my={10}
        w={200}
        _hover={{
          bg: 'primary.700',
        }}
        onClick={onAccept}
      >
       {t('accept')}
      </Button>
      <Text fontSize={'md'} fontWeight={'600'}>---- {t('or').toUpperCase()} ----</Text>
      <VStack mt={10}>
        <Text fontSize={'md'} fontWeight={'600'}>{t('suggest_a_new_time')}</Text>
        <Textarea
            value={value}
            onChange={(event) => setValue(event.target.value)}
            placeholder={'e.g. ' + moment(data.start).add(1, 'day').format('DD MMM') 
              + ' ' + moment(data.start).add(2, 'hours').format('HH:mm') 
              + ', or anytime on ' + moment(data.start).add(3, 'day').format('DD MMM') }
            height="200px"
            width={400}
          />
        <Button
          bg={'primary.500'}
          color={'white'}
          onClick={onSuggestAlternative}
          w={200}
          _hover={{
            bg: 'primary.700',
          }}
        >
        {t('submit')}
        </Button>
      </VStack>
    </Box>
  )
}

export default BookingRequestConfirmation
