import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { logout } from '../features/auth/authSlice'

import { useFlagsmith } from 'flagsmith/react'
import Cookies from 'js-cookie'
import { useMixpanel } from '../utils/MixpanelContext'

export const useLogout = () => {
  const history = useHistory()
  const flagsmith = useFlagsmith()
  const mixpanel = useMixpanel()
  const dispatch = useDispatch()

  const onLogout = () => {
    localStorage.clear()
    Cookies.remove('AmiEmail', { path: '/', domain: 'hupo.co' })
    Cookies.remove('AmiPassword', { path: '/', domain: 'hupo.co' })
    dispatch(logout())
    mixpanel.track('logout_success')
    mixpanel.reset()
    flagsmith.logout()
    // @ts-ignore
    if (window._chatlio) {
      // @ts-ignore
      window._chatlio.reset()
    }
    history.push('/login')
  }

  return onLogout
}
