import React, { HTMLAttributes } from 'react'
import ReactDatePicker, { registerLocale } from 'react-datepicker'
import { useColorMode } from '@chakra-ui/react'
import en from 'date-fns/locale/en-US'
import ko from 'date-fns/locale/ko'

import 'react-datepicker/dist/react-datepicker.css'
import './date-picker.css'

registerLocale('en', en)
registerLocale('ko', ko)

interface Props {
  isClearable?: boolean
  onChange?: (date: Date) => any
  selectedDate: Date | undefined
  showPopperArrow?: boolean
  onSelect?: any
  showTimeSelect?: boolean
  dateFormat?: string
  minDate?: Date
  maxDate?: Date
  inline?: boolean
  excludeDates?: Date[]
  language?: string
  filterTime?: any
}

const DatePicker = ({
  selectedDate,
  onChange = () => {},
  onSelect = (date: Date, event: any) => {
    console.log('Selected')
    console.log(date)
    console.log(event)
  },
  inline = false,
  dateFormat = 'Pp',
  isClearable = false,
  showPopperArrow = false,
  language = 'en',
  ...props
}: Props & HTMLAttributes<HTMLElement>) => {
  const isLight = useColorMode().colorMode === 'light' //you can check what theme you are using right now however you want

  return (
    // if you don't want to use chakra's colors or you just wwant to use the original ones,
    // set className to "light-theme-original" ↓↓↓↓
    <div className={isLight ? 'light-theme' : 'dark-theme'}>
      <ReactDatePicker
        locale={language ?? 'en'}
        onSelect={onSelect}
        selected={selectedDate}
        onChange={onChange}
        isClearable={isClearable}
        showPopperArrow={showPopperArrow}
        dateFormat={dateFormat}
        className="react-datapicker__input-text" //input is white by default and there is no already defined class for it so I created a new one
        inline={inline}
        timeClassName={() => 'react-datepicker__time-select'}
        filterTime={props.filterTime}
        {...props}
      />
    </div>
  )
}

export default DatePicker
