import { Schedule, TimeRange } from '../types/schedule'

export const APP_NAME = 'Hupo'
export const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT || '/'
export const AGORA_APP_ID: string = process.env.REACT_APP_AGORA_APP_ID || ''

export const GA_TRACKING_ID = process.env.REACT_GA_TRACKING_ID || ''
export const FLAGSMITH_ENVIRONMENT_ID =
  process.env.REACT_APP_FLAGSMITH_ENVIRONMENT_ID || ''
export const MIXPANEL_TOKEN =
  (process.env.REACT_APP_MIXPANEL_TOKEN as string) || ''

export const ONE_SIGNAL_APP_ID: string =
  process.env.REACT_APP_ONE_SIGNAL_APP_ID || ''

export const ONE_SIGNAL_SAFARI_WEB_ID: string =
  process.env.REACT_APP_ONE_SIGNAL_SAFARI_WEB_ID || ''

export const DURATION_OPTIONS = [
  {
    label: '30_min',
    value: 30,
  },
  {
    label: '1_hour',
    value: 60,
  },
]
export const DURATION_LABEL_MAP: Record<number, string> = {
  30: '30_min',
  60: '1_hour',
}

// Notification sounds
export const SEND_AUDIO_URL = 'https://puu.sh/GSHJ0/25fae22f76.mp3'
export const RECEIVE_AUDIO_URL = 'https://puu.sh/GSHIU/df806a9cb8.mp3'
export const CALL_RINGING_AUDIO_URL =
  'https://assets.mixkit.co/sfx/download/mixkit-office-telephone-ring-1350.wav'
export const CALL_WAITING_AUDIO_URL =
  'https://assets.mixkit.co/sfx/download/mixkit-waiting-ringtone-1354.wav'
export const CALL_JOIN_AUDIO_URL =
  'https://assets.mixkit.co/sfx/download/mixkit-magic-notification-ring-2344.wav'

export const defaultDayRange: TimeRange = {
  start: new Date(new Date().setUTCHours(9, 0, 0, 0)),
  end: new Date(new Date().setUTCHours(17, 0, 0, 0)),
}

export const DEFAULT_SCHEDULE: Schedule = [
  [],
  [defaultDayRange],
  [defaultDayRange],
  [defaultDayRange],
  [defaultDayRange],
  [defaultDayRange],
  [],
]
export const MAXIMUM_BOOKING_DAYS = 30
export const DEFAULT_MOOD_SCORE = 3

export const HUPO_CARE_IOS_APP_STORE_LINK =
  'https://apps.apple.com/app/ami-mental-wellness-coaching/id1618739473'
export const HUPO_CARE_ANDROID_PLAY_STORE_LINK =
  'https://play.google.com/store/apps/details?id=co.getami.app'
export const HUPO_CARE_ANDROID_APK_LOCATION_LINK =
  'https://dopmo1eihgbgm.cloudfront.net/64ef24c66c4ee16ef0a80313/app-standalone-release-1.0.47.apk'

export const LANGUAGE_PREFERENCE_QUESTION = 'coaching-language-preferences'

export const BookingFlow = {
  FlowA: 'flowA',
  FlowB: 'flowB',
  FlowC: 'flowC',
  FlowD: 'flowD',
}
