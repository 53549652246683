import {
  Box,
  Checkbox,
  Divider,
  Flex,
  HStack,
  IconButton,
  SimpleGrid,
  Spacer,
  Text,
} from '@chakra-ui/react'
import moment, { Moment } from 'moment-timezone'
import React, { useCallback, useEffect, useState } from 'react'
import { Controller, useFieldArray } from 'react-hook-form'
import { BsFillTrashFill, BsPlusCircleFill } from 'react-icons/bs'

import { defaultDayRange } from '../../app/constants'
import { useLocale } from '../../hooks/useLocale'
import { TimeRange } from '../../types/schedule'
import Select from './Select'

/** Begin Time Increments For Select */
const TIME_INCREMENT = 30
/** End Time Increments For Select */

const TimeRangeField = ({ name, allTimeSlots }: any) => {
  // Lazy-loaded options, otherwise adding a field has a noticable redraw delay.
  const [options, setOptions] = useState<any[]>([])
  const [selected, setSelected] = useState<number | undefined>()
  // const { i18n } = useLocale();

  const handleSelected = (value: number | undefined) => {
    setSelected(value)
  }

  const getOption = (time: Moment) => {
    return {
      value: moment(time).toDate().valueOf(),
      label: moment(time).utc().format('h:mma'),
    }
  }

  const timeOptions = useCallback(
    (
      offsetOrLimitorSelected: {
        offset?: number
        limit?: number
        selected?: number
      } = {}
    ) => {
      const { limit, offset, selected } = offsetOrLimitorSelected
      return allTimeSlots
        .filter(
          (time: Moment) =>
            (!limit || time.isBefore(limit)) &&
            (!offset || time.isAfter(offset)) &&
            (!selected || time.isAfter(selected))
        )
        .map((t: Moment) => getOption(t))
    },
    [allTimeSlots]
  )

  return (
    <HStack>
      <Controller
        name={`${name}.start`}
        render={({ field: { onChange, value } }) => {
          handleSelected(value)
          return (
            <Select
              options={options}
              onFocus={() => setOptions(timeOptions())}
              onBlur={() => setOptions([])}
              defaultValue={getOption(value)}
              onChange={(option) => {
                onChange(new Date(option?.value as number))
                handleSelected(option?.value)
              }}
            />
          )
        }}
      />
      <span>-</span>
      <Controller
        name={`${name}.end`}
        render={({ field: { onChange, value } }) => (
          <Select
            options={options}
            onFocus={() => setOptions(timeOptions({ selected }))}
            onBlur={() => setOptions([])}
            defaultValue={getOption(value)}
            onChange={(option) => onChange(new Date(option?.value as number))}
          />
        )}
      />
    </HStack>
  )
}

const ScheduleBlock = ({ name, day, weekday }: any) => {
  const { fields, append, remove, replace } = useFieldArray({
    name: `${name}.${day}`,
  })

  const { t } = useLocale()

  const [allTimeSlots, setAllTimeSlots] = useState<Moment[]>()

  useEffect(() => {
    const timeSlots = (() => {
      const end = moment().utc().endOf('day')
      /**
       * Creates an array of times on a 30 minute interval from
       * 00:00:00 (Start of day) to
       * 23:45:00 (End of day with enough time for 30 min booking)
       */
      const times: Moment[] = []
      let inc = 0
      while (true) {
        const newT = moment()
          .utc()
          .startOf('day')
          .add(TIME_INCREMENT * inc, 'minutes')
        if (newT.isAfter(end)) break
        times.push(newT)
        inc = inc + 1
      }
      return times
    })()
    setAllTimeSlots(timeSlots)
  }, [])

  const handleAppend = () => {
    const range = fields[fields.length - 1] as unknown as TimeRange
    const nextRangeStart = moment(range.end).add(1, 'hour').startOf('hour')
    const nextRangeEnd = moment(nextRangeStart).add(1, 'hour')
    return append({
      start: nextRangeStart.toDate(),
      end: nextRangeEnd.toDate(),
    })
  }

  if (!fields.length) {
    return (
      <SimpleGrid columns={3} spacing={10}>
        <Box>
          <Checkbox
            isChecked={false}
            onChange={(e) =>
              e.target.checked ? replace([defaultDayRange]) : replace([])
            }
          >
            {weekday}
          </Checkbox>
        </Box>
        <Box>
          <Text mb={2} color="grey">
            {t('unavailable')}
          </Text>
        </Box>
        <Box></Box>
      </SimpleGrid>
    )
  }

  return (
    <>
      {fields.map((field, index) => (
        <Flex>
          <Box w={'30%'}>
            {index === 0 && (
              <Checkbox
                isChecked={true}
                onChange={(e) =>
                  e.target.checked ? replace([defaultDayRange]) : replace([])
                }
              >
                {weekday}
              </Checkbox>
            )}
          </Box>
          <Box>
            <div key={field.id}>
              <TimeRangeField
                name={`${name}.${day}.${index}`}
                allTimeSlots={allTimeSlots}
              />
            </div>
          </Box>
          <Spacer />
          <Box mb={1} textAlign="right">
            <IconButton
              aria-label="Remove"
              size="md"
              color="minimal"
              icon={<BsFillTrashFill />}
              type="button"
              onClick={() => remove(index)}
            />
            {index === 0 && fields.length > 0 && (
              <IconButton
                aria-label="Add"
                type="button"
                color="minimal"
                size="md"
                icon={<BsPlusCircleFill />}
                onClick={handleAppend}
              />
            )}
          </Box>
        </Flex>
      ))}
    </>
  )
}

const Schedule = ({ name }: { name: string }) => {
  const [weekdayNames, setWeekdayNames] = useState<string[]>([])

  useEffect(() => {
    const weekdayNames = moment.weekdays()
    setWeekdayNames(weekdayNames)
  }, [])

  return (
    <Box>
      {weekdayNames.map((weekday, num) => (
        <Box my={5}>
          <ScheduleBlock key={num} name={name} weekday={weekday} day={num} />
          <Divider />
        </Box>
      ))}
    </Box>
  )
}

export default Schedule
