import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Box,
  Button,
  Divider,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Grid,
  GridItem,
  Heading,
  HStack,
  IconButton,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spinner,
  StackDivider,
  Tag,
  TagLabel,
  TagLeftIcon,
  Text,
  Textarea,
  useDisclosure,
  useToast,
  VStack,
} from '@chakra-ui/react'
import { Select } from 'chakra-react-select'
import moment from 'moment-timezone'
import React, {
  MutableRefObject,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { BsPlusCircleFill } from 'react-icons/bs'
import { FiEdit2, FiTrash2, FiHash } from 'react-icons/fi'
import { MdDateRange } from 'react-icons/md'

import {
  useDeleteNoteMutation,
  useDeleteUserNoteMutation,
  useGetNotesMutation,
  useGetUserNotesQuery,
  usePostNoteMutation,
  usePostUserNoteMutation,
  useUpdateNoteMutation,
  useUpdateUserNoteMutation,
} from '../../app/services/api'
import {
  FlatCoachingTags,
  GroupedCoachingTags,
  UsedTechniques,
} from '../../utils/constants/coaching-tags'
import { useMixpanel } from '../../utils/MixpanelContext'
import { parseText } from '../../utils/string'

const Notes = ({ coach, userId, user }: any) => {
  const { isOpen, onOpen, onClose: onModalClose } = useDisclosure()
  const {
    isOpen: isAlertOpen,
    onOpen: onAlertOpen,
    onClose: onAlertClose,
  } = useDisclosure()
  const { t } = useTranslation()
  const [note, setNote] = useState('')
  const [userMoodScore] = useState<number | null>(null)
  const [updating, setUpdating] = useState(false)
  const [noteId, setNoteId] = useState<string | null>(null)
  const [getNotes, { data: notes, isLoading }] = useGetNotesMutation()
  const [postNote] = usePostNoteMutation()
  const [updateNote] = useUpdateNoteMutation()
  const [deleteNote] = useDeleteNoteMutation()
  const { data: userNotes } = useGetUserNotesQuery()
  const [deleteUserNote] = useDeleteUserNoteMutation()
  const [updateUserNote] = useUpdateUserNoteMutation()
  const [createUserNote] = usePostUserNoteMutation()
  const mixpanel = useMixpanel()

  const form = useForm({ reValidateMode: 'onBlur' })

  const onClose = useCallback(() => {
    onModalClose()
    form.reset({
      session_length: undefined,
      topics: [],
      techniques: [],
      flags: undefined,
      action_items: undefined,
      follow_ups: undefined,
    })
    setUpdating(false)
  }, [form, onModalClose])

  useEffect(() => {
    if (user) {
      mixpanel.track('note_screen_open')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const toast = useToast()
  const initialRef = useRef() as any
  const cancelRef = useRef() as MutableRefObject<HTMLInputElement>

  const retrieveNotes = useCallback(
    async (userId: string) => {
      await getNotes(userId)
    },
    [getNotes]
  )

  useEffect(() => {
    if (userId) {
      retrieveNotes(userId)
    }
  }, [retrieveNotes, userId])

  const onAdd = useCallback(() => {
    setNote('')
    onOpen()
    if (user) {
      mixpanel.track('note_create')
    }
  }, [onOpen, mixpanel, user])

  const onNoteChange = useCallback(
    (e: React.ChangeEvent<HTMLTextAreaElement>) => {
      setNote(e.target.value)
    },
    [setNote]
  )

  const onSubmit = useCallback(
    async (event) => {
      event.preventDefault()
      try {
        if (updating) {
          const updatedNote = {
            id: noteId!,
            body: note,
          }
          if (coach) {
            await updateNote(updatedNote).unwrap()
          } else {
            try {
              await updateUserNote({
                id: noteId,
                body: { body: note },
              }).unwrap()
              mixpanel.track('note_update_success')
            } catch (error: any) {
              mixpanel.track('note_update_fail', {
                message: error?.data?.message,
              })
            }
          }
        } else {
          if (coach) {
            const newNote = {
              userId,
              content: note,
              userMoodScore,
            }
            await postNote(newNote).unwrap()
          } else {
            try {
              const newNote = {
                body: note,
              }
              await createUserNote(newNote).unwrap()
              mixpanel.track('note_create_success')
            } catch (error: any) {
              mixpanel.track('note_create_fail', {
                message: error?.data?.message,
              })
            }
          }
        }
        if (coach) {
          await retrieveNotes(userId)
        }
        onClose()
        toast({
          status: 'success',
          description:
            'The note is successfully ' + (updating ? 'updated' : 'added'),
          isClosable: true,
        })
        setUpdating(false)
      } catch (err) {
        toast({
          status: 'error',
          title: 'Error',
          description: 'Error while saving a note',
          isClosable: true,
        })
      }
    },
    [
      updating,
      coach,
      onClose,
      toast,
      noteId,
      note,
      updateNote,
      updateUserNote,
      userId,
      postNote,
      createUserNote,
      retrieveNotes,
      mixpanel,
      userMoodScore,
    ]
  )

  const onCoachSubmit = useCallback(
    async (data: any) => {
      const body = {
        ...data,
        session_length: Number(data.session_length),
        techniques: data.techniques.map((item: any) => item.value),
        topics: data.topics.map((item: any) => item.value),
      }
      try {
        if (updating) {
          const updatedNote = {
            id: noteId!,
            body,
          }
          await updateNote(updatedNote).unwrap()
        } else {
          const newNote = {
            userId,
            ...(coach ? { content: body } : body),
            userMoodScore,
          }
          await postNote(newNote).unwrap()
        }
        await retrieveNotes(userId)
        onClose()
        toast({
          status: 'success',
          description:
            'The note is successfully ' + (updating ? 'updated' : 'added'),
          isClosable: true,
        })
        setUpdating(false)
      } catch (err) {
        toast({
          status: 'error',
          title: 'Error',
          description: 'Error while saving a note',
          isClosable: true,
        })
      }
    },
    [
      noteId,
      onClose,
      postNote,
      retrieveNotes,
      toast,
      updateNote,
      updating,
      userId,
      userMoodScore,
      coach,
    ]
  )

  const onEdit = useCallback(
    (e: any) => {
      if (coach) {
        const coachEditData = JSON.parse(
          e.currentTarget.getAttribute('data-item')
        )
        const techniques: any[] = []
        const topics: any[] = []
        coachEditData.topics.forEach((key: any) => {
          const item = FlatCoachingTags.find((elem) => elem.value === key)
          topics.push(item)
        })
        coachEditData.techniques.forEach((key: any) => {
          const item = UsedTechniques.find((elem) => elem.value === key)
          techniques.push(item)
        })
        const editData = {
          ...coachEditData,
          topics,
          techniques,
        }
        Object.keys(editData).forEach((key: any) => {
          form.setValue(key, editData[key])
        })
      }
      const note = e.currentTarget.getAttribute('data-body')
      const noteId = e.currentTarget.getAttribute('data-id')
      setNote(note)
      setNoteId(noteId)
      setUpdating(true)
      onOpen()
      if (user) {
        mixpanel.track('note_update')
      }
    },
    [onOpen, mixpanel, user, coach, form]
  )

  const onDelete = async (e: any) => {
    if (!noteId) return
    try {
      if (coach) {
        await deleteNote(noteId).unwrap()
        await retrieveNotes(userId)
      } else {
        try {
          await deleteUserNote(noteId).unwrap()
          mixpanel.track('note_delete_success')
        } catch (error: any) {
          mixpanel.track('note_delete_fail', { message: error?.data?.message })
        }
      }
      onAlertClose()
      toast({
        status: 'success',
        description: 'The note is successfully deleted',
        isClosable: true,
      })
    } catch (err) {
      toast({
        status: 'error',
        title: 'Error',
        description: 'Error while saving a note',
        isClosable: true,
      })
    }
  }

  const onDeleteClick = (e: any) => {
    setNoteId(e.currentTarget.getAttribute('data-id'))
    onAlertOpen()
  }

  return (
    <Flex w="full" flexDirection="column" bg={'white'} borderRadius={10}>
      <Box width={'full'} my={6} pl={6} pr={4}>
        <Flex width={'full'} my={1} justifyContent={'space-between'} mb={2}>
          <Box>
            <Heading size={'md'}>
              {user ? t('my_notes') : t('coach_private_notes')}
            </Heading>
          </Box>
          <IconButton
            onClick={onAdd}
            colorScheme="primary"
            aria-label={t('create_new_note')}
            icon={<BsPlusCircleFill />}
            size={'sm'}
          />
        </Flex>
        <Divider />
        {isLoading && (
          <Box textAlign="center" py={10} px={6}>
            <Spinner />
          </Box>
        )}
        {(notes && notes.length > 0) || (userNotes && userNotes.length > 0) ? (
          <VStack
            divider={<StackDivider borderColor="gray.200" />}
            spacing={4}
            align="stretch"
          >
            {(notes ? notes : (userNotes as any[])).map((note: any) => (
              <Grid key={note._id} templateColumns="repeat(5, 1fr)" gap={4}>
                <GridItem colSpan={7} my={2}>
                  <Flex my={2} color="gray">
                    <MdDateRange size="18px" />
                    <Text fontSize={'sm'} ml={1}>
                      {moment(note.createdAt)
                        .tz((coach || user).timezone)
                        .format('MMM DD, YYYY h:mm A')}
                    </Text>
                  </Flex>
                  {coach && !note.body && (
                    <>
                      <Text>
                        {' '}
                        <b>{t('session_length')}:</b>{' '}
                        {note.content.session_length} {t('minute_or_minutes')}{' '}
                      </Text>
                      <Flex
                        my={2}
                        gridGap={3}
                        flexWrap="wrap"
                        direction="row"
                        justifyContent="flex-start"
                      >
                        <Text>
                          {' '}
                          <b>{t('discussed_topics')}:</b>
                        </Text>
                        <HStack gridGap={2} flexWrap="wrap" spacing={3}>
                          {note.content.topics.map((key: any) => (
                            <Tag
                              size="md"
                              variant="subtle"
                              key={`${note._id}_${key}`}
                              colorScheme="blue"
                            >
                              <TagLeftIcon as={FiHash} />
                              <TagLabel>
                                {
                                  FlatCoachingTags.find(
                                    (tag: any) => tag.value === key
                                  )?.label
                                }
                              </TagLabel>
                            </Tag>
                          ))}
                        </HStack>
                      </Flex>
                      <Flex
                        my={2}
                        gridGap={3}
                        flexWrap="wrap"
                        direction="row"
                        justifyContent="flex-start"
                      >
                        <Text>
                          {' '}
                          <b>{t('used_techniques')}:</b>
                        </Text>
                        <HStack gridGap={2} flexWrap="wrap" spacing={3}>
                          {note.content.techniques.map((key: any) => (
                            <Tag
                              size="md"
                              variant="subtle"
                              key={`${note._id}_${key}`}
                              colorScheme="green"
                            >
                              <TagLeftIcon as={FiHash} />
                              <TagLabel>
                                {
                                  UsedTechniques.find(
                                    (tag: any) => tag.value === key
                                  )?.label
                                }
                              </TagLabel>
                            </Tag>
                          ))}
                        </HStack>
                      </Flex>
                      <Text>
                        {' '}
                        <b>{t('plan_action_items')} :</b>{' '}
                        {parseText(note.content.action_items)}{' '}
                      </Text>
                      <Text>
                        {' '}
                        <b>{t('flags_and_observation')} :</b>{' '}
                        {parseText(note.content.flags)}{' '}
                      </Text>
                      <Text>
                        {' '}
                        <b>{t('follow_ups')} :</b>{' '}
                        {parseText(note.content.follow_ups)}{' '}
                      </Text>
                    </>
                  )}
                  <Text>{parseText(note.body ? note.body.text : '')}</Text>
                </GridItem>
                <GridItem colStart={8} colEnd={11} my={2} alignSelf={'center'}>
                  {!note.body && (
                    <IconButton
                      onClick={onEdit}
                      data-item={JSON.stringify(note.body || note.content)}
                      data-body={note.body && note.body.text}
                      data-id={note._id}
                      aria-label="Edit"
                      bg={'white'}
                      size="md"
                      icon={<FiEdit2 size="18px" />}
                    />
                  )}
                  <IconButton
                    onClick={onDeleteClick}
                    data-id={note._id}
                    aria-label="Delete"
                    bg={'white'}
                    size="md"
                    icon={<FiTrash2 size="18px" />}
                  />
                </GridItem>
              </Grid>
            ))}
          </VStack>
        ) : (
          <Box textAlign="center" py={10} px={6}>
            <Text fontSize="18px" mt={3} mb={2}>
              No notes yet.
            </Text>
          </Box>
        )}
      </Box>
      <Modal
        initialFocusRef={initialRef}
        isOpen={isOpen}
        onClose={onClose}
        size="lg"
      >
        <form onSubmit={user ? onSubmit : form.handleSubmit(onCoachSubmit)}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>
              {updating ? t('update_note') : t('create_new_note')}
            </ModalHeader>
            <ModalCloseButton />
            <ModalBody pb={6}>
              {user ? (
                <FormControl isRequired mb={3}>
                  <FormLabel>{t('note')}</FormLabel>
                  <Textarea
                    isRequired
                    ref={initialRef}
                    value={note}
                    placeholder={`${t('notes')}...`}
                    onChange={onNoteChange}
                    size="lg"
                    height={'full'}
                    rows={8}
                  />
                </FormControl>
              ) : (
                <>
                  <FormControl
                    isInvalid={form.formState.errors.session_length}
                    mb={3}
                  >
                    <FormLabel>{t('session_length_in_minutes')}</FormLabel>
                    <Input
                      type="number"
                      {...form.register('session_length', { required: true })}
                    />
                    <FormErrorMessage>
                      {' '}
                      {t('session_length_required')}{' '}
                    </FormErrorMessage>
                  </FormControl>
                  <Controller
                    control={form.control}
                    name="topics"
                    rules={{ required: 'Topics is required field' }}
                    render={({ field, fieldState }) => (
                      <FormControl mb={3} isInvalid={!!fieldState.error}>
                        <FormLabel>{t('discussed_topics')}</FormLabel>
                        <Select
                          isMulti
                          {...field}
                          options={GroupedCoachingTags}
                          closeMenuOnSelect={false}
                          onChange={(newvalue) => {
                            form.setValue('topics', newvalue)
                          }}
                        />
                        <FormErrorMessage>
                          {t('discussed_topics_required')}
                        </FormErrorMessage>
                      </FormControl>
                    )}
                  />
                  <Controller
                    control={form.control}
                    name="techniques"
                    rules={{ required: 'Topics is required field' }}
                    render={({ field, fieldState }) => (
                      <FormControl mb={3} isInvalid={!!fieldState.error}>
                        <FormLabel>{t('used_techniques')}</FormLabel>
                        <Select
                          isMulti
                          {...field}
                          options={UsedTechniques}
                          closeMenuOnSelect={false}
                          onChange={(newvalue) => {
                            form.setValue('techniques', newvalue)
                          }}
                        />
                        <FormErrorMessage>
                          {t('used_techniques_required')}
                        </FormErrorMessage>
                      </FormControl>
                    )}
                  />
                  <FormControl mb={3}>
                    <FormLabel>{t('plan_action_items')}</FormLabel>
                    <Textarea {...form.register('action_items')} />
                  </FormControl>
                  <FormControl mb={3}>
                    <FormLabel>{t('flags_and_observation')}</FormLabel>
                    <Textarea {...form.register('flags')} />
                  </FormControl>
                  <FormControl mb={3}>
                    <FormLabel>{t('follow_ups')}</FormLabel>
                    <Textarea {...form.register('follow_ups')} />
                  </FormControl>
                </>
              )}
            </ModalBody>

            <ModalFooter>
              <Button onClick={onClose} mr={3}>
                {t('cancel')}
              </Button>
              <Button colorScheme="green" type="submit">
                {updating ? t('update_note') : t('save')}
              </Button>
            </ModalFooter>
          </ModalContent>
        </form>
      </Modal>
      <AlertDialog
        isOpen={isAlertOpen}
        onClose={onAlertClose}
        leastDestructiveRef={cancelRef}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              {t('are_you_sure')}
            </AlertDialogHeader>

            <AlertDialogBody>{t('are_you_sure_warning')}</AlertDialogBody>

            <AlertDialogFooter>
              <Button onClick={onAlertClose}>{t('cancel')}</Button>
              <Button colorScheme="red" onClick={onDelete} ml={3}>
                {t('continue')}
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </Flex>
  )
}

export default Notes
