import { Box, Divider, Flex, Heading, Stack, Text } from '@chakra-ui/react'
import moment from 'moment'

import { useGetCoachFeedbackQuery } from '../../app/services/api'
import { useLocale } from '../../hooks/useLocale'

type Props = any

const startOfMonth = moment().startOf('month').format('YYYY-MM-DD')

export const Feedback: React.FC<Props> = ({ coach }) => {
  const { t } = useLocale()
  const { data, isLoading, error } = useGetCoachFeedbackQuery('coach/feedback')

  if (isLoading) {
    return <Box>{t('loading')}</Box>
  }

  if (error) {
    return <Box>{t('an_error_occurred')}</Box>
  }

  const otherAnswers =
    data?.otherAnswers.filter((i: string) => i.length > 0) ?? []

  const improvements =
    data?.improvements?.filter((i: string) => i.length > 0) ?? []

  moment.tz.setDefault(coach.timezone)

  return (
    <Flex minH="85vh" direction="column" justify="flex-start" gap="4">
      <BasicCard>
        <Heading size="lg" mb="3">
          {t('stats')}
        </Heading>

        <Text size="lg">
          {t('average_rating')}:{' '}
          <strong>
            {data?.avgRating} ({t('out_of_x', { max: 5 })})
          </strong>
        </Text>
        <Text size="lg">
          {t('feedback_submitted')}: <strong>{data?.ratingCount}</strong>
        </Text>
        <Text size="lg">
          {t('last_updated')}:{' '}
          <strong>{moment(startOfMonth).format('MMM D, YYYY')}</strong>
        </Text>
      </BasicCard>

      <BasicCard>
        <Heading size="lg" mb="3">
          {t('categories')}
        </Heading>

        <Stack spacing={5}>
          {Object.keys(data?.answers ?? {}).map((key) => (
            <Box key={key}>
              <Text pb={1}>{t(key.split(':')?.[1])}</Text>
              <HorizontalBar value={data.answers[key]} />
            </Box>
          ))}
          {Object.keys(data?.answers ?? {}).length === 0 && (
            <Text size="lg">{t('no_feedback_yet')}</Text>
          )}
        </Stack>
      </BasicCard>

      <BasicCard>
        <Heading size="lg" mb="3">
          {t('open_feedback')}
        </Heading>
        <Stack mt="2" direction="row" spacing={5}>
          {otherAnswers.map((otherAnswer: any) => (
            <Bubble key={otherAnswer}>
              <Text>{otherAnswer}</Text>
            </Bubble>
          ))}
          {otherAnswers.length === 0 && <Text size="lg">{t('nothing_here_yet')}</Text>}
        </Stack>
      </BasicCard>

      <BasicCard>
        <Heading size="lg" mb="3">
          Improvement suggestions
        </Heading>
        <Stack mt="2" direction="row" spacing={5}>
          {improvements.map((improvement: any) => (
            <Bubble key={improvement}>
              <Text>{improvement}</Text>
            </Bubble>
          ))}
          {improvements.length === 0 && <Text size="lg">{t('nothing_here_yet')}</Text>}
        </Stack>
      </BasicCard>
    </Flex>
  )
}

const BasicCard: React.FC = ({ children }) => (
  <Box p="4" shadow="md" borderWidth="1px" rounded="lg" bg="white">
    {children}
  </Box>
)

const Bubble: React.FC = ({ children }) => (
  <Box
    bg="primary.100"
    p="2"
    borderRadius="lg"
    border="1px"
    borderColor="primary.200"
    maxW={250}
    style={{
      justifyContent: 'center',
      alignItems: 'center',
      alignContent: 'center',
      textAlign: 'center',
    }}
  >
    {children}
  </Box>
)

const HorizontalBar: React.FC<{ value: number }> = ({ value }) => {
  const width = `${(value * 100) / 100}%`

  return (
    <Flex alignItems="center">
      <Divider
        borderColor="primary.500"
        borderRadius="3px"
        borderWidth="12px"
        width={width}
      />
      <Text ml={3} fontWeight="bold">
        {value}
      </Text>
    </Flex>
  )
}
