import {
  Box,
  Button,
  Center,
  Flex,
  FlexProps,
  Heading,
  Link,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  SimpleGrid,
  Stack,
  Text,
  useColorModeValue,
  useDisclosure,
  useToast,
} from '@chakra-ui/react'
import { useCallback, useEffect, useState } from 'react'
import { AiOutlineClockCircle } from 'react-icons/ai'
import { BiPhoneCall } from 'react-icons/bi'
import { MdChat, MdDateRange } from 'react-icons/md'
import { useHistory } from 'react-router-dom'

import { APP_NAME } from './app/constants'
import { useGetCoachBioMutation, useGetEventMutation } from './app/services/api'
import CoachInfo from './features/coaches/CoachInfo'
import CoachAvatar from './features/profile/CoachAvatar'
import Book from './features/scheduler/Book'
import CallLink from './features/scheduler/CallLink'
import GoogleMeetLink from './features/scheduler/GoogleMeetLink'
import MicrosoftMeetLink from './features/scheduler/MicrosoftMeetLink'
import { useLocale } from './hooks/useLocale'
import { parseEventDate, parseEventTime } from './utils/date'
import { useMixpanel } from './utils/MixpanelContext'

interface Props extends FlexProps {
  user: any
  usersCoach: any
}

export const CardBooking = ({ event, user }: any) => {
  return (
    <Box textAlign={'center'} color={useColorModeValue('gray.700', 'gray.400')}>
      <Stack direction={'row'} justify={'center'} spacing={0}>
        <Stack spacing={0} align={'center'}>
          <Flex alignItems="center" mb={3}>
            <Text fontSize="23px">
              <MdDateRange />
            </Text>
            <Text fontSize="sm" pl={2} mr={5}>
              {parseEventDate(user, event.start, event.end)}
            </Text>
            <Text fontSize="23px">
              <AiOutlineClockCircle />
            </Text>
            <Text fontSize="sm" pl={2}>
              {parseEventTime(user, event.start, event.end)}
            </Text>
          </Flex>
          <CallLink event={event} />
        </Stack>
      </Stack>
    </Box>
  )
}

export default function CardCoach({ user, usersCoach }: Props) {
  const { t } = useLocale()
  const history = useHistory()
  const [getEvent] = useGetEventMutation()
  const [getCoachBio, { data: coachBio }] = useGetCoachBioMutation()
  const [latestEvent, setLatestEvent] = useState<any>(null)
  const [coach, setCoach] = useState<any>(null)
  const toast = useToast()
  const mixpanel = useMixpanel()
  const {
    isOpen: isModalOpen,
    onOpen: onModalOpen,
    onClose: onModalClose,
  } = useDisclosure()
  const {
    isOpen: isCoachBioOpen,
    onOpen: onCoachBioOpen,
    onClose: onCoachBioClose,
  } = useDisclosure()

  const getLatestEvent = useCallback(async () => {
    const response: any = await getEvent({ date: new Date().toISOString() })
    if (response && response.data) {
      setLatestEvent(response.data)
    }
  }, [getEvent])

  useEffect(() => {
    const loadCoachBio = async () => {
      await getCoachBio()
    }
    if (user) {
      loadCoachBio()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    getLatestEvent()
    // Send home_screen_open event to mixpanel
    mixpanel.track('home_screen_open')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (usersCoach) setCoach(usersCoach)
  }, [usersCoach])

  const onClickChat = useCallback(() => {
    history.push(`/chat`)
  }, [history])
  const onClickBook = useCallback(() => {
    history.push(`/book`)
  }, [history])

  const onEventUpdate = useCallback(async () => {
    await onModalClose()
    getLatestEvent()
    toast({
      status: 'success',
      title: t('success'),
      description: t('the_session_is_successfully_updated'),
      isClosable: true,
    })
  }, [getLatestEvent, onModalClose, toast, t])

  useEffect(() => {
    if (isCoachBioOpen) {
      mixpanel.track('coach_bio_opened')
    }
  }, [isCoachBioOpen, mixpanel])

  const onClickReschedule = useCallback(() => {
    onModalOpen()
  }, [onModalOpen])

  return (
    <Center py={6}>
      <Box
        maxW={'400px'}
        w={'full'}
        bg={'white'}
        boxShadow={'2xl'}
        rounded={'lg'}
        p={6}
        textAlign={'center'}
      >
        <Link onClick={onCoachBioOpen}>
          <SimpleGrid columns={{ base: 2, sm: 1, md: 2, lg: 2 }} spacing={3}>
            <Box
              textAlign={{
                base: 'right',
                sm: 'center',
                md: 'right',
                lg: 'right',
              }}
              verticalAlign="center"
            >
              {coach?.profile?.picture && (
                <CoachAvatar src={coach?.profile.picture} />
              )}
            </Box>
            <Box
              as={'header'}
              textAlign={{
                base: 'left',
                sm: 'center',
                md: 'left',
                lg: 'left',
              }}
              verticalAlign="center"
              mt={{ base: 5, sm: 0, md: 5 }}
            >
              <Heading fontSize={'2xl'} fontFamily={'body'}>
                {coach?.profile && coach?.profile.name}
              </Heading>
              <Text fontWeight={600} color={'gray.500'} mb={4}>
                {t('coach')} @ {APP_NAME}
              </Text>
            </Box>
          </SimpleGrid>
        </Link>

        {latestEvent && (
          <>
            <Text fontWeight={600} mb={2}>
              {t('next_call')}
            </Text>
            <CardBooking user={user} event={latestEvent} />
          </>
        )}

        <Stack mt={8} direction={'row'} spacing={8} px={8}>
          <Button
            leftIcon={<MdChat />}
            onClick={onClickChat}
            flex={1}
            fontWeight={400}
            fontSize={'md'}
            rounded={'full'}
            bg={'primary2.500'}
            color={'white'}
            _hover={{
              bg: 'white',
              color: 'primary2.500',
              borderColor: 'primary2.500',
              border: '1px',
            }}
            _active={{
              bg: 'primary2.400',
            }}
            _focus={{
              bg: 'primary2.400',
            }}
          >
            {t('chat')}
          </Button>
          <Button
            leftIcon={<BiPhoneCall />}
            onClick={latestEvent ? onClickReschedule : onClickBook}
            flex={1}
            fontSize={'md'}
            fontWeight={400}
            rounded={'full'}
            bg={'primary.500'}
            color={'white'}
            _hover={{
              bg: 'white',
              color: 'primary.500',
              borderColor: 'primary.500',
              border: '1px',
            }}
            _active={{
              bg: 'primary.400',
            }}
            _focus={{
              bg: 'primary.400',
            }}
          >
            {latestEvent ? t('reschedule') : t('make_appointment')}
          </Button>
        </Stack>
      </Box>
      <Modal isOpen={isModalOpen} onClose={onModalClose} size="lg">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{t('reschedule')}</ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>
            <Book
              user={user}
              coach={coach}
              event={latestEvent}
              onEventUpdate={onEventUpdate}
            />
          </ModalBody>
        </ModalContent>
      </Modal>
      <CoachInfo
        isOpen={isCoachBioOpen}
        onClose={onCoachBioClose}
        coach={coachBio}
      />
    </Center>
  )
}
