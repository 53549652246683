import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
  useToast,
} from '@chakra-ui/react'
import { FC, useCallback, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import TimezoneSelect from 'react-timezone-select'
import moment from 'moment'

import { useProfileMutation, usePutCoachProfileMutation } from '../../app/services/api'
import { useAuth } from '../../hooks/useAuth'
import { useAuth as useCoachAuth } from '../../hooks/useAuthCoach'
import { useLocale } from '../../hooks/useLocale'
import { Coach, User } from '../../types/api'
import { updateCoach, updateUser } from '../auth/authSlice'

const currentTimezone = moment.tz.guess()

const UserTimezoneDialog: FC = () => {
  const { isOpen, onClose, onOpen } = useDisclosure()
  const { user } = useAuth()
  const { coach } = useCoachAuth()
  const [updateUserProfile, { isLoading: isUserProfileUpdateLoading  }] = useProfileMutation()
  const [updateCoachProfile, { isLoading: isCoachProfileUpdateLoading }] = usePutCoachProfileMutation()
  useEffect(() => {
    coach && coach.timezone !== currentTimezone && onOpen()
    user && user.timezone !== currentTimezone && onOpen()

    console.log('timezone.useEffect.... user.timezone:', user?.timezone, ', TZ:', currentTimezone);
    console.log('timezone.useEffect.... coach.timezone:', coach?.timezone, ', TZ:', currentTimezone);
    
  }, [user, coach, onOpen])
  const [data, setData] = useState({ timezone: currentTimezone })
  const toast = useToast()
  const { t } = useLocale()
  const dispatch = useDispatch()

  const handleSave = useCallback(async () => {
    try {
      console.log('timezone.save.... user:', user, ', coach:', coach);
      let updatedUser: any = null;
      if (user) {
        updatedUser = await updateUserProfile(data as any).unwrap()
      } else {
        updatedUser = await updateCoachProfile(data as any).unwrap()
      }
      toast({
        status: 'success',
        title: t('success'),
        description: t('your_profile_is_updated'),
        isClosable: true,
      })
      if (user) {
        dispatch(updateUser(updatedUser as User))
      } else {
        dispatch(updateCoach(updatedUser as Coach))
      }
      onClose()
    } catch (error: any) {
      toast({
        status: 'error',
        title: t('error'),
        description: t('there_was_an_error'),
        isClosable: true,
      })
    }
  }, [data, dispatch, t, toast, updateUserProfile, updateCoachProfile, onClose, user, coach])

  return (
    <Modal isCentered isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{t('timezone_detected')}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <p>
            {t('timezone_detected_description', { timezone: currentTimezone })}
          </p>
          <br />
          <TimezoneSelect
            isDisabled={isUserProfileUpdateLoading || isCoachProfileUpdateLoading}
            value={data.timezone}
            onChange={({ value }) => setData({ ...data, timezone: value })}
          />
        </ModalBody>
        <ModalFooter>
          <Button
            onClick={handleSave}
            isLoading={isUserProfileUpdateLoading || isCoachProfileUpdateLoading}
            isFullWidth
            colorScheme="blue"
          >
            {t('save')}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export default UserTimezoneDialog
