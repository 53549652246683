import { createSlice } from '@reduxjs/toolkit'

interface onboardingState {
  questions: any
  email: string
  name: string
  language: string
  companyId: string
  onboardingData: any
  coachEmail: string
}

const initialState: onboardingState = {
  questions: {},
  email: '',
  name: '',
  language: '',
  companyId: '',
  onboardingData: {},
  coachEmail: '',
}

const slice = createSlice({
  name: 'onboarding',
  initialState: initialState,
  reducers: {
    itemSelect: (state, { payload }) => {
      state.questions[payload.questionId] = payload.answers
      console.log('itemSelect(after) state:', state)
    },
    setPreOnboardingData: (state, { payload }) => {
      state.name = payload.name
      state.email = payload.email
      state.companyId = payload.companyId
      state.language = payload.language
      console.log('setPreOnboardingData(after) state:', state)
    },
    setOnboardingData: (state, { payload }) => {
      state.onboardingData = payload
      console.log('setOnboardingQuestions() state:', state)
    },
    clearOnboardingData: (state, { payload }) => {
      Object.assign(state, initialState)
    },
    setCoachEmail: (state, { payload }) => {
      state.coachEmail = payload
      console.log('setCoachEmail payload:', payload)
    },
  },
})

export default slice.reducer

export const {
  itemSelect,
  setPreOnboardingData,
  setOnboardingData,
  clearOnboardingData,
  setCoachEmail,
} = slice.actions
