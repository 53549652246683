import { Button, Flex, Image, Link } from '@chakra-ui/react'
import { useMixpanel } from '../../utils/MixpanelContext'

const MicrosoftMeetLink = ({
  event,
  buttonSize = 'sm',
  label = 'Join with Microsoft Teams',
  ...props
}: any) => {
  const mixpanel = useMixpanel()

  const handleClick = () => {
    mixpanel.track('google_meet_link_open')
  }

  if (!event.teamsMeetingLink) return null

  return (
    <Flex>
      <Image
        src="https://cdn.cdnlogo.com/logos/m/47/microsoft-teams.png"
        alt="Microsoft Teams"
        width="20px"
        height="20px"
        mt="6px"
        mr={2}
      />
      <Link
        isExternal={true}
        href={event.teamsMeetingLink}
        target="_blank"
        _hover={{
          textDecoration: 'none',
        }}
        {...props}
      >
        <Button
          bgColor="#1a73e8"
          color="#fff"
          variant="solid"
          size={buttonSize}
          onClick={handleClick}
          p={4}
          _hover={{
            textDecoration: 'none',
            bg: '#4285f4',
          }}
        >
          {label}
        </Button>
      </Link>
    </Flex>
  )
}

export default MicrosoftMeetLink
