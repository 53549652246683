import {
    Box,
    Button,
    Center,
    Flex,
    Heading,
    Spinner, Text, Textarea, useToast
} from '@chakra-ui/react'
import { useCallback, useState } from 'react'
import { useParams } from 'react-router-dom'
import { usePostSessionUserFeedbackMutation } from '../../app/services/api'
import { useAuth } from '../../hooks/useAuth'
import { useLocale } from '../../hooks/useLocale'
import { Logo } from '../../utils/Logo'
import Rating from '../customer/Rating'

const REDIRECT_SECONDS = 3
const LANDING_PAGE = 'https://hupo.co/'
const HOME_PAGE = `${window.location.protocol}//${window.location.host}`

const options = {
    "app": [
        "call_quality",
        "chat",
        "coach_availability",
    ],
    "coaching": [
        "chemistry_with_coach",
        "coaching_style_structure",
        "helpful_takeaways",
    ],
    "other": [
        "tell_us_more",
    ]
}

const SelectOption = ({ title, value, selected, onItemSelected }: any) => {
    const isItemSelected = selected.has(value)
    return (
        <Box
            key={value}
            boxShadow="0 0px 5px rgba(0,0,0,0.15)"
            mb={3}
            borderRadius={20}
            padding={3}
            width={360}
            bg={isItemSelected ? 'var(--chakra-colors-primary-400)' : '#fff'}
            cursor="pointer"
            onClick={() => onItemSelected(value)}
        >
            <Flex direction={'column'} alignItems="center">
                <Text
                    color={isItemSelected ? '#fff' : '#000'}
                    textAlign="center"
                    fontWeight="400"
                    fontSize={'l'}
                >
                    {title}
                </Text>
            </Flex>
        </Box>
    )
}

const PostSessionUserFeedback = () => {
    const { t } = useLocale()
    const { user } = useAuth()
    const { eventId, coachName, rating: ratingParam } = useParams() as any
    const ratingValue = parseInt(ratingParam);
    const [rating, setRating] = useState(ratingValue)

    const getFollowUpQuestionTitle = useCallback((rating: number) => {
        if (rating === 1 || rating === 2) {
            return t('review.followup_title_rating_low')
        } else if (rating === 3) {
            return t('review.followup_title_rating_mid')
        } else if (rating === 4 || rating === 5) {
            return t('review.followup_title_rating_high')
        }
        return '';
    }, [t]);

    const [followUpQuestionTitle, setFollowUpQuestionTitle] = useState(getFollowUpQuestionTitle(ratingValue))

    const [answers, setAnswers] = useState(new Set<string>())
    const [otherAnswer, setOtherAnswer] = useState('');
    const [improvementSuggestion, setImprovementSuggestion] = useState('');
    
    const [isRedirecting, setIsRedirecting] = useState(false)
    const toast = useToast()

    const onItemSelected = (item: any) => {
        const options = new Set(answers)
        if (options.has(item)) {
            options.delete(item)
        } else {
            options.add(item)
        }
        setAnswers(options)
    }

    const [postSessionUserFeedback, { isLoading }] = usePostSessionUserFeedbackMutation()

    const redirectTitle = user ? t('redirect_to_home') : t('redirect_to_landing')
    const redirectPage = user ? HOME_PAGE : LANDING_PAGE

    const onSubmit = useCallback(
        async (event) => {
            if (answers.size === 0 && !otherAnswer) {
                toast({
                    status: 'error',
                    title: t('error'),
                    description: t('select_at_least_one'),
                    isClosable: true,
                })
                return;
            }
            event.preventDefault()
            try {
                await postSessionUserFeedback({ eventId, rating, answers: Array.from(answers), other: otherAnswer, improvement: improvementSuggestion }).unwrap()
                toast({
                    title: t('review.success'),
                    description: t('review.success_description'),
                    status: 'success',
                })
            } catch (err) {
                console.log(err)
                toast({
                    status: 'error',
                    title: t('error'),
                    description: t('there_was_an_error'),
                    isClosable: true,
                })
            }
            setIsRedirecting(true)
            setTimeout(() => {
                window.location.href = redirectPage
            }, REDIRECT_SECONDS * 1000)
        },
        [eventId, postSessionUserFeedback, improvementSuggestion, answers, rating, t, toast, otherAnswer, redirectPage]
    )

    if (isLoading || isRedirecting) {
        return (
            <Center h="200px" color="white">
                <Heading color="gray.500">{redirectTitle}</Heading>
                <Spinner
                    thickness="4px"
                    speed="0.65s"
                    emptyColor="gray.200"
                    color="blue.500"
                    size="xl"
                />
            </Center>
        )
    }

    const renderOption = (parent: string, child: string) => {
        if (child === 'tell_us_more') {
            return (
                <Box>
                    <Textarea
                        fontSize={['sm', 'md', 'lg']}
                        placeholder={t('tell_us_more')}
                        borderColor="gray.200"
                        borderWidth="1px"
                        borderRadius="md"
                        width="360px"
                        height="100px"
                        resize="none"
                        value={otherAnswer}
                        onChange={(event) => setOtherAnswer(event.target.value)}
                    />
                </Box>
            );
        }

        let option = child;
        if (option === 'helpful_takeaways' && rating < 4) {
            option = 'lack_of_helpful_takeaways';
        }

        const value = parent + ":" + option;
        return (
            <SelectOption
                title={t(`${option}`)}
                value={value}
                selected={answers}
                onItemSelected={onItemSelected}
            />
        )
    }

    return (
        <Box flexDirection={'column'} textAlign="center" px={6} pb={10}>
            <Flex
                px={{ base: 4, md: 4 }}
                height="20"
                borderBottomWidth="1px"
                alignItems="center"
                justifyContent='center'
            >
                <Logo md={5} />
            </Flex>

            <Heading
                display="inline-block"
                as="h5"
                size="lg"
                pt={10}
            >
                {t('review.title', { coachName })}
            </Heading>
            <Rating
                size={48}
                icon="star"
                scale={5}
                fillColor="gold"
                strokeColor="grey"
                rating={rating}
                setRating={(rating: number) => {
                    console.log('Rating, set rating:', rating);
                    setRating(rating);
                    setFollowUpQuestionTitle(getFollowUpQuestionTitle(rating));
                }}
            />
            {
                rating >= 1 && rating <= 5 ? (
                    <Flex width="100%" justifyContent="center" direction="column" mb={5} px={10}>
                        <Heading
                            display="inline-block"
                            as="h5"
                            size="lg"
                            pt={6}
                        >
                            {followUpQuestionTitle}
                        </Heading>
                        <Flex
                            justifyContent="center"
                            gridColumnGap={5}
                            alignItems="center"
                            direction="column"
                        >
                            {
                                Object.entries(options).map((option: any) => {
                                    const group = option[0];
                                    const items = option[1];
                                    return (
                                        <>
                                            <Box py={4}>
                                                <Heading
                                                    display="inline-block"
                                                    as="h2"
                                                    size="md"
                                                    pb={4}
                                                >
                                                    {t(`${group}`) + ':'}
                                                </Heading>
                                                {
                                                    items.map((option: any) => renderOption(group, option))
                                                }
                                            </Box>
                                        </>
                                    )
                                })
                            }
                        </Flex>
                    </Flex>
                ) : null
            }
            {
                rating >= 4 && rating <= 5 ? (
                    <Flex width="100%" justifyContent="center" direction="column" mb={5}>
                        <Heading
                            display="inline-block"
                            as="h5"
                            size="lg"
                            pt={6}
                        >
                            {t('improvement_to_make_ami_better_for_you')}
                        </Heading>
                        <Text fontSize='xl' mt={2} mb={5}> {t('make_it_happen_for_you')}</Text>
                        <Box>
                            <Textarea
                                fontSize={['sm', 'md', 'lg']}
                                placeholder={t('improvement_suggestions')}
                                borderColor="gray.200"
                                borderWidth="1px"
                                borderRadius="md"
                                width="400px"
                                height="200px"
                                resize="none"
                                value={improvementSuggestion}
                                onChange={(event) => setImprovementSuggestion(event.target.value)}
                            />
                        </Box>
                    </Flex>
                ) : null
            }
            {
                rating >= 1 && rating <= 5 ? (
                    <Button
                        onClick={onSubmit}
                        colorScheme="primary"
                        style={{ borderRadius: 100 }}
                        px={20}
                        display="block"
                        margin={'0 auto'}
                        height="50px"
                        disabled={isLoading}
                        fontSize="18px"
                    >
                        {t('submit')}
                    </Button>
                ) : null
            }
        </Box>
    )
}

export default PostSessionUserFeedback
