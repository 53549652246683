import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { api } from '../../app/services/api'
import type { RootState } from '../../app/store'
import { Coach, User } from '../../types/api'

type AuthState = {
  user: User | null
  coach: Coach | null
  token: string | null
}

const initialState: AuthState = {
  user: null,
  coach: null,
  token: null,
}

const slice = createSlice({
  name: 'auth',
  initialState: initialState,
  reducers: {
    logout: (state) => {
      Object.assign(state, initialState)
    },
    updateUser: (state, action: PayloadAction<User>) => {
      state.user = action.payload
    },
    updateCoach: (state, action: PayloadAction<Coach>) => {
      state.coach = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      api.endpoints.login.matchFulfilled,
      (state, { payload }) => {
        state.token = payload.token
        state.user = payload.user
      }
    )
    builder.addMatcher(
      api.endpoints.signup.matchFulfilled,
      (state, { payload }) => {
        state.token = payload.token
        state.user = payload.user
      }
    )
    builder.addMatcher(
      api.endpoints.loginCoach.matchFulfilled,
      (state, { payload }) => {
        state.token = payload.token
        state.coach = payload.coach
      }
    )
    builder.addMatcher(
      api.endpoints.postOnboardingData.matchFulfilled,
      (state, { payload }) => {
        state.token = payload.token
        state.user = payload.user
      }
    )
  },
})

export default slice.reducer

// Custom action creator
export const logout = () => {
  return async (dispatch: any) => {
    localStorage.clear()
    sessionStorage.clear()

    function deleteAllCookies() {
      document.cookie.split(';').forEach((c) => {
        document.cookie = c
          .replace(/^ +/, '')
          .replace(/=.*/, '=;expires=' + new Date().toUTCString() + ';path=/')
      })
    }

    deleteAllCookies()

    // Unregister all service workers
    const registrations = await navigator.serviceWorker.getRegistrations()
    for (let registration of registrations) {
      registration.unregister()
    }

    // Dispatch our logout action
    dispatch(slice.actions.logout())
  }
}

export const { updateUser, updateCoach } = slice.actions

export const selectCurrentUser = (state: RootState) => state.auth.user
export const selectCurrentCoach = (state: RootState) => state.auth.coach
