import {
  Box,
  Button,
  Checkbox,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Input,
  InputGroup,
  InputRightElement,
  Link,
  Stack,
  useColorModeValue,
  useToast,
} from '@chakra-ui/react'
import { ChangeEvent, useCallback, useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'

import { useLoginCoachMutation } from '../../app/services/api'
import { useAuth } from '../../hooks/useAuthCoach'
import { useLocale } from '../../hooks/useLocale'
import { CoachLoginRequest } from '../../types/api'

function PasswordInput({
  name,
  onChange,
}: {
  name: string
  onChange: (event: ChangeEvent<HTMLInputElement>) => void
}) {
  const { t } = useLocale()
  const [show, setShow] = useState(false)
  const handleClick = () => setShow((prev) => !prev)

  return (
    <InputGroup size="md">
      <Input
        pr="4.5rem"
        type={show ? 'text' : 'password'}
        placeholder={t('enter_password')}
        name={name}
        onChange={onChange}
      />
      <InputRightElement width="4.5rem">
        <Button h="1.75rem" size="sm" onClick={handleClick}>
          {show ? 'Hide' : 'Show'}
        </Button>
      </InputRightElement>
    </InputGroup>
  )
}

export default function LoginCoach() {
  const { t } = useLocale()
  const { push } = useHistory()
  const toast = useToast()
  const { coach } = useAuth()
  const { state }: any = useLocation()

  const redirectTo = state?.from ? state.from : '/coach'

  const [login] = useLoginCoachMutation()

  const [formState, setFormState] = useState<CoachLoginRequest>({
    email: '',
    password: '',
  })
  const [isButtonDisabled, setIsButtonDisabled] = useState<boolean>(true)

  useEffect(() => {
    if (coach) {
      push(redirectTo)
    }
  }, [push, coach, redirectTo])

  useEffect(() => {
    setIsButtonDisabled(!(formState?.email.trim() && formState?.password))
  }, [formState])

  const handleChange = ({
    target: { name, value },
  }: ChangeEvent<HTMLInputElement>) =>
    setFormState((prev) => ({ ...prev, [name]: value }))

  const onSubmit = useCallback(
    async (event) => {
      event.preventDefault(event)
      try {
        const adminSecret = document.getElementById(
          'adminSecret'
        ) as HTMLInputElement
        const formStateWithSecret = {
          ...formState,
          adminSecret: adminSecret?.value,
        }
        await login(formStateWithSecret).unwrap()
        // Being that the result is handled in extraReducers in authSlice,
        // we know that we're authenticated after this, so the user
        // and token will be present in the store
        push(redirectTo)
      } catch (err) {
        toast({
          status: 'error',
          title: t('error'),
          description: t('there_was_an_error'),
          isClosable: true,
        })
      }
    },
    [formState, login, toast, push, redirectTo, t]
  )

  return (
    <Flex
      minH={'100vh'}
      align={'center'}
      justify={'center'}
      bg={useColorModeValue('gray.50', 'gray.800')}
    >
      <form onSubmit={onSubmit}>
        <Stack spacing={8} mx={'auto'} maxW={'lg'} py={12} px={6}>
          <Stack align={'center'}>
            <Heading fontSize={'4xl'}>{t('sign_in_to_your_account')}</Heading>
          </Stack>
          <Box
            rounded={'lg'}
            bg={useColorModeValue('white', 'gray.700')}
            boxShadow={'lg'}
            p={8}
          >
            <Stack spacing={4}>
              <FormControl id="email">
                <FormLabel>{t('email')}</FormLabel>
                <Input
                  onChange={handleChange}
                  name="email"
                  type="text"
                  placeholder={t('email')}
                />
              </FormControl>
              <FormControl id="password">
                <FormLabel>{t('password')}</FormLabel>
                <PasswordInput onChange={handleChange} name="password" />
              </FormControl>
              <FormControl id="adminSecret">
                <Input name="adminSecret" value="" type="hidden" />
              </FormControl>
              <Stack spacing={10}>
                <Stack
                  direction={{ base: 'column', sm: 'row' }}
                  align={'start'}
                  justify={'space-between'}
                >
                  <Checkbox>{t('remember_me')}</Checkbox>
                  <Link color={'blue.400'} href="/coach/password_forgot">
                    {t('forgot_password?')}
                  </Link>
                </Stack>
                <Button
                  disabled={isButtonDisabled}
                  colorScheme="primary"
                  type="submit"
                  onClick={onSubmit}
                >
                  {t('sign_in')}
                </Button>
              </Stack>
            </Stack>
          </Box>
        </Stack>
      </form>
    </Flex>
  )
}
