import { useTranslation } from 'react-i18next'
import i18next from 'i18next'
import moment from 'moment-timezone'

export const changeLocale = (locale = 'en-us') => {
  i18next.changeLanguage(locale)
  moment.locale(locale)
  import(`moment/locale/${locale}`)
    .then(() => {
      moment.locale(locale)
    })
    .catch(() => {
      moment.locale('en-us')
    })
}

export const useLocale = () => {
  const { i18n, t } = useTranslation('translation')

  return {
    i18n,
    t,
  }
}
