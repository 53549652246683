import {
  Avatar,
  Box,
  BoxProps,
  CloseButton,
  Drawer,
  DrawerContent,
  Flex,
  FlexProps,
  HStack,
  Icon,
  IconButton,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  Text,
  useColorModeValue,
  useDisclosure,
  VStack,
} from '@chakra-ui/react'
import { ReactNode, ReactText, useEffect } from 'react'
import { IconType } from 'react-icons'
import { AiOutlineSchedule, AiOutlineInbox } from 'react-icons/ai'
import { VscFeedback } from 'react-icons/vsc'
import { FiChevronDown, FiHome, FiMenu, FiSettings } from 'react-icons/fi'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router'
import { NavLink } from 'react-router-dom'

import './Home.css'
import { logout } from './features/auth/authSlice'
import { Logo } from './utils/Logo'
import { changeLocale } from './hooks/useLocale'
import { Coach } from './types/api'

interface LinkItemProps {
  name: string
  icon: IconType
  href: string
  isExact?: boolean
}
const LinkItems: Array<LinkItemProps> = [
  { name: 'Home', icon: FiHome, href: '/coach', isExact: true },
  { name: 'Chats', icon: AiOutlineInbox, href: '/coach/inbox' },
  { name: 'Schedule', icon: AiOutlineSchedule, href: '/coach/schedule' },
  { name: 'Feedback', icon: VscFeedback, href: '/coach/feedback' },
  { name: 'Settings', icon: FiSettings, href: '/coach/settings' },
]

export default function HomeCoach({
  coach,
  children,
}: {
  coach: Coach | null
  children: ReactNode
}) {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const history = useHistory()

  return (
    <Box minH="100vh" bg={useColorModeValue('gray.100', 'gray.900')}>
      <SidebarContent
        onClose={() => onClose}
        display={{ base: 'none', md: 'none', lg: 'block' }}
      />
      <Drawer
        autoFocus={false}
        isOpen={isOpen}
        placement="left"
        onClose={onClose}
        returnFocusOnClose={false}
        onOverlayClick={onClose}
        size="full"
      >
        <DrawerContent>
          <SidebarContent onClose={onClose} />
        </DrawerContent>
      </Drawer>
      {/* mobilenav */}
      <MobileNav onOpen={onOpen} coach={coach} history={history} />
      <Box ml={{ base: 0, md: 0, lg: 60 }} p="4">
        {children}
      </Box>
    </Box>
  )
}

interface SidebarProps extends BoxProps {
  onClose: () => void
}

const SidebarContent = ({ onClose, ...rest }: SidebarProps) => {
  return (
    <Box
      transition="3s ease"
      bg={useColorModeValue('white', 'gray.900')}
      borderRight="1px"
      borderRightColor={useColorModeValue('gray.200', 'gray.700')}
      w={{ base: 'full', md: 'full', lg: 60 }}
      pos="fixed"
      h="full"
      {...rest}
    >
      <Flex
        h="20"
        alignItems="center"
        justify={{ base: 'space-between', md: 'center' }}
        mx="8"
      >
        <Logo />
        <CloseButton display={{ base: 'flex', md: 'none' }} onClick={onClose} />
      </Flex>
      {LinkItems.map((link) => (
        <NavItem
          key={link.name}
          icon={link.icon}
          href={link.href}
          onClick={onClose}
          isExact={link.isExact || false}
        >
          {link.name}
        </NavItem>
      ))}
    </Box>
  )
}

interface NavItemProps extends FlexProps {
  icon: IconType
  href: string
  isExact: boolean
  children: ReactText
}
const NavItem = ({ icon, href, isExact, children, ...rest }: NavItemProps) => {
  return (
    <NavLink activeClassName="active" to={href} exact={isExact}>
      <Flex
        align="center"
        p="4"
        mx="4"
        borderRadius="lg"
        role="group"
        cursor="pointer"
        _hover={{
          bg: 'primary.400',
          color: 'white',
        }}
        {...rest}
      >
        {icon && (
          <Icon
            mr="4"
            fontSize="16"
            _groupHover={{
              color: 'white',
            }}
            as={icon}
          />
        )}
        {children}
      </Flex>
    </NavLink>
  )
}

interface MobileProps extends FlexProps {
  onOpen: () => void
  coach: Coach | null
  history: any
}
const MobileNav = ({ onOpen, coach, history, ...rest }: MobileProps) => {
  const dispatch = useDispatch()

  const onCoachLogout = () => {
    localStorage.clear()
    dispatch(logout())
    history.push('/coach-login')
  }

  useEffect(() => {
    // TODO: do not localize to en-US here
    changeLocale('en-us')
  }, [])

  return (
    <Flex
      ml={{ base: 0, md: 0, lg: 60 }}
      px={{ base: 4, md: 4, lg: 4 }}
      height="20"
      alignItems="center"
      bg={useColorModeValue('white', 'gray.900')}
      borderBottomWidth="1px"
      borderBottomColor={useColorModeValue('gray.200', 'gray.700')}
      justifyContent={{
        base: 'space-between',
        md: 'space-between',
        lg: 'flex-end',
      }}
      // onMouseMove={onNotsOpen}
      {...rest}
    >
      <IconButton
        display={{ base: 'flex', md: 'flex', lg: 'none' }}
        onClick={onOpen}
        variant="outline"
        aria-label="open menu"
        icon={<FiMenu />}
      />
      <Logo display={{ base: 'flex', md: 'none' }} />

      <HStack spacing={{ base: '0', md: '0', lg: '6' }}>
        <Flex alignItems={'center'}>
          <Menu>
            <MenuButton
              py={2}
              transition="all 0.3s"
              _focus={{ boxShadow: 'none' }}
            >
              <HStack>
                <Avatar size={'sm'} src={coach?.profile?.picture} />
                <VStack
                  display={{ base: 'none', md: 'none', lg: 'flex' }}
                  alignItems="flex-start"
                  spacing="1px"
                  ml="2"
                >
                  <Text fontSize="sm">{coach?.profile?.name}</Text>
                  <Text fontSize="xs" color="gray.600">
                    Coach
                  </Text>
                </VStack>
                <Box display={{ base: 'none', md: 'none', lg: 'flex' }}>
                  <FiChevronDown />
                </Box>
              </HStack>
            </MenuButton>
            <MenuList
              bg={useColorModeValue('white', 'gray.900')}
              borderColor={useColorModeValue('gray.200', 'gray.700')}
            >
              <MenuItem onClick={() => history.push('/coach/profile')}>
                Profile
              </MenuItem>
              <MenuDivider />
              <MenuItem onClick={onCoachLogout}>Sign out</MenuItem>
            </MenuList>
          </Menu>
        </Flex>
      </HStack>
    </Flex>
  )
}
