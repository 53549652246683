import {
  Box,
  Heading,
  Image,
  ListItem,
  OrderedList,
  Text,
} from '@chakra-ui/react'

export const FAQ: React.FC = () => {
  return (
    <Box width="90%" mx="auto" mb="10">
      <Heading color="#f5594f" my="5">
        FAQs
      </Heading>

      <Heading size="md" mb="5">
        Experiencing Coaching
      </Heading>

      <OrderedList className="faq-ul">
        <ListItem mb="5">
          <Heading size="sm" display="inline-block" pl="2">
            What is coaching?
          </Heading>
          <Text pl="6">
            Coaching is a collaborative relationship with a neutral and trusted
            confidante and advisor. Your coach will be your partner in managing
            and achieving both short and long term goals. In journeying to get
            you to where you want to be, your coach will be a listening ear,
            your pillar of emotional support, as well as a source of reflection.
            <br />
            <br />
            Coaching is present-focused, forward looking and action-oriented.
            Together with your coach, develop emotional groundedness, and
            nurture a happier and healthier version of yourself. Our users enjoy
            a positive, and highly personalized experience with Hupo, and say
            the benefits also cascade down to better mood and overall
            performance at work.
          </Text>
        </ListItem>
        <ListItem mb="5">
          <Heading size="sm" display="inline-block" pl="2">
            Who is coaching for?
          </Heading>
          <Text pl="6">
            Everyone and anyone! It’s an everyday resource that any individual
            can tap on, wherever you may be on your personal well being journey.
            In the same way you work with a personal gym trainer to improve
            physical strength and fitness, this is a proactive way for you to
            invest further in your own mental and emotional fitness, and
            agility.
          </Text>
        </ListItem>
        <ListItem mb="5">
          <Heading size="sm" display="inline-block" pl="2">
            What can I talk about with my coaches?
          </Heading>
          <Text pl="6">
            You should feel comfortable talking to your coach about anything.
            Typically, most people start by discussing goals, sources of
            motivation, challenges, or even use coaching sessions as a check-in
            on one’s overall mood. We have a great framework to help you get
            started, covering topics like: life skills, relationships, work,
            self and family. After a few sessions, work with your coach to
            identify specific key areas to explore further together. We have a
            proprietary curriculum here designed to help first-timers and also
            experienced users alike:
            <Image
              src="/images/ami-coaching-pathways.webp"
              alt="Curriculum"
              display="block"
              mx="auto"
              mt="5"
              width={{ base: '80%', md: '60%' }}
            />
          </Text>
        </ListItem>
        <ListItem mb="5">
          <Heading size="sm" display="inline-block" pl="2">
            If I use Hupo, will it leave a record on my medical history?
          </Heading>
          <Text pl="6">
            Absolutely not. Your use of Hupo’s coaching stays completely
            private.
          </Text>
        </ListItem>

        <ListItem mb="5" className="skip">
          <Heading size="md">Using the Hupo Care app</Heading>
        </ListItem>
        <ListItem mb="5">
          <Heading size="sm" display="inline-block" pl="2">
            How many sessions can I book?
          </Heading>
          <Text pl="6">
            You have unlimited access to Hupo and may book as many coaching
            sessions as desired. You are encouraged to discuss the optimum
            frequency with your coach, for a coaching experience that best suits
            your needs and lifestyle.
          </Text>
        </ListItem>
        <ListItem mb="5">
          <Heading size="sm" display="inline-block" pl="2">
            How do I communicate with my coach?
          </Heading>
          <Text pl="6">
            Text, audio call, or video call your coach directly through our
            mobile and web apps.
          </Text>
        </ListItem>
        <ListItem mb="5">
          <Heading size="sm" display="inline-block" pl="2">
            How do I book a session?
          </Heading>
          <Text pl="6">
            It’s super easy to use our “Book” function on the Hupo web or mobile
            app. You can also view and manage all appointments in one place on
            the app, and sessions will automatically be reflected on your Google
            calendar as a private event, along with a Google meet link.
          </Text>
        </ListItem>
        <ListItem mb="5">
          <Heading size="sm" display="inline-block" pl="2">
            What if I encounter technical issues?
          </Heading>
          <Text pl="6">
            Please reach out to your coach if you are already in contact, and
            you can easily chat with him/her via the app. You can also chat to
            customer support within the app or contact us at{' '}
            <a href="mailto:team@hupo.co">team@hupo.co</a>.
          </Text>
        </ListItem>
        <ListItem mb="5">
          <Heading size="sm" display="inline-block" pl="2">
            How do I give feedback?
          </Heading>
          <Text pl="6">
            The app has a help desk feature to chat with us instantly on any
            issues. Team Hupo will reach out to you periodically to understand
            how you are enjoying the experience, and how we can make it even
            better. We’d love to hear from you at any time! Write to us at{' '}
            <a href="mailto:team@hupo.co">team@hupo.co</a> or you may contact
            your People team whom we work closely with.
          </Text>
        </ListItem>

        <ListItem mb="5" className="skip">
          <Heading size="md">Your Hupo coach</Heading>
        </ListItem>
        <ListItem mb="5">
          <Heading size="sm" display="inline-block" pl="2">
            Can I trust my coach, is he/she a professional?
          </Heading>
          <Text pl="6">
            Yes, Hupo coaches are hired in-house and have all been rigorously
            vetted. All coaches are trained and certified in disciplines like
            clinical psychology and behavioral health, and also are seasoned
            professionals with years of coaching and counseling experience. You
            can find your coach’s profile on the Hupo app.
          </Text>
        </ListItem>
        <ListItem mb="5">
          <Heading size="sm" display="inline-block" pl="2">
            Will information discussed with my coach be shared with my employer?
          </Heading>
          <Text pl="6">
            Everything discussed remains highly private and confidential between
            you and your coach. No individually identifiable information will be
            shared to your firm, not even how many times or how often you are
            using Hupo’s service.
          </Text>
        </ListItem>
        <ListItem mb="5">
          <Heading size="sm" display="inline-block" pl="2">
            Will my coach provide clinical diagnoses?
          </Heading>
          <Text pl="6">
            Hupo is a non-clinical service that is focused on everyday-style
            mental wellbeing coaching. We refrain from providing clinical
            diagnoses or engaging in clinical psychological intervention.
          </Text>
        </ListItem>
        <ListItem mb="5">
          <Heading size="sm" display="inline-block" pl="2">
            Can I change my coach, and how?
          </Heading>
          <Text pl="6">
            Yes, the Hupo app provides the direct option for you to switch to an
            alternative coach, or simply reach out to{' '}
            <a href="mailto:team@hupo.co">team@hupo.co</a> and we will
            personally help to manage this.
          </Text>
        </ListItem>
        <ListItem>
          <Heading
            size="sm"
            display="inline"
            style={{ textIndent: '200px hanging' }}
          >
            If I resign from my company and still want to receive coaching,
            could I book a session with Hupo coach and pay with my own pocket?
          </Heading>
          <Text pl="6">
            Hupo partners with employers to offer coaching as an employee
            wellbeing benefit, in our campaign and quest for holistic mental
            wellness for workers everywhere! Although we do not yet offer an
            individual subscription, do speak with us to discuss if a potential
            interim or permanent arrangement can be worked out whilst you are in
            between roles, or in your new gig. We’d love to spread the word for
            more companies to benefit from Hupo!
          </Text>
        </ListItem>
      </OrderedList>
    </Box>
  )
}
