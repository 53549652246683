import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Flex,
  Heading,
  List,
  ListIcon,
  ListItem,
  StackDivider,
  Text,
  UnorderedList,
  VStack,
} from '@chakra-ui/react'
import { useEffect } from 'react'
import { MdCheckCircle } from 'react-icons/md'

import { useGetSurveyAnswersMutation } from '../../app/services/api'
import { useLocale } from '../../hooks/useLocale'

const OnboardingInfo = ({ coach, userId }: any) => {
  const { t } = useLocale()
  const [getSurveyAnswers, { data: answers }] = useGetSurveyAnswersMutation()

  useEffect(() => {
    const retrieveAnswers = async (userId: string) => {
      await getSurveyAnswers(userId)
    }
    retrieveAnswers(userId)
  }, [userId, getSurveyAnswers])

  console.log('answers:', answers)
  return (
    <Accordion allowMultiple>
      <AccordionItem mb={2}>
        <Flex
          w="full"
          flexDirection="column"
          bg={'white'}
          borderRadius={10}
          mb={2}
        >
          <AccordionButton>
            <Flex width={'full'} px={6} py={3} justifyContent={'space-between'}>
              <Box>
                <Heading size={'md'}>{t('onboarding_answers')}</Heading>
              </Box>
            </Flex>
            <AccordionIcon />
          </AccordionButton>
        </Flex>
        <Flex bg={'white'} borderRadius={10}>
          <AccordionPanel pb={4}>
            {answers && answers.length > 0 ? (
              <VStack
                divider={<StackDivider borderColor="gray.200" />}
                spacing={4}
                align="stretch"
                px={6}
                py={5}
              >
                {/* Loop through answers array and print question and answers */}
                <List spacing={2}>
                  {answers.map((answer: any) => (
                    <ListItem>
                      <ListIcon as={MdCheckCircle} color="green.500" />
                      <Text as={'span'} fontWeight={'bold'}>
                        {answer.question.title}
                      </Text>{' '}
                      <UnorderedList>
                        <ListItem>
                          {answer.answers.map((answer_: any) => (
                            <ListItem>{answer_.label}</ListItem>
                          ))}
                        </ListItem>
                      </UnorderedList>
                    </ListItem>
                  ))}
                </List>
              </VStack>
            ) : (
              <Box textAlign="center" py={10} px={6}>
                <Text fontSize="18px" mt={3} mb={2}>
                  No onboarding info.
                </Text>
              </Box>
            )}
          </AccordionPanel>
        </Flex>
      </AccordionItem>
    </Accordion>
  )
}

export default OnboardingInfo
