import {
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
  Flex,
  Slider,
  SliderTrack,
  SliderFilledTrack,
  SliderThumb,
  FormControl,
  FormLabel,
  Box,
} from '@chakra-ui/react'
import { useState } from 'react'

const SliderInput = (props: any) => {
  // Get params from props
  const { name, label, min, max, step, defaultValue, onChange } = props
  const [value, setValue] = useState(defaultValue ?? 0)

  const handleChange = (value: string | number) => {
    setValue(Number(value))
    onChange(Number(value))
  }

  return (
    <FormControl>
      <FormLabel htmlFor={name} textAlign="center">
        {label}
      </FormLabel>
      <Flex>
        <NumberInput
          maxW="100px"
          mr="2rem"
          value={value}
          onChange={handleChange}
        >
          <NumberInputField />
          <NumberInputStepper>
            <NumberIncrementStepper />
            <NumberDecrementStepper />
          </NumberInputStepper>
        </NumberInput>
        <Slider
          flex="1"
          focusThumbOnChange={false}
          value={value}
          onChange={handleChange}
          min={min}
          max={max}
          step={step}
          defaultValue={defaultValue}
        >
          <SliderTrack>
            <Box position="relative" right={10} />
            <SliderFilledTrack />
          </SliderTrack>
          <SliderThumb fontSize="sm" boxSize="32px" children={value} />
        </Slider>
      </Flex>
    </FormControl>
  )
}

export default SliderInput
