import {
  Box,
  Center,
  FormControl,
  FormLabel,
  Heading,
  IconButton,
  Link,
  Spinner,
  Switch,
  Text,
  useColorModeValue,
  useDisclosure,
  useRadio,
  useRadioGroup,
  VStack
} from '@chakra-ui/react'
import { addMonths } from 'date-fns'
import moment from 'moment-timezone'
import 'moment/locale/ko'
import { useCallback, useEffect, useRef, useState } from 'react'
import { MAXIMUM_BOOKING_DAYS } from '../../app/constants'
import CoachInfo from '../coaches/CoachInfo'

import { useFlags } from 'flagsmith/react'
import { BiInfoCircle } from 'react-icons/bi'
import {
  useGetAvailabilityMutation, useGetExcludedDatesMutation,
  useLazyGetCoachBioByIdQuery
} from '../../app/services/api'
import { useLocale } from '../../hooks/useLocale'
import { useMixpanel } from '../../utils/MixpanelContext'
import CoachAvatar from '../profile/CoachAvatar'
import { AskPopUp } from './AskPopUp'
import { InfoPopover } from './components/InfoPopover'
import DatePicker from './DatePicker'

//moment.locale('ko')

export const TimeItem = (props: any) => {
  const { onClick, ...rest } = props
  const { getInputProps, getCheckboxProps } = useRadio(rest)

  const input = getInputProps()
  const checkbox = getCheckboxProps()
  let centerProps = {
    p: '4',
    m: '1',
    borderRadius: 'lg',
    role: 'group',
    cursor: 'pointer',
    bg: 'gray.200',
    _hover: {
      bg: 'blue.500',
      color: 'white',
    },
  }

  if (props.noStyleCenter) {
    centerProps = {} as any
  }

  return (
    <Link as="label" onClick={onClick}>
      <Center {...centerProps} {...checkbox}>
        <input {...input} />
        <Text fontSize="sm">{props.children}</Text>
      </Center>
    </Link>
  )
}

const Datetime = ({
  user,
  coach,
  duration,
  date,
  event,
  setDate,
  setAvailableDatesCount,
  setSlotsCount,
  setTime,
  onNext,
  timezone,
  language,
  attempts,
  setAttempts,
  isAskPopUpEnabled,
  isSearchThroughAllCoachesEnabled,
  flowId,
  mergeAllCoachSlots,
  setMergeAllCoachSlots,
  setAnotherCoachSlot,
}: any) => {
  const { t } = useLocale()
  const [times, setTimes] = useState<any[]>()
  const [triedDates, setTriedDates] = useState<any[]>([])
  const [excludeDates, setExcludeDates] = useState<Date[]>([])
  const [shouldShowAskPopUp, setShouldShowAskPopUp] =
    useState(isAskPopUpEnabled)
  const { isOpen, onOpen, onClose } = useDisclosure()
  const {
    isOpen: isCoachBioOpen,
    onOpen: onCoachBioOpen,
    onClose: onCoachBioClose,
  } = useDisclosure()
  const [getAvailability, { isLoading }] = useGetAvailabilityMutation()
  const [getExcludedDates, { data: excludeDatesData }] =
    useGetExcludedDatesMutation()
  const [triggerGetCoachBio, { data: coachBio, isLoading: isCoachBioLoading }] = useLazyGetCoachBioByIdQuery()

  const timesEndRef = useRef<HTMLDivElement>(null)
  const mixpanel = useMixpanel()
  const { booking_flow: bookingFlowExperiment } = useFlags(['booking_flow'])

  useEffect(() => {
    if (shouldShowAskPopUp && attempts > 3) {
      onOpen()
    }
  }, [attempts, shouldShowAskPopUp, onOpen])

  // Get exlude dates from server as soon as it's mounted
  useEffect(() => {
    // If searchAll is true, don't call getExcludedDates
    if (mergeAllCoachSlots) {
      setExcludeDates([])
      return
    }
    const params = {
      userId: coach._id,
      duration: duration,
      timeZone: user?.timezone ?? timezone,
    }
    getExcludedDates(params)
  }, [mergeAllCoachSlots, coach, duration, user, timezone, getExcludedDates])

  // Set exlude dates based on excludeDatesData
  useEffect(() => {
    console.log('excludeDatesData', excludeDatesData)
    if (excludeDatesData) {
      const { data } = excludeDatesData
      // Convert excludeDatesData to Date type
      const dates = data.map((date: any) => new Date(date))
      setExcludeDates(dates)
      setAvailableDatesCount(dates ? MAXIMUM_BOOKING_DAYS - dates.length : 0)
    }
  }, [excludeDatesData, setAvailableDatesCount])

  const start = new Date()

  const scrollToBottom = useCallback(() => {
    if (timesEndRef?.current)
      timesEndRef.current.scrollIntoView({ behavior: 'smooth' })
  }, [timesEndRef])

  const onSelectedDay = useCallback(
    async (date: any) => {
      if (date) {
        const params = {
          userId: coach._id,
          duration: duration,
          date: moment(date).utc(true).toISOString(),
          timeZone: user?.timezone ?? timezone,
          bookingFlow: flowId,
          mergeSlots: mergeAllCoachSlots ? 1 : 0,
        }
        const { data }: any = await getAvailability(params)
        setDate(date)
        let allSlots: any[] = []
        const slots = data?.data || []
        const mergedSlots = data?.mergedSlots || []
        // For MixPanel
        setSlotsCount(slots ? slots.length : 0)
        // Slots
        slots.forEach((slot: string) => {
          allSlots.push({
            label: moment(slot)
              .tz(user?.timezone ?? timezone)
              .format('HH:mm'),
            value: slot,
            coach: null,
          })
        })
        // Merged slots
        mergedSlots.forEach((item: any) => {
          item.slots.forEach((slot: string) => {
            allSlots.push({
              label: moment(slot)
                .tz(user?.timezone ?? timezone)
                .format('HH:mm'),
              value: slot,
              coach: item.coach,
            })
          })
        })

        allSlots.sort((a, b) => a.value.localeCompare(b.value))
        setTimes(allSlots)
        const formattedDate = moment(date).format('YYYY-MM-DD')
        const formattedDateWithSlots = `${formattedDate} -> ${
          slots.length
        } (${allSlots.map((time) => time.label).join(', ')})`
        setTriedDates((prev) => [...prev, formattedDateWithSlots])
        scrollToBottom()

        setAttempts((prev: number) => prev + 1)
      }
    },
    [
      setDate,
      getAvailability,
      coach,
      duration,
      user,
      timezone,
      setSlotsCount,
      mergeAllCoachSlots,
      scrollToBottom,
      setAttempts,
      flowId,
    ]
  )
  const onTimeChange = useCallback(
    (time: string) => {
      setTime(time)
      mixpanel.track(
        event ? 'appointment_update_date_time' : 'appointment_create_date_time', {
          bookingExperimentEnabled: bookingFlowExperiment.enabled,
          bookingFlow: bookingFlowExperiment.value,
        }
      )
      onNext()
    },
    [onNext, setTime, mixpanel, bookingFlowExperiment, event]
  )

  const { getRootProps, getRadioProps } = useRadioGroup({
    name: 'time',
    onChange: onTimeChange,
  })
  const group = getRootProps()

  return (
    <VStack spacing={4} {...group}>
      <DatePicker
        language={language}
        selectedDate={date}
        // Exclude unavailable days
        excludeDates={excludeDates}
        onSelect={onSelectedDay}
        dateFormat={'P'}
        minDate={start}
        maxDate={addMonths(new Date(), 1)}
        inline={true}
      />
      {isSearchThroughAllCoachesEnabled && (
        <FormControl display="flex" alignItems="center" justifyContent="center">
          <FormLabel htmlFor="search-throgh-all" mb="0">
            {t('merge_slots_from_all_coaches')}
          </FormLabel>
          <Switch
            id="search-throgh-all"
            isChecked={mergeAllCoachSlots}
            onChange={(e) => setMergeAllCoachSlots(e.target.checked)}
          />
        </FormControl>
      )}
      <Box
        bg={useColorModeValue('white', 'gray.900')}
        borderRight="1px"
        borderRightColor={useColorModeValue('gray.200', 'gray.700')}
        h="full"
        // {...rest}
      >
        {date && !isLoading && times && (
          <>
            <Heading p="4" as="h5" size="sm" w="240px">
              {moment(date).format('dddd, D MMMM')}
            </Heading>
            {times && times.length > 0 ? (
              times.map(({ value, label, coach }) => {
                const radio = getRadioProps({ value })

                if (coach) {
                  return (
                    <Box
                      key={value + "_" + coach?._id}
                      bg="gray.200"
                      _hover={{ bg: 'blue.500' }}
                      m="1"
                      p="4"
                      borderRadius="lg"
                      pos="relative"
                      onClick={() => {
                        setAnotherCoachSlot(coach)
                        const loadCoachBio = async () => {
                          await triggerGetCoachBio(coach._id)
                        }
                        loadCoachBio()
                      }}
                    >
                      <Link onClick={onCoachBioOpen}>
                        <CoachAvatar
                          size="sm"
                          coach={coach}
                          styles={{
                            position: 'absolute',
                            top: '20%',
                            left: '10%',
                          }}
                        />
                      </Link>
                      <InfoPopover
                        title={t('extra_slot')}
                        trigger={
                          <IconButton
                            bg="transparent"
                            _hover={{ bg: 'transparent' }}
                            aria-label="Information"
                            icon={<BiInfoCircle />}
                            pos="absolute"
                            top="15%"
                            right="10%"
                          />
                        }
                      >
                        <Text>{t('another_coach_slot')}</Text>
                      </InfoPopover>
                      <Box flex="1">
                        <TimeItem {...radio} noStyleCenter>
                          {label}
                        </TimeItem>
                      </Box>
                    </Box>
                  )
                }

                return (
                  <TimeItem
                    {...radio}
                    key={value}
                    onClick={() => setAnotherCoachSlot(null)}
                  >
                    {label}
                  </TimeItem>
                )
              })
            ) : (
              <Text mb={2}>{t('no_slots_available')}</Text>
            )}
            <span ref={timesEndRef}></span>
          </>
        )}
        {date && isLoading && (
          <Box w="240px" py={3}>
            <Spinner size="xl" />
          </Box>
        )}
        <AskPopUp
          isOpen={isOpen}
          keepLookingAndClose={() => {
            setAttempts(0)
            setShouldShowAskPopUp(false)
            mixpanel.track('appointment_time_preference_keep_looking', {
              bookingExperimentEnabled: bookingFlowExperiment.enabled,
              bookingFlow: bookingFlowExperiment.value,
            });
            onClose()
          }}
          onClose={onClose}
          triedDates={triedDates}
        />
        {coachBio && !isCoachBioLoading && (
          <CoachInfo
            isOpen={isCoachBioOpen}
            onOpen={onCoachBioOpen}
            onClose={onCoachBioClose}
            coach={coachBio}
          />
        )}
      </Box>
    </VStack>
  )
}

export default Datetime
