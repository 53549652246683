import { Image, Link } from '@chakra-ui/react'

export const Logo = (props: any) => {
  return (
    <Link href="/">
      <Image
        src="https://ami-avatars.s3.amazonaws.com/646fa927d47205798c223b6d/hupo_logo_.png"
        alt="Hupo logo"
        width="80px"
        {...props}
      />
    </Link>
  )
}
