import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Box,
  Button,
  Flex,
  IconButton,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Stack,
  Text,
  Tooltip,
  useToast,
  VStack,
} from '@chakra-ui/react'
import moment from 'moment'
import {
  MutableRefObject,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react'
import { AiFillDelete } from 'react-icons/ai'
import { BiCheck, BiCheckDouble } from 'react-icons/bi'
import { BsThreeDotsVertical } from 'react-icons/bs'
import { useSelector } from 'react-redux'

import {
  useDeleteMessageMutation,
  usePostMessagesReadMutation,
} from '../../app/services/api'
import { RootState } from '../../app/store'
import { socket } from '../../hooks/socket'
import { useLocale } from '../../hooks/useLocale'
import { useMixpanel } from '../../utils/MixpanelContext'
import { parseText } from '../../utils/string'

type Props = {
  id?: string
  message: string
  dateSent?: string
  from?: string
  me?: string
  read?: string
  onMessageRead?: any
  type?: string
}

const ChatBubble = ({
  id,
  message,
  dateSent,
  from,
  me,
  read,
  onMessageRead,
  type,
}: Props) => {
  const isMe = from === me
  const alignment = isMe ? 'flex-end' : 'flex-start'
  const bottomRightRadius = isMe ? 0 : 32
  const bottomLeftRadius = isMe ? 32 : 0

  const [isOptionsOpen, setIsOptionsOpen] = useState(false)
  const openOptions = () => setIsOptionsOpen(!isOptionsOpen)
  const closeOptions = () => setIsOptionsOpen(false)
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [hover, setHover] = useState(false)
  const onClose = () => setIsOpen(false)
  const cancelRef = useRef() as MutableRefObject<HTMLInputElement>
  const [postMessagesRead] = usePostMessagesReadMutation()
  const [deleteMessage] = useDeleteMessageMutation()
  const mixpanel = useMixpanel()
  const toast = useToast()
  const { t } = useLocale()
  const socketId = useSelector((state: RootState) => state.socket.socketId)

  useEffect(() => {
    if (!id || read) return
    const markRead = async () => {
      await postMessagesRead(id)
      mixpanel.track('chat_message_received')
    }
    if (!isMe && !read) {
      console.log('Marking as read')
      markRead()
      onMessageRead && onMessageRead()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleClickDelete = useCallback(() => {
    setIsOpen(true)
  }, [setIsOpen])

  // Callback function to delete a message
  const onDelete = useCallback(
    async (ev: any) => {
      if (!id) return
      try {
        await deleteMessage(id).unwrap()
        toast({
          status: 'success',
          title: t('success'),
          description: t('message_is_successfully_deleted'),
          isClosable: true,
        })
        mixpanel.track('delete_message_success')
        socket.emit('room:refresh', { socketId, messageId: id })
        onClose()
      } catch (err: any) {
        console.log(err)
        toast({
          status: 'error',
          title: t('error'),
          description: t('there_was_an_error'),
          isClosable: true,
        })
        mixpanel.track('delete_message_fail', { message: err?.data?.message })
      }
    },
    [id, socketId, deleteMessage, toast, t, mixpanel]
  )

  const onMouseEnter = useCallback(() => {
    setHover(true)
  }, [setHover])

  const onMouseLeave = useCallback(() => {
    if (!isOptionsOpen && !isOpen) {
      setHover(false)
    }
  }, [isOpen, isOptionsOpen, setHover])

  if (!me) {
    return null
  }

  return (
    <VStack mt={6} alignItems={alignment} alignSelf={alignment}>
      <Flex onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
        {hover && isMe ? (
          <Popover
            placement="bottom-end"
            isOpen={isOptionsOpen}
            onClose={closeOptions}
            isLazy
          >
            <PopoverTrigger>
              <IconButton
                aria-label="More options"
                icon={<BsThreeDotsVertical />}
                variant="solid"
                w="fit-content"
                backgroundColor={'white'}
                mt={2}
                verticalAlign="center"
                onClick={openOptions}
              />
            </PopoverTrigger>
            <PopoverContent w="fit-content" _focus={{ boxShadow: 'none' }}>
              <PopoverArrow />
              <PopoverBody>
                <Stack>
                  <Button
                    w="194px"
                    variant="ghost"
                    rightIcon={<AiFillDelete />}
                    justifyContent="space-between"
                    fontWeight="normal"
                    colorScheme="red"
                    fontSize="sm"
                    onClick={handleClickDelete}
                  >
                    Remove
                  </Button>
                </Stack>
              </PopoverBody>
            </PopoverContent>
          </Popover>
        ) : (
          <></>
        )}
        <Box
          bg={isMe ? 'blue.50' : (type === 'admin-coach' ? 'gray.300' : 'gray.100')}
          px={6}
          py={4}
          maxW={80}
          borderTopLeftRadius={32}
          borderTopRightRadius={32}
          borderBottomLeftRadius={bottomLeftRadius}
          borderBottomRightRadius={bottomRightRadius}
        >
          {parseText(message)}
        </Box>
      </Flex>
      <Tooltip
        label={`Seen ${moment(read).format('MMMM D, h:mm a')}`}
        placement="top"
        aria-label="read"
        hidden={!read}
      >
        <Flex
          alignItems="center"
          justifyContent="center"
          // bg="gray.100"
          borderRadius="full"
          w="fit-content"
          h="fit-content"
          mt={2}
          ml={isMe ? 0 : 'auto'}
          mr={isMe ? 'auto' : 0}
        >
          {isMe && read && (
            <BiCheckDouble size="1.5em" style={{ marginRight: '0.5em' }} />
          )}
          {isMe && !read && (
            <BiCheck size="1.5em" style={{ marginRight: '0.5em' }} />
          )}
          <Text fontSize="xs" color="gray">
            {moment(dateSent).fromNow()}
          </Text>
        </Flex>
      </Tooltip>
      <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={onClose}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              {t('delete_message')}
            </AlertDialogHeader>

            <AlertDialogBody>{t('are_you_sure_warning')}</AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRef as any} onClick={onClose}>
                {t('cancel')}
              </Button>
              <Button colorScheme="red" onClick={onDelete} ml={3}>
                {t('continue')}
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </VStack>
  )
}

export default ChatBubble
