/* eslint-disable */
import { Link, useParams } from 'react-router-dom'
import {
  Avatar,
  Badge,
  Box,
  Center,
  Flex,
  Grid,
  GridItem,
  Heading,
  Text,
  Wrap,
  WrapItem,
} from '@chakra-ui/react'

import { useGetCoachInboxQuery } from '../../app/services/api'
import Chat from '../chat/Chat'
import { useLocale } from '../../hooks/useLocale'

interface Props {
  coach: any
}

const truncateLastMessage = (text: string) => {
  if (text.length > 35) {
    return text.slice(0, 35) + '...'
  }
  return text
}

export const Inbox: React.FC<Props> = ({ coach }) => {
  const { t } = useLocale()
  const { data, isLoading, refetch } = useGetCoachInboxQuery('inbox')
  const { userId } = useParams<{ userId?: string }>()

  const onSendNewMessage = () => {
    refetch()
  }

  const onMessageRead = () => {
    // workaround to await the message read mutation on the server
    setTimeout(() => {
      refetch()
    }, 500)
  }

  if (isLoading) {
    return <Box>{t('loading')}</Box>
  }

  return (
    <Grid templateColumns="25% 1fr" gap={2}>
      <GridItem bg={'white'} p={2} borderRadius={5}>
        <Heading size="lg" mb="5" px={5} py={3}>
          Chats
        </Heading>
        {(data || []).map(({ user, unread, lastMessage }: any) => (
          <Flex
            key={user._id}
            as={Link}
            to={`/coach/inbox/${user._id}`}
            _hover={{ cursor: 'pointer', bg: 'gray.100' }}
            borderRadius={5}
            justify="space-between"
            align="center"
            mb={2}
            p={1}
            // Set bg color to blue.300 if the user is the selected user
            bg={userId === user._id ? 'gray.100' : 'white'}
          >
            <WrapItem>
              <Avatar name={user.name} src={user.picture} mr="3" />
              <Wrap direction="column" spacing={0}>
                <Text>
                  {user.name} @ {user.company}
                </Text>
                <Text size="sm">
                  {truncateLastMessage(lastMessage.text || '')}
                </Text>
              </Wrap>
            </WrapItem>

            {unread > 0 && (
              <WrapItem>
                <Badge
                  ml="1"
                  bg="red.800"
                  borderRadius={60}
                  colorScheme="whiteAlpha"
                >
                  {unread}
                </Badge>
              </WrapItem>
            )}
          </Flex>
        ))}
      </GridItem>
      <GridItem borderRadius={5}>
        {userId && (
          <>
            <Chat
              user={null}
              coach={coach}
              onSendNewMessage={onSendNewMessage}
              onMessageRead={onMessageRead}
            />
          </>
        )}
        {!userId && (
          <Center minH="85vh" bg="white">
            {t('select_a_user_to_chat_with')}
          </Center>
        )}
      </GridItem>
    </Grid>
  )
}
