/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Spinner } from '@chakra-ui/react'
import { useContext, useEffect, useState } from 'react'
import ReactGA from 'react-ga'
import { useDispatch, useSelector } from 'react-redux'
import { Route, Switch } from 'react-router-dom'

import runOneSignal from './utils/oneSignal'
import { useGetCoachMutation } from './app/services/api'
import { RootState } from './app/store'
import CardCoach from './CardCoach'
import Customers from './Customers'
import Login from './features/auth/Login'
import LoginCoach from './features/auth/LoginCoach'
import PasswordForgot from './features/auth/PasswordForgot'
import PasswordReset from './features/auth/PasswordReset'
import Signup from './features/auth/Signup'
import Bookings from './features/call/Bookings'
import ChangeCoach from './features/change-coach/ChangeCoach'
import Chat from './features/chat/Chat'
import Coaches from './features/coaches/Coaches'
import { updateSocket } from './features/connection/socketSlice'
import Customer from './features/customer/Customer'
import SessionReview from './features/customer/SessionReview'
import UserNotesWithSessionNotes from './features/customer/UserNotesWithSessionNotes'
import AppDistribution from './features/distribution/AppDistribution'
import AppDistributionApk from './features/distribution/AppDistributionApk'
import { FAQ } from './features/faq/FAQ'
import CoachingGuide from './features/guide/CoachingGuide'
import { Inbox } from './features/inbox/Inbox'
import OnboardingFlow from './features/onboarding/OnboardingFlow'
import { CoachProfile } from './features/profile/CoachProfile'
import CoachSettings from './features/profile/CoachSettings'
import UserTimezoneDialog from './features/profile/TimezoneDialog'
import UserProfile from './features/profile/UserProfile'
import Accept from './features/scheduler/Accept'
import Book from './features/scheduler/Book'
import BookingRequestConfirmation from './features/scheduler/BookingRequestConfirmation'
import BookPublic from './features/scheduler/BookPublic'
import PostSessionUserFeedback from './features/scheduler/PostSessionUserFeedback'
import Scheduler from './features/scheduler/Scheduler'
import Support from './features/support/Support'
import { FeedbackSurvey } from './features/survey/FeedbackSurvey'
import UnifiedLink from './features/unified/UnifiedLink'
import Home from './Home'
import HomeCoach from './HomeCoach'
import { SocketContext } from './hooks/socket'
import { useAuth } from './hooks/useAuth'
import { useAuth as useAuthCoach } from './hooks/useAuthCoach'
import { changeLocale } from './hooks/useLocale'
import Error from './utils/Error'
import { useMixpanel } from './utils/MixpanelContext'
import { PrivateRoute } from './utils/PrivateRoute'
import { PrivateRouteCoach } from './utils/PrivateRouteCoach'
import { Feedback } from './features/feedback/Feedback'
import { SelectCalendar } from './features/calendars/SelectCalendar'
import OnboardingLink from './features/distribution/OnboardingLink'

const context = new AudioContext()
let oneSignalRegistered = false

function App() {
  const { user } = useAuth()
  const { coach } = useAuthCoach()
  const [coachRequested, setCoachRequested] = useState(false)
  const [getCoach, { data: usersCoach, isLoading, error }] =
    useGetCoachMutation()
  const mixpanel = useMixpanel()
  const dispatch = useDispatch()

  const socket: any = useContext(SocketContext)
  const socketId = useSelector((state: RootState) => state.socket.socketId)

  useEffect(() => {
    // eslint-disable-next-line
    // @ts-ignore
    const userId = user?.id || user?._id || coach?.id || coach?._id

    socket.emit('status:connect', {
      user: userId,
    })
    // Track pageview events
    ReactGA.pageview(window.location.pathname + window.location.search)

    if (userId && !oneSignalRegistered) {
      oneSignalRegistered = true
      runOneSignal(userId)
    }

    window.addEventListener('click', handleClick)
    return () => {
      window.removeEventListener('click', handleClick)
      socket.disconnect()
    }
  }, [])

  useEffect(() => {
    if (user || coach) {
      if (!socket.connected) {
        socket.connect()
        console.log(
          'socket.connect() socket.id: ' + socket.id,
          ', socketId:',
          socketId
        )
      } else if (!socketId && socket.id) {
        // in case we logged out and log in within same session
        dispatch(updateSocket(socket.id))
      }
    }
  }, [user, coach, socketId])

  useEffect(() => {
    if (user?.language) changeLocale(user.language)
    const loadCoach = async () => {
      if (user) {
        setCoachRequested(true)
        await getCoach()
        // setCoachRequested(false)
      }
    }
    // Get users coach
    loadCoach()
  }, [user])

  useEffect(() => {
    if (
      usersCoach &&
      usersCoach.email &&
      mixpanel.get_property('coach') !== usersCoach.email
    ) {
      mixpanel.add_group('coach', usersCoach.email)
    }
  }, [usersCoach])

  const handleClick = (event: any) => {
    try {
      context.resume()
    } catch (error) {
      console.log(error)
    }
  }

  if (coachRequested && isLoading) {
    console.log('isLoading', isLoading)
    return <Spinner />
  } else if (coachRequested && user && (error || !usersCoach)) {
    console.clear()
    console.log(
      Boolean(coachRequested),
      Boolean(user),
      Boolean(usersCoach),
      Boolean(error)
    )
    return <Error />
  }

  return (
    <SocketContext.Provider value={socket}>
      <UserTimezoneDialog />
      <Box>
        <Switch>
          <Route exact path="/login" component={Login} />
          <Route exact path="/password_forgot" component={PasswordForgot} />
          <Route
            exact
            path="/password_reset/:token/:email"
            component={PasswordReset}
          />
          <Route exact path="/signup/:invitationId?" component={Signup} />
          <Route exact path="/book/:invitationId" component={BookPublic} />
          <Route
            exact
            path="/onboarding/:language/:companyId"
            component={OnboardingFlow}
          />
          <Route exact path="/survey/:sessionId" component={FeedbackSurvey} />
          <Route exact path="/coach-login" component={LoginCoach} />
          <Route exact path="/coach/login" component={LoginCoach} />
          <Route
            exact
            path="/coach/password_forgot"
            component={() => PasswordForgot({ isCoach: true })}
          />
          <Route
            exact
            path="/coach/password_reset/:token/:email"
            component={() => PasswordReset({ isCoach: true })}
          />
          <Route
            exact
            path="/bookings/accept/:userId/:eventId/:isCoach?"
            component={Accept}
          />
          <Route
            exact
            path="/bookings/review/:eventId/:coachName/:rating?"
            component={SessionReview}
          />
          <Route
            exact
            path="/support/:userId/:userName/:userEmail/:userCompany/:userCoachEmail"
            component={Support}
          />
          <Route exact path="/app/distribution" component={AppDistribution} />
          <Route
            exact
            path="/app/distribution/apk"
            component={AppDistributionApk}
          />
          <Route
            exact
            path="/links/unified/:companyFriendlyId"
            component={UnifiedLink}
          />
          <Route
            exact
            path="/links/onboarding/:companyFriendlyId"
            component={OnboardingLink}
          />
          <Route
            exact
            path="/session-feedback/user/:eventId/:coachName/:rating?"
            component={PostSessionUserFeedback}
          />
          <Route
            exact
            path="/booking-request/:requestId"
            component={BookingRequestConfirmation}
          />
          <PrivateRouteCoach exact path="/coach">
            <HomeCoach coach={coach}>
              <Customers coach={coach} />
            </HomeCoach>
          </PrivateRouteCoach>
          <PrivateRouteCoach exact path="/coach/profile">
            <HomeCoach coach={coach}>
              <CoachProfile coach={coach} />
            </HomeCoach>
          </PrivateRouteCoach>
          <PrivateRouteCoach exact path="/coach/settings">
            <HomeCoach coach={coach}>
              <CoachSettings coach={coach} />
            </HomeCoach>
          </PrivateRouteCoach>
          <PrivateRouteCoach exact path="/coach/settings/new-calendar">
            <HomeCoach coach={coach}>
              <SelectCalendar coach={coach} />
            </HomeCoach>
          </PrivateRouteCoach>
          <PrivateRouteCoach exact path="/coach/inbox">
            <HomeCoach coach={coach}>
              <Inbox coach={coach} />
            </HomeCoach>
          </PrivateRouteCoach>
          <PrivateRouteCoach exact path="/coach/inbox/:userId">
            <HomeCoach coach={coach}>
              <Inbox coach={coach} />
            </HomeCoach>
          </PrivateRouteCoach>
          <PrivateRouteCoach exact path="/coach/chat/:userId">
            <HomeCoach coach={coach}>
              <Chat user={user} coach={coach} />
            </HomeCoach>
          </PrivateRouteCoach>
          <PrivateRouteCoach exact path="/coach/customer/:userId">
            <HomeCoach coach={coach}>
              <Customer coach={coach} />
            </HomeCoach>
          </PrivateRouteCoach>
          <PrivateRouteCoach exact path="/coach/schedule">
            <HomeCoach coach={coach}>
              <Scheduler user={user} coach={coach} />
            </HomeCoach>
          </PrivateRouteCoach>
          <PrivateRouteCoach exact path="/coach/feedback">
            <HomeCoach coach={coach}>
              <Feedback coach={coach} />
            </HomeCoach>
          </PrivateRouteCoach>
          <PrivateRoute exact path="/">
            <Home user={user}>
              {user?.coach ? (
                <CardCoach user={user} usersCoach={usersCoach} />
              ) : (
                <Coaches />
              )}
            </Home>
          </PrivateRoute>

          <PrivateRoute path="/change-coach">
            <Home user={user}>
              <ChangeCoach usersCoach={usersCoach} />
            </Home>
          </PrivateRoute>
          <PrivateRoute path="/faq">
            <Home user={user}>
              <FAQ />
            </Home>
          </PrivateRoute>
          <PrivateRoute path="/chat/:userId?">
            <Home user={user}>
              <Chat user={user} coach={coach} usersCoach={usersCoach} />
            </Home>
          </PrivateRoute>
          <PrivateRoute path="/book">
            <Home user={user}>
              <Book user={user} coach={usersCoach} />
            </Home>
          </PrivateRoute>
          <PrivateRoute path="/notes">
            <Home user={user}>
              <UserNotesWithSessionNotes user={user} />
            </Home>
          </PrivateRoute>
          <PrivateRoute path="/bookings/:userId?">
            <Home user={user}>
              <Bookings user={user} coach={usersCoach} />
            </Home>
          </PrivateRoute>
          <PrivateRoute path="/profile">
            <Home user={user}>
              <UserProfile user={user} />
            </Home>
          </PrivateRoute>
          <PrivateRoute path="/coaching-guide">
            <Home user={user}>
              <CoachingGuide />
            </Home>
          </PrivateRoute>
        </Switch>
      </Box>
    </SocketContext.Provider>
  )
}

export default App
