import {
  Avatar,
  Box,
  Divider,
  Flex,
  ListItem,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Tag,
  TagLabel,
  Text,
  UnorderedList,
} from '@chakra-ui/react'
import { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { processNewLineText } from '../../utils/text'

const ExpandableText: FC<any> = ({ title, text }) => {
  const { t } = useTranslation()
  const [visible, setVisible] = useState(true)
  return (
    <>
      <Box>
        <Text fontWeight="700" fontSize="18px" mb={2}>
          {title}
        </Text>
        <Text noOfLines={visible ? 3 : undefined}>
          {processNewLineText(text)}
        </Text>
        {text && text.length > 0 && (
          <Text
            color="var(--chakra-colors-primary-500)"
            py={2}
            fontWeight="400"
            display="inline-block"
            cursor="pointer"
            onClick={() => setVisible(!visible)}
            userSelect="none"
          >
            {t(visible ? 'read_more' : 'read_less')}
          </Text>
        )}
      </Box>
      <Divider my={4} />
    </>
  )
}

const CoachInfo: FC<any> = ({ isOpen, onClose, coach }) => {
  const { t } = useTranslation()
  const bio = coach?.bio || coach?.coachBio
  const profile = coach?.profile || coach?.coach?.profile

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      {coach && (
        <ModalContent>
          <ModalHeader
            style={{
              padding: '0',
              position: 'sticky',
              backgroundColor: '#fff',
              zIndex: 2,
              top: 0,
              borderRadius: '10px 10px 0 0',
            }}
            shadow="sm"
          >
            <Flex p={4} alignItems="center" gridColumnGap={3}>
              <Avatar size="lg" src={profile?.picture} />
              <Flex direction="column" alignItems="flex-start">
                <Text fontWeight="700">{profile?.name} </Text>
                <Text fontSize="14px">{profile?.title} </Text>
              </Flex>
            </Flex>
            <Divider />
            <ModalCloseButton zIndex={3} />
          </ModalHeader>
          <ModalBody pb={4}>
            <ExpandableText
              key="philosophy"
              title={t('summary')}
              text={bio?.summary || bio?.bio?.summary || ''}
            />
            {(bio?.full || bio?.bio?.full) && (
              <ExpandableText
                title={t('biography')}
                text={bio?.full || bio?.bio?.full || ''}
              />
            )}
            {(bio?.coachingType || bio?.bio?.coachingType) && (
              <ExpandableText
                key="coaching_type"
                title={t('coaching_look_like', { coach: profile?.name })}
                text={bio?.coachingType || bio?.bio?.coachingType || ''}
              />
            )}
            {(bio?.qualifications?.length ||
              bio?.bio?.qualifications?.length) && (
              <Box>
                <Text fontWeight="700" fontSize="18px" mb={2}>
                  {t('qualifications')}
                </Text>
                <UnorderedList>
                  {(bio?.qualifications || bio?.bio?.qualifications) &&
                    (bio?.qualifications || bio?.bio?.qualifications).map(
                      (license: string, index: number) => (
                        <ListItem key={`license_${index}`}>
                          <Text> {processNewLineText(license)} </Text>
                        </ListItem>
                      )
                    )}
                </UnorderedList>
                <Divider my={4} />
              </Box>
            )}
            {(bio?.specilizations?.length ||
              bio?.bio?.specilizations?.length) && (
              <Box>
                <Text fontWeight="700" fontSize="18px" mb={2}>
                  {t('specializations')}
                </Text>
                <Flex
                  wrap="wrap"
                  gridGap={2}
                  alignItems="center"
                  justifyContent="flex-start"
                >
                  {(bio?.specilizations || bio?.bio?.specilizations) &&
                    (bio?.specilizations || bio?.bio?.specilizations).map(
                      (item: any, index: number) => (
                        <Tag size="lg" colorScheme="red" key={item._id}>
                          <TagLabel> {item.specialization} </TagLabel>
                        </Tag>
                      )
                    )}
                </Flex>
                <Divider my={4} />
              </Box>
            )}
            {(bio?.languages?.length || bio?.bio?.languages?.length) && (
              <Box>
                <Text fontWeight="700" fontSize="18px" mb={2}>
                  {t('languages')}
                </Text>
                <Flex
                  wrap="wrap"
                  gridGap={2}
                  alignItems="center"
                  justifyContent="flex-start"
                >
                  {(bio?.languages || bio?.bio?.languages) &&
                    (bio?.languages || bio?.bio?.languages).map(
                      (item: any, index: number) => (
                        <Tag
                          size="lg"
                          colorScheme="red"
                          key={`coach_lang_${index}`}
                        >
                          <TagLabel> {item} </TagLabel>
                        </Tag>
                      )
                    )}
                </Flex>
                <Divider my={4} />
              </Box>
            )}
          </ModalBody>
        </ModalContent>
      )}
    </Modal>
  )
}

export default CoachInfo
