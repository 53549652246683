import { Box, Flex, Heading, Image, SimpleGrid } from '@chakra-ui/react'
import { FC } from 'react'
import {
  HUPO_CARE_ANDROID_APK_LOCATION_LINK,
  HUPO_CARE_IOS_APP_STORE_LINK,
} from '../../app/constants'
import { useLocale } from '../../hooks/useLocale'

const AppDistributionApk: FC<any> = () => {
  const { t } = useLocale()
  return (
    <Flex
      alignItems="center"
      justifyContent="center"
      padding={10}
      direction="column"
    >
      <Heading
        display="inline-block"
        as="h5"
        size="lg"
        textAlign={'center'}
        mb={10}
      >
        {t('download-app-from-links')}
      </Heading>
      <SimpleGrid
        minChildWidth="220px"
        spacing="100px"
        style={{ alignItems: 'center' }}
      >
        <Box>
          <a href={HUPO_CARE_IOS_APP_STORE_LINK}>
            <Image
              src={'../../images/apple-app-store-logo.jpeg'}
              style={{ paddingRight: 10 }}
              objectFit={'cover'}
              alt={'iOS App Store Link'}
            />
          </a>
        </Box>
        <Box>
          <a href={HUPO_CARE_ANDROID_APK_LOCATION_LINK}>
            <Image
              src={'../../images/android-logo.png'}
              style={{ paddingLeft: 10 }}
              objectFit={'cover'}
              alt={'APK download link'}
            />
          </a>
        </Box>
      </SimpleGrid>
    </Flex>
  )
}

export default AppDistributionApk
