import { useState } from 'react'
import { useParams } from 'react-router-dom'

import { Box, Flex, useColorModeValue } from '@chakra-ui/react'
import { Logo } from '../../utils/Logo'
import ProgressComponent from './components/ProgressLine'
import FinalizeSetupStep from './steps/FinalizeSetupStep'
import InitialStepBasicInfo from './steps/InitialStepBasicInfo'
import OnboardingQuestionsStep from './steps/OnboardingQuestionsStep'
import { CoachSelectionStep } from './steps/CoachSelectionStep'
import { useSelector } from 'react-redux'

const OnboardingFlow = () => {
  const { language, companyId } = useParams() as any

  const [step, setStep] = useState(0)
  const [totalSteps, setTotalSteps] = useState(1)

  const onboardingData = useSelector(
    (state: any) => state.onboarding.onboardingData
  )
  const question = step > 0 ? onboardingData.questions[step - 1] : null

  const onProceedNextStep = () => {
    setStep((prev) => prev + 1)
    console.log('onProceedNextStep step:', step + 1)
  }

  const onGoBack = () => {
    setStep((prev) => prev - 1)
  }

  console.log(question?.type)

  let content = null
  if (step === 0) {
    content = (
      <InitialStepBasicInfo
        onProceedNextStep={onProceedNextStep}
        setTotalSteps={setTotalSteps}
        language={language}
        companyId={companyId}
      />
    )
  } else if (step === totalSteps) {
    content = <FinalizeSetupStep step={step} onGoBack={onGoBack} />
  } else if (question?.type === 'coach-selection') {
    content = (
      <CoachSelectionStep
        onProceedNextStep={onProceedNextStep}
        onGoBack={onGoBack}
      />
    )
  } else {
    content = (
      <OnboardingQuestionsStep
        key={`step#${step}`}
        step={step}
        onProceedNextStep={onProceedNextStep}
        onGoBack={onGoBack}
      />
    )
  }

  return (
    <Box
      flexDirection={'column'}
      flex={1}
      overflow="auto"
      bg={useColorModeValue('gray.50', 'gray.800')}
    >
      <Flex
        px={{ base: 4, md: 4 }}
        height="20"
        bg={useColorModeValue('white', 'gray.900')}
        borderBottomWidth="1px"
        borderBottomColor={useColorModeValue('gray.200', 'gray.700')}
        alignItems="center"
        justifyContent={{ base: 'space-between', md: 'center' }}
      >
        <Logo md={5} />
      </Flex>
      <ProgressComponent currentPage={step} totalPages={totalSteps} />
      {content}
    </Box>
  )
}

export default OnboardingFlow
