import { Box } from '@chakra-ui/react'

export const Card = ({ children, ...rest }: any) => {
  return (
    <Box
      bg="#fff"
      borderRadius="md"
      boxShadow="md"
      p={4}
      w="100%"
      maxW="3xl"
      mx="auto"
      {...rest}
    >
      {children}
    </Box>
  )
}
