import { Box, Image, SimpleGrid } from '@chakra-ui/react'
import React, { useEffect } from 'react'
import { useLocale } from '../../hooks/useLocale'
import { useMixpanel } from '../../utils/MixpanelContext'

const CoachingGuide = () => {
  const mixpanel = useMixpanel()
  const { i18n } = useLocale()

  useEffect(() => {
    mixpanel.track('coaching_guide_open')
  }, [])

  return (
    <>
      {i18n.language === 'ko' ? (
        <SimpleGrid
          columns={{ base: 1, md: 2 }}
          gridTemplateColumns={{ base: '1fr', md: '2.025fr 3.618fr' }}
          spacingX={0}
          spacingY={0}
        >
          <Box>
            <Image
              src="https://ami-landing.s3.ap-southeast-1.amazonaws.com/images/hupo-coaching-pathways-ko-left.png"
              objectFit={'cover'}
              alt="Hupo Coaching Guide - text"
            />
          </Box>
          <Box>
            <Image
              src="https://ami-landing.s3.ap-southeast-1.amazonaws.com/images/hupo-coaching-pathways-ko-right.png"
              objectFit={'cover'}
              alt="Hupo Coaching Guide - Flywheel"
            />
          </Box>
        </SimpleGrid>
      ) : (
        <SimpleGrid
          columns={{ base: 1, md: 2 }}
          gridTemplateColumns={{ base: '1fr', md: '2.025fr 3.413fr' }}
          spacingX={0}
          spacingY={0}
        >
          <Box>
            <Image
              src="https://ami-landing.s3.ap-southeast-1.amazonaws.com/images/hupo-coaching-pathways-left.png"
              objectFit={'cover'}
              alt="Hupo Coaching Guide - text"
            />
          </Box>
          <Box>
            <Image
              src="https://ami-landing.s3.ap-southeast-1.amazonaws.com/images/hupo-coaching-pathways-right.png"
              objectFit={'cover'}
              alt="Hupo Coaching Guide - Flywheel"
            />
          </Box>
        </SimpleGrid>
      )}
    </>
  )
}

export default CoachingGuide
