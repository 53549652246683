import { ChakraProvider } from '@chakra-ui/react'
import flagsmith from 'flagsmith'
import { FlagsmithProvider } from 'flagsmith/react'
import { createBrowserHistory } from 'history'
import ReactDOM from 'react-dom'
import ReactGA from 'react-ga'
import { Provider } from 'react-redux'
import { Router } from 'react-router'
import { PersistGate } from 'redux-persist/integration/react'

import App from './App'
import './i18n'

import { FLAGSMITH_ENVIRONMENT_ID, GA_TRACKING_ID } from './app/constants'
import { persistor, store } from './app/store'
import MixpanelProvider from './utils/MixpanelContext'
import { theme } from './utils/theme'

ReactGA.initialize(GA_TRACKING_ID)

const history = createBrowserHistory()

ReactDOM.render(
  <FlagsmithProvider
      flagsmith={flagsmith}
      options={{ environmentID: FLAGSMITH_ENVIRONMENT_ID }}
  >
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <ChakraProvider theme={theme}>
          <MixpanelProvider>
            <Router history={history}>
              <App />
            </Router>
          </MixpanelProvider>
        </ChakraProvider>
      </PersistGate>
    </Provider>
  </FlagsmithProvider>,
  document.getElementById('root')
)
