import { Box, Heading, Text, Button, Center } from '@chakra-ui/react'
import { FiRefreshCcw } from 'react-icons/fi'
import { IoMdConstruct } from 'react-icons/io'
import { useLocale } from '../hooks/useLocale'
import { useLogout } from '../hooks/useLogout'

const Error = () => {
  const { t } = useLocale()
  const onLogout = useLogout()

  return (
    <Box textAlign="center" py={10} px={6}>
      <Center mb={10}>
        <IoMdConstruct size={100} color="#E2E8F0" />
      </Center>
      <Heading
        display="inline-block"
        as="h2"
        size="2xl"
        mb={6}
        fontWeight="bold"
        lineHeight="shorter"
        color="gray.900"
      >
        {t('error')}
      </Heading>
      <Text fontSize="18px" mt={3} mb={10}>
        {t('error_description')}
      </Text>

      <Button
        size="lg"
        colorScheme="primary"
        leftIcon={<FiRefreshCcw />}
        onClick={onLogout}
      >
        {t('error_button')}
      </Button>
    </Box>
  )
}

export default Error
