import {
  Flex,
  Heading,
  Text,
  useColorModeValue,
  VStack,
} from '@chakra-ui/react'
import React from 'react'

import { Card, CardProps } from './Card'

export interface PricingCardData {
  id: string
  features: string[]
  name: string
  biography: string
}

interface PricingCardProps extends CardProps {
  data: PricingCardData
  selectedCoachId?: string
}

export const CoachCard = (props: PricingCardProps) => {
  const { data, selectedCoachId, ...rest } = props
  const { id, biography, name } = data
  const accentColor = useColorModeValue('blue.600', 'blue.200')

  return (
    <Card
      rounded={{ sm: 'xl' }}
      borderWidth={1}
      cursor="pointer"
      style={{
        transform: selectedCoachId === id ? 'scale(1.02)' : 'scale(1)',
        borderColor: selectedCoachId === id ? accentColor : '',
      }}
      _hover={{
        transform: 'scale(1.02)',
        borderColor: accentColor,
      }}
      data-id={id}
      {...rest}
    >
      <VStack spacing={6}>
        <Heading size="md" fontWeight="bold" color={accentColor}>
          {name}
        </Heading>
      </VStack>
      <Flex align="flex-end" justify={'center'} my="8">
        <Text color="muted" maxW="2xl" textAlign={'left'} fontSize="14px">
          {biography}
        </Text>
      </Flex>
    </Card>
  )
}
