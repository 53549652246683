import {
  Button,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Input,
  Stack,
  useColorModeValue,
  useToast,
} from '@chakra-ui/react'
import { ChangeEvent, useCallback, useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'

import {
  useCoachPasswordResetMutation,
  usePasswordResetMutation,
} from '../../app/services/api'
import { useLocale } from '../../hooks/useLocale'
import { PasswordResetRequest } from '../../types/api'
import { useMixpanel } from '../../utils/MixpanelContext'

const PasswordReset = ({ isCoach = false }): JSX.Element => {
  // Get reset token from params
  const { token, email } = useParams() as any
  const { t } = useLocale()
  const { push } = useHistory()
  const toast = useToast()
  const [isButtonDisabled, setIsButtonDisabled] = useState(true)
  const mixpanel = useMixpanel()

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [passwordReset, { isLoading }] = isCoach ? useCoachPasswordResetMutation() : usePasswordResetMutation()

  const [formState, setFormState] = useState<PasswordResetRequest>({
    email,
    password: '',
    confirmPassword: '',
    token,
  })

  useEffect(() => {
    // Disable button if form is not filled
    setIsButtonDisabled(
      !formState?.password ||
        !formState?.confirmPassword ||
        !(formState?.password === formState?.confirmPassword)
    )
  }, [formState])

  const onSubmit = useCallback(
    async (event) => {
      event.preventDefault()
      if (isButtonDisabled) {
        return
      }
      try {
        setIsButtonDisabled(true)
        await passwordReset(formState).unwrap()
        setIsButtonDisabled(false)
        toast({
          title: t('password_reset_success'),
          description: t('password_reset_success_description'),
          status: 'success',
          duration: 5000,
          isClosable: true,
        })
        mixpanel.track('password_reset_success')
        isCoach ? push('/coach-login') : push('/login')
      } catch (err: any) {
        toast({
          status: 'error',
          title: t('error'),
          description: t('there_was_an_error'),
          isClosable: true,
        })
        mixpanel.track('password_reset_fail', { message: err?.data?.message })
      }
    },
    [
      formState,
      isButtonDisabled,
      passwordReset,
      t,
      toast,
      mixpanel,
      push,
      isCoach,
    ]
  )

  const handleChange = ({
    target: { name, value },
  }: ChangeEvent<HTMLInputElement>) =>
    setFormState((prev) => ({ ...prev, [name]: value }))

  return (
    <Flex
      minH={'100vh'}
      align={'center'}
      justify={'center'}
      bg={useColorModeValue('gray.50', 'gray.800')}
    >
      <form onSubmit={onSubmit}>
        <Stack
          spacing={4}
          w={'full'}
          maxW={'md'}
          bg={useColorModeValue('white', 'gray.700')}
          rounded={'xl'}
          boxShadow={'lg'}
          p={6}
          my={12}
        >
          <Heading lineHeight={1.1} fontSize={{ base: '2xl', md: '3xl' }}>
            {t('reset_your_password')}
          </Heading>
          <FormControl id="email" isRequired>
            <FormLabel>{t('email_address')}</FormLabel>
            <Input
              value={email}
              _placeholder={{ color: 'gray.500' }}
              type="email"
              disabled={true}
            />
          </FormControl>
          <FormControl id="password" isRequired>
            <FormLabel>{t('password')}</FormLabel>
            <Input type="password" name="password" onChange={handleChange} />
          </FormControl>
          <FormControl id="confirmPassword" isRequired>
            <FormLabel>{t('confirm_password')}</FormLabel>
            <Input
              type="password"
              name="confirmPassword"
              onChange={handleChange}
            />
          </FormControl>
          <Stack spacing={6}>
            <Button
              bg={'blue.400'}
              color={'white'}
              _hover={{
                bg: 'blue.500',
              }}
              type="submit"
              isLoading={isLoading}
              isDisabled={isButtonDisabled}
            >
              {t('reset_password')}
            </Button>
          </Stack>
        </Stack>
      </form>
    </Flex>
  )
}

export default PasswordReset
