import {
  Avatar,
  Badge,
  Box,
  Button,
  Flex,
  Heading,
  Text,
  useDisclosure,
} from '@chakra-ui/react'
import { MdKeyboardArrowRight } from 'react-icons/md'

import { useLocale } from '../../../hooks/useLocale'
import { VideoModal } from './VideoModal'

export const Coach: React.FC<any> = ({
  name,
  picture,
  video,
  description,
  languages = [],
  isSelected = false,
  onSelect,
  openModal,
}) => {
  const { t } = useLocale()
  const { isOpen, onClose, onOpen } = useDisclosure()

  return (
    <Box
      as={Flex}
      p={5}
      shadow="md"
      borderWidth="1px"
      borderRadius="lg"
      flexDir="column"
      justifyContent="center"
      alignItems="center"
      maxW="lg"
      mx="auto"
      my="3"
      bg={isSelected ? 'red.200' : 'white'}
    >
      <Avatar
        onClick={openModal}
        _hover={{ cursor: 'pointer' }}
        size="xl"
        name={name}
        src={picture}
      />
      <Heading
        onClick={openModal}
        _hover={{ cursor: 'pointer', textDecoration: 'underline' }}
        pb="2"
      >
        {name}
      </Heading>
      <Text>{description}</Text>

      <Box as={Flex} alignItems="center" mt="3">
        <Text mr="3" as="i">
          Languages:
        </Text>
        {languages.map((language: any) => (
          <Badge mr="1">{language}</Badge>
        ))}
      </Box>

      {video && (
        <Flex
          style={{ cursor: 'pointer' }}
          _hover={{ opacity: 0.4 }}
          alignItems="center"
          justifyContent="space-between"
          userSelect="none"
          my="2"
          transition=".1s"
          _active={{
            transform: 'scaleX(.9)',
          }}
          onClick={onOpen}
        >
          <Text fontWeight="700">{t('watch_coach_intro')}</Text>
          <MdKeyboardArrowRight fontSize="30px" />
          <VideoModal url={video} onClose={onClose} isOpen={isOpen} />
        </Flex>
      )}

      <Box minW="100%" mt="3">
        <Button
          minW="100%"
          bg={isSelected ? 'transparent' : 'primary.500'}
          color={isSelected ? 'black' : 'white'}
          _hover={{ bg: isSelected ? 'red.700' : 'red.500' }}
          isDisabled={isSelected}
          onClick={onSelect}
        >
          {isSelected ? 'Selected' : 'Select'}
        </Button>
      </Box>
    </Box>
  )
}
