import {
  Box,
  Button,
  Flex,
  Heading,
  SimpleGrid,
  useDisclosure,
} from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { LANGUAGE_PREFERENCE_QUESTION } from '../../../app/constants'
import { useGetOnboardingCoachOptionsQuery } from '../../../app/services/api'
import { useLocale } from '../../../hooks/useLocale'
import { useMixpanel } from '../../../utils/MixpanelContext'
import CoachInfo from '../../coaches/CoachInfo'
import { Coach } from '../components/Coach'
import { GoBackButton } from '../components/GoBackButton'
import { setCoachEmail } from '../onboardingSlice'

export const CoachSelectionStep: React.FC<any> = ({
  onProceedNextStep,
  onGoBack,
}) => {
  const { t } = useLocale()
  const dispatch = useDispatch()
  const mixpanel = useMixpanel()

  const { isOpen, onClose, onOpen } = useDisclosure()

  const email = useSelector((state: any) => state.onboarding.email)
  const companyId = useSelector((state: any) => state.onboarding.companyId)
  const language = useSelector((state: any) => state.onboarding.language)
  const coachEmail = useSelector((state: any) => state.onboarding.coachEmail)

  const [selectedCoach, setSelectedCoach] = useState(coachEmail)
  const [previewingCoach, setPreviewingCoach] = useState(coachEmail)

  const onboardingQuestions = useSelector(
    (state: any) => state.onboarding.questions
  )

  let coachingLanguages = [language]
  if (LANGUAGE_PREFERENCE_QUESTION in onboardingQuestions) {
    coachingLanguages = onboardingQuestions[LANGUAGE_PREFERENCE_QUESTION]
  }

  const { data, error, isLoading } = useGetOnboardingCoachOptionsQuery({
    email,
    companyId,
    language,
    coachingLanguages: coachingLanguages.join(','),
  })

  const noCoachOptions = data?.coaches.length === 0
  const topCoachInfo = data?.coaches[0]
  const additionalCoachOptions = data?.coaches.slice(1)

  useEffect(() => {
    if (topCoachInfo && !selectedCoach) {
      setSelectedCoach(topCoachInfo.coach.email)
    }
  }, [topCoachInfo, selectedCoach])

  const onProceed = () => {
    dispatch(setCoachEmail(selectedCoach))
    onProceedNextStep()
    mixpanel.track('onboarding_coach_selected', { coach: selectedCoach })
  }

  if (isLoading) {
    return <div>{t('loading')}</div>
  }

  if (error) {
    return <div>{t('an_error_occurred')}</div>
  }

  if (noCoachOptions) {
    return (
      <Box>
        <Heading textAlign="center">{t('no_coach_options')}</Heading>
      </Box>
    )
  }

  return (
    <>
      <CoachInfo isOpen={isOpen} onClose={onClose} coach={previewingCoach} />
      <Box>
        <Heading my="4" fontSize="2xl" textAlign="center">
          {t('our_recommended_coach')}
        </Heading>
        <Coach
          name={topCoachInfo?.profile.name}
          picture={topCoachInfo?.profile?.picture}
          video={topCoachInfo?.profile?.video}
          description={topCoachInfo?.bio?.firstCall}
          languages={topCoachInfo?.languages}
          isSelected={topCoachInfo?.coach.email === selectedCoach}
          onSelect={() => setSelectedCoach(topCoachInfo?.coach.email)}
          openModal={() => {
            onOpen()
            setPreviewingCoach(topCoachInfo)
          }}
        />

        {additionalCoachOptions.length > 0 && (
          <Box my="5">
            <Heading textAlign="center" fontSize="xl">
              {t('or_explore_other_coaches')}
            </Heading>
          </Box>
        )}

        <SimpleGrid columns={2} gap="1">
          {additionalCoachOptions?.map((coachInfo: any) => (
            <Coach
              name={coachInfo?.profile.name}
              picture={coachInfo?.profile?.picture}
              video={coachInfo?.profile?.video}
              description={coachInfo?.bio?.firstCall}
              languages={coachInfo?.languages}
              isSelected={coachInfo?.coach.email === selectedCoach}
              onSelect={() => setSelectedCoach(coachInfo?.coach.email)}
              openModal={() => {
                onOpen()
                setPreviewingCoach(coachInfo)
              }}
            />
          ))}
        </SimpleGrid>

        <Flex mt="5" mb="7" justify="center">
          <GoBackButton onGoBack={onGoBack} />
          <Button
            minW="xl"
            ml="2"
            bg="primary.500"
            color="white"
            onClick={onProceed}
            isDisabled={!selectedCoach}
          >
            {t('proceed')}
          </Button>
        </Flex>
      </Box>
    </>
  )
}
