import { useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

import { RootState } from '../../app/store'
import { useLocale } from '../../hooks/useLocale'
import { Question } from '../onboarding/components/Question'
import { itemSelect } from './surveySlice'

const SHOULD_CHECK_TYPES = ['select', 'checkbox', 'radio', 'multiselect']

const FeedbackSurveyQuestionsStep = (props: any) => {
  const { step, onProceedNextStep, canGoBack } = props

  const { t } = useLocale()
  const [errorMessage, setErrorMessage] = useState('')
  const [showError, setShowError] = useState(false)

  const surveyData = useSelector((state: RootState) => state.survey.surveyData)
  const question = surveyData.questions[step - 1]
  const minSelectionLimit = question.minSelectionLimit ?? 1
  const maxSelectionLimit = question.maxSelectionLimit

  const selectedQuestions = useSelector(
    (state: RootState) => state.survey.questions
  )
  const selectedCount =
    (selectedQuestions[question._id]?.answers.length ||
    typeof selectedQuestions[question._id]?.other === 'string'
      ? 1
      : 0) || 0

  useEffect(() => {
    if (!SHOULD_CHECK_TYPES.includes(question?.type)) {
      const answer = selectedQuestions[question._id]?.other

      if (question.required && !answer) {
        console.log('required', answer);
        
        setErrorMessage(t('field_required'))
      } else {
        setErrorMessage('')
        setShowError(false)
      }

      return
    }

    if (
      maxSelectionLimit &&
      (selectedCount < minSelectionLimit || selectedCount > maxSelectionLimit)
    ) {
      if (minSelectionLimit < maxSelectionLimit) {
        setErrorMessage(
          t('select_between_x_y_items', {
            min: minSelectionLimit,
            max: maxSelectionLimit,
          })
        )
      } else if (minSelectionLimit === maxSelectionLimit) {
        setErrorMessage(t('select_x_items', { count: minSelectionLimit }))
      }
    } else if (selectedCount < minSelectionLimit) {
      setErrorMessage(
        t('select_at_least_x_items', { count: minSelectionLimit })
      )
    } else {
      setErrorMessage('')
      setShowError(false)
    }
  }, [
    selectedCount,
    minSelectionLimit,
    maxSelectionLimit,
    t,
    question,
    selectedQuestions,
  ])

  const onProceed = useCallback(
    (step = 1) => {
      if (step < 0) {
        onProceedNextStep(step)
        return
      }

      if (errorMessage) {
        setShowError(true)
      } else {
        onProceedNextStep()
        console.log('onProceedNextStep')
      }
    },
    [errorMessage, onProceedNextStep]
  )

  return (
    <Question
      question={question}
      itemSelect={itemSelect}
      onProceed={onProceed}
      errorMessage={errorMessage}
      showError={showError}
      setErrorMessage={setErrorMessage}
      setShowError={setShowError}
      canGoBack={canGoBack}
    />
  )
}

export default FeedbackSurveyQuestionsStep
