import { useEffect, useRef } from 'react'
import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  Textarea,
  useToast,
} from '@chakra-ui/react'
import { usePostUserBookingMutation } from '../../app/services/api'
import { useLocale } from '../../hooks/useLocale'
import { useMixpanel } from '../../utils/MixpanelContext'
import { useFlags } from 'flagsmith/react'

export const AskPopUp: React.FC<any> = ({
  isOpen,
  keepLookingAndClose,
  onClose,
  triedDates,
}) => {
  const textArea = useRef<HTMLTextAreaElement>(null)
  const { t } = useLocale()
  const toast = useToast()
  const mixpanel = useMixpanel()
  const { booking_flow: bookingFlowExperiment } = useFlags(['booking_flow'])

  useEffect(() => {
    mixpanel.track('appointment_time_preference_modal_shown', {
      bookingExperimentEnabled: bookingFlowExperiment.enabled,
      bookingFlow: bookingFlowExperiment.value,
      datesTried: triedDates,
    });
  }, [triedDates, bookingFlowExperiment, mixpanel]);

  const [postBookingRequest] = usePostUserBookingMutation()

  const onSubmit = (e: React.FormEvent) => {
    e.preventDefault()
    const text = textArea.current?.value
    if (!text) return

    toast({
      title: t('appointment_preference_submit_success'),
      status: 'success',
      duration: 5000,
      isClosable: true,
    })

    mixpanel.track('appointment_time_preference_submitted', {
      bookingExperimentEnabled: bookingFlowExperiment.enabled,
      bookingFlow: bookingFlowExperiment.value,
    });

    postBookingRequest({
      datesTried: triedDates,
      requestText: text,
      bookingFlow: bookingFlowExperiment.value,
    })
      .unwrap()
      .then(() => {
        keepLookingAndClose()
      })
      .catch(() => {})
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{t('cant_find_preferred_slot')}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Text mb={4}>{t('provide_preferred_slot')}</Text> 
          <form id="ask-form" onSubmit={onSubmit}>
            <Textarea
              ref={textArea}
              placeholder={t('preferred_slots_placeholder')}
              isRequired
            />
          </form>
        </ModalBody>

        <ModalFooter>
          <Button colorScheme="blue" mr={3} onClick={keepLookingAndClose}>
          {t('keep_looking')}
          </Button>
          <Button colorScheme="green" type="submit" form="ask-form">
            {t('submit')}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
