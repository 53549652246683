import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Stack,
} from '@chakra-ui/react'
import React, { useEffect } from 'react'

import { useGetCoachBioMutation } from '../../app/services/api'
import { useLocale } from '../../hooks/useLocale'
import CoachBio from './CoachBio'

// Welcome popup for users who logged in for the first time
const WelcomePopup = (props: any) => {
  const { isOpen, onClose, user } = props
  const { t } = useLocale()
  const [getCoachBio, { data: coachBio }] = useGetCoachBioMutation()

  useEffect(() => {
    const loadCoachBio = async () => {
      await getCoachBio()
    }
    if (user) {
      loadCoachBio()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleCloseWelcome = () => {
    onClose()
  }

  if (!coachBio) {
    return null
  }
  console.log('Showing coachBio', coachBio)
  console.log('isOpen', isOpen)

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      isCentered
      size="2xl"
      scrollBehavior={'inside'}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader textAlign={'center'} mb={0} pb={0}>
          {t('coach_bio_title')}
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Stack>
            <CoachBio user={user} />
            <Button
              fontWeight="bold"
              onClick={handleCloseWelcome}
              fontSize="lg"
              colorScheme="blue"
              size="lg"
            >
              {t('start_exploring')}
            </Button>
          </Stack>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

export default WelcomePopup
