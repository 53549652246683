import { TriangleDownIcon, TriangleUpIcon } from '@chakra-ui/icons'
import {
  Box,
  Center,
  chakra,
  Flex,
  IconButton,
  Link,
  Spinner,
  Stack,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react'
import { useEffect, useMemo } from 'react'
import { BsChatDots } from 'react-icons/bs'
import { MdCall } from 'react-icons/md'
import { NavLink } from 'react-router-dom'
import {
  useFlexLayout,
  useGlobalFilter,
  useSortBy,
  useTable,
} from 'react-table'

import { useGetCustomersMutation } from './app/services/api'
import { parseEventDate, parseEventTime } from './utils/date'

const DataTable = ({ user, customers }: any) => {
  console.log('customers', customers)
  const data = useMemo(() => customers, [customers])

  const columns = useMemo(
    () => [
      {
        Header: 'Basic Info',
        accessor: (d: any) => (
          <Flex>
            <NavLink to={`/coach/customer/${d._id}`}>
              <Box>
                <Text fontWeight="bold">{d.profile.name}</Text>
                <Text fontSize={{ base: '11px', md: '12px', lg: '13px' }}>
                  {d.email}
                </Text>
              </Box>
            </NavLink>
          </Flex>
        ),
        maxWidth: 80,
      },
      {
        Header: 'Company',
        accessor: 'company.name',
        maxWidth: 70,
      },
      {
        Header: 'Last call',
        accessor: 'lastEvent',
        Cell: (props: any) =>
          props.value ? (
            <Stack spacing={0}>
              <Flex alignItems="center">
                {parseEventDate(user, props.value.start, props.value.end)},{' '}
                {parseEventTime(
                  user,
                  props.value.start,
                  props.value.end,
                  false
                )}
              </Flex>
            </Stack>
          ) : (
            <></>
          ),
        maxWidth: 90,
      },
      {
        Header: 'Next call',
        accessor: 'nextEvent',
        Cell: (props: any) =>
          props.value ? (
            <Stack spacing={0}>
              <Flex alignItems="center">
                {parseEventDate(user, props.value.start, props.value.end)},{' '}
                {parseEventTime(
                  user,
                  props.value.start,
                  props.value.end,
                  false
                )}
              </Flex>
            </Stack>
          ) : (
            <></>
          ),
        maxWidth: 90,
      },
      {
        Header: 'Actions',
        accessor: (d: any) => (
          <Box>
            <NavLink to={`/coach/chat/${d._id}`}>
              <IconButton
                colorScheme="gray"
                aria-label="Chat"
                size="lg"
                icon={<BsChatDots />}
                mr={2}
              />
            </NavLink>
            <Link
              isExternal={true}
              href={
                d.nextEvent &&
                (d.nextEvent.teamsMeetingLink || d.nextEvent.zoomLink || d.nextEvent.meetLink)
              }
              target="_blank"
            >
              <IconButton
                colorScheme="teal"
                aria-label="Call"
                size="lg"
                icon={<MdCall />}
                isDisabled={!d.nextEvent}
              />
            </Link>
          </Box>
        ),
        maxWidth: 60,
      },
    ],
    [user]
  )
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({ columns, data }, useGlobalFilter, useFlexLayout, useSortBy)

  return (
    <Table {...getTableProps()}>
      <Thead>
        {headerGroups.map((headerGroup) => (
          <Tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column: any) => (
              <Th
                {...column.getHeaderProps(column.getSortByToggleProps())}
                isNumeric={column.isNumeric}
              >
                {column.render('Header')}
                <chakra.span pl="4">
                  {column.isSorted ? (
                    column.isSortedDesc ? (
                      <TriangleDownIcon aria-label="sorted descending" />
                    ) : (
                      <TriangleUpIcon aria-label="sorted ascending" />
                    )
                  ) : null}
                </chakra.span>
              </Th>
            ))}
          </Tr>
        ))}
      </Thead>
      <Tbody {...getTableBodyProps()}>
        {rows.map((row: any) => {
          prepareRow(row)
          return (
            <Tr
              {...row.getRowProps()}
              _hover={{
                background: 'white',
                color: 'blue.400',
              }}
            >
              {row.cells.map((cell: any) => (
                <Td {...cell.getCellProps()} isNumeric={cell.column.isNumeric}>
                  {cell.render('Cell')}
                </Td>
              ))}
            </Tr>
          )
        })}
      </Tbody>
    </Table>
  )
}

const Customers = ({ coach }: any) => {
  const [
    getCustomers,
    {
      data: customers,
      error: getCustomersError,
      isLoading: getCustomersLoading,
    },
  ] = useGetCustomersMutation()

  useEffect(() => {
    const loadCustomers = async () => {
      await getCustomers()
    }
    loadCustomers()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Box p={1}>
      <Text fontFamily={'heading'} fontWeight={700} mb={5} fontSize={'xl'}>
        Users
      </Text>
      <Flex w={'full'} bg={'white'} boxShadow={'2xl'} rounded={'lg'} p={2}>
        {getCustomersError ? (
          <Center>
            <Text color={'red.500'} fontSize={'lg'}>
              Error while getting customers.
            </Text>
          </Center>
        ) : getCustomersLoading || !customers ? (
          <Center>
            <Spinner />
          </Center>
        ) : (
          <DataTable user={coach} customers={customers} />
        )}
      </Flex>
    </Box>
  )
}

export default Customers
