import {
  Box,
  BoxProps,
  Button,
  CloseButton,
  Drawer,
  DrawerContent,
  Flex,
  FlexProps,
  HStack,
  Icon,
  IconButton,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  Text,
  useColorModeValue,
  useDisclosure,
  VStack,
} from '@chakra-ui/react'
import Cookies from 'js-cookie'
import { ReactNode, ReactText, useContext, useEffect, useState } from 'react'
import { IconType } from 'react-icons'
import { AiOutlineSchedule } from 'react-icons/ai'
import { BiPhoneCall } from 'react-icons/bi'
import { FiChevronDown, FiHome, FiMenu, FiUser } from 'react-icons/fi'
import { MdChat, MdEventNote } from 'react-icons/md'
import { useDispatch } from 'react-redux'
import { Link, NavLink, useHistory } from 'react-router-dom'

import './Home.css'
import { useGetEventMutation } from './app/services/api'
import { useLogout } from './hooks/useLogout'
import Call from './features/call/Call'
import WelcomePopup from './features/profile/WelcomePopup'
import { SocketContext } from './hooks/socket'
import { useLocale } from './hooks/useLocale'
import { Logo } from './utils/Logo'
import { useMixpanel } from './utils/MixpanelContext'
import { User } from './types/api'
import { useFlagsmith } from 'flagsmith/react'

interface LinkItemProps {
  name: string
  icon: IconType
  href: string
}
const LinkItems: Array<LinkItemProps> = [
  { name: 'home', icon: FiHome, href: '/' },
  { name: 'chat', icon: MdChat, href: '/chat' },
  { name: 'make_appointment', icon: BiPhoneCall, href: '/book' },
  { name: 'appointments', icon: AiOutlineSchedule, href: '/bookings' },
  {
    name: 'notes',
    icon: MdEventNote,
    href: '/notes',
  },
]

export default function Home({
  user,
  children,
}: {
  user: User | null
  children: ReactNode
}) {
  const { t } = useLocale()
  const { isOpen, onOpen, onClose } = useDisclosure()
  const {
    isOpen: isCallOpen,
    onOpen: onCallOpen,
    onClose: onCallClose,
  } = useDisclosure()
  const {
    isOpen: isWelcomeOpen,
    onOpen: onWelcomeOpen,
    onClose: onWelcomeClose,
  } = useDisclosure()
  const flagsmith = useFlagsmith()
  const mixpanel = useMixpanel()

  const [canUseService] = useState(true)
  const [event, setEvent] = useState<any>(null)
  const [getEvent] = useGetEventMutation()

  const socket: any = useContext(SocketContext)

  const history = useHistory()

  useEffect(() => {
    socket.emit('call:join', { userId: user?.id, channelName: user?.id })
    console.log('call:join, ', { userId: user?.id, channelName: user?.id })
  }, [user, socket])

  useEffect(() => {
    const onRequestCall = async ({ coachId, userId, eventId }: any) => {
      console.log(`${coachId} is calling ${userId} regarding event: ${eventId}`)
      const response: any = await getEvent({ eventId })
      if (response && response.data) {
        setEvent(response.data)
        onCallOpen()
      }
    }
    socket.on('onRequestCall', onRequestCall)

    return () => {
      socket.removeAllListeners()
    }
  }, [socket, getEvent, onCallOpen])

  useEffect(() => {
    if (!user) return
    // @ts-ignore
    const userId: string = user?._id || user?.id

    // Set Mixpanel identifiers if not set
    if (!mixpanel.get_property('company')) {
      mixpanel.identify(userId)
      mixpanel.people.set('email', user.email)
      mixpanel.set_group('company', user.company?.name || 'Unknown')
      // @ts-ignore
      mixpanel.add_group('coach', user.coach.email)
    }

    // Identify user in Flagsmith
    flagsmith.identify(userId)
    flagsmith.setTrait('email', user.email)
    flagsmith.setTrait('company', user.company?.name)
    flagsmith.setTrait('companyId', user.company?._id ?? user.company)
    // @ts-ignore
    flagsmith.setTrait('coach', user.coach.email)

    // Check if user has limits and show warning popup if they do
    if (!Cookies.get('welcomePopup')) {
      console.log('Show welcomePopup')
      onWelcomeOpen()
      Cookies.set('welcomePopup', 'true', { expires: 1 })
    }

    // Identify user in Chatlio
    // @ts-ignore
    if (window._chatlio) {
      // @ts-ignore
      window._chatlio.identify(userId, {
        name: user.profile.name,
        email: user.email,
        company: user.company?.name || 'Unknown',
        // @ts-ignore
        coach: user.coach?.email,
      })
    }
  }, [flagsmith, mixpanel, user, onWelcomeOpen])

  if (!canUseService) {
    return (
      <VStack
        align="center"
        justify="center"
        textAlign={'center'}
        height="100vh"
      >
        <Logo mb={5} />
        <Text fontSize="xl" fontWeight="bold">
          {t('home.cantUseService')}
        </Text>
        <Text fontSize="lg" fontWeight="bold">
          {t('home.cantUseServiceDescription')}
        </Text>
      </VStack>
    )
  }

  return (
    <Box minH="100vh" bg={'gray.100'}>
      <SidebarContent
        t={t}
        onClose={() => onClose}
        display={{ base: 'none', md: 'block' }}
      />
      <Drawer
        autoFocus={false}
        isOpen={isOpen}
        placement="left"
        onClose={onClose}
        returnFocusOnClose={false}
        onOverlayClick={onClose}
        size="full"
      >
        <DrawerContent>
          <SidebarContent t={t} onClose={onClose} />
        </DrawerContent>
      </Drawer>
      {/* mobilenav */}
      <MobileNav
        t={t}
        onOpen={onOpen}
        user={user}
        history={history}
        socket={socket}
      />
      <Box ml={{ base: 0, md: 60 }} p="4">
        {children}
        <Call
          isOpen={isCallOpen}
          onClose={onCallClose}
          event={event}
          user={user}
          coach={null}
        />
        <WelcomePopup
          isOpen={isWelcomeOpen}
          onClose={onWelcomeClose}
          user={user}
        />
      </Box>
    </Box>
  )
}

interface SidebarProps extends BoxProps {
  onClose: () => void
  t: any
}

const SidebarContent = ({ onClose, t, ...rest }: SidebarProps) => {
  return (
    <Box
      transition="3s ease"
      bg={useColorModeValue('white', 'gray.900')}
      borderRight="1px"
      borderRightColor={useColorModeValue('gray.200', 'gray.700')}
      w={{ base: 'full', md: 60 }}
      pos="fixed"
      h="full"
      {...rest}
    >
      <Flex
        h="20"
        alignItems="center"
        justify={{ base: 'space-between', md: 'center' }}
        mx="8"
      >
        <Logo />
        <CloseButton display={{ base: 'flex', md: 'none' }} onClick={onClose} />
      </Flex>
      {LinkItems.map((link) => (
        <NavItem
          href={link.href}
          key={link.name}
          icon={link.icon}
          onClick={onClose}
        >
          {t(link.name)}
        </NavItem>
      ))}
    </Box>
  )
}

interface NavItemProps extends FlexProps {
  icon: IconType
  href: string
  children: ReactText
}
const NavItem = ({ icon, href, children, ...rest }: NavItemProps) => {
  return (
    <NavLink exact activeClassName="active" to={href}>
      <Flex
        align="center"
        p="4"
        mx="4"
        borderRadius="lg"
        role="group"
        cursor="pointer"
        _hover={{
          bg: 'primary.400',
          color: 'white',
        }}
        {...rest}
      >
        {icon && (
          <Icon
            mr="4"
            fontSize="16"
            _groupHover={{
              color: 'white',
            }}
            as={icon}
          />
        )}
        {children}
      </Flex>
    </NavLink>
  )
}

interface MobileProps extends FlexProps {
  onOpen: () => void
  user: User | null
  history: any
  socket: any
  t: any
}
const MobileNav = ({
  onOpen,
  user,
  history,
  socket,
  t,
  ...rest
}: MobileProps) => {
  const dispatch = useDispatch()
  const mixpanel = useMixpanel()
  const flagsmith = useFlagsmith()
  const onLogout = useLogout()

  return (
    <Flex
      ml={{ base: 0, md: 60 }}
      px={{ base: 4, md: 4 }}
      height="20"
      alignItems="center"
      bg={useColorModeValue('white', 'gray.900')}
      borderBottomWidth="1px"
      borderBottomColor={useColorModeValue('gray.200', 'gray.700')}
      justifyContent={{ base: 'space-between', md: 'flex-end' }}
      {...rest}
    >
      <IconButton
        display={{ base: 'flex', md: 'none' }}
        onClick={onOpen}
        variant="outline"
        aria-label="open menu"
        icon={<FiMenu />}
      />
      <Logo display={{ base: 'flex', md: 'none' }} />

      <HStack spacing={{ base: '0', md: '6' }}>
        <Flex alignItems={'center'}>
          <HStack>
            <NavLink exact activeClassName="active" to={'/coaching-guide'}>
              <Button me={4} variant="outline" colorScheme={'primary'}>
                {t('coaching_guide')}
              </Button>
            </NavLink>
            <Menu>
              <MenuButton
                py={2}
                transition="all 0.3s"
                _focus={{ boxShadow: 'none' }}
              >
                <HStack>
                  <Icon as={FiUser} fontSize={{ base: '32', md: '20' }} />
                  <VStack
                    display={{ base: 'none', md: 'flex' }}
                    alignItems="flex-start"
                    spacing="1px"
                    ml="2"
                  >
                    <Text fontSize="sm">{user?.profile?.name}</Text>
                    {user?.role === 'admin' ? (
                      <Text fontSize="xs" color="gray.600">
                        {t('admin')}
                      </Text>
                    ) : (
                      <Text fontSize="xs" color="gray.600">
                        {user?.company?.name}
                      </Text>
                    )}
                  </VStack>
                  <Box display={{ base: 'none', md: 'flex' }}>
                    <FiChevronDown />
                  </Box>
                </HStack>
              </MenuButton>
              <MenuList
                bg={useColorModeValue('white', 'gray.900')}
                borderColor={useColorModeValue('gray.200', 'gray.700')}
              >
                <a href="/profile">
                  <MenuItem>{t('profile')}</MenuItem>
                </a>
                <Link to="/change-coach">
                  <MenuItem>{t('change_coach')}</MenuItem>
                </Link>
                <a
                  href="https://hupo.co/care/user-agreement"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <MenuItem>{t('user_agreement')}</MenuItem>
                </a>
                <Link to="/faq">
                  <MenuItem>{t('faq')}</MenuItem>
                </Link>
                <MenuDivider />
                <MenuItem onClick={onLogout}>{t('sign_out')}</MenuItem>
              </MenuList>
            </Menu>
          </HStack>
        </Flex>
      </HStack>
    </Flex>
  )
}
