import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  useToast,
} from '@chakra-ui/react'
import React, { useEffect, useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'

import { DEFAULT_SCHEDULE } from '../../app/constants'
import {
  useGetScheduleMutation,
  usePostScheduleMutation,
} from '../../app/services/api'
import { useLocale } from '../../hooks/useLocale'
import { Coach, GetScheduleResponse } from '../../types/api'
import { Form } from '../../utils/fields'
import { CalendarConnection } from '../calendars'
import Schedule from './Schedule'
import SliderInput from './SliderInput'

interface Props {
  coach: Coach | null
}

const AvailabilityForm = ({ schedule, maxHoursPerWeek }: any) => {
  const { t } = useLocale()
  const [postSchedule] = usePostScheduleMutation()
  const toast = useToast()

  const form = useForm({
    defaultValues: {
      schedule,
      maxHoursPerWeek,
    },
  })
  const onSubmit = async (values: any) => {
    await postSchedule(values).unwrap
    toast({
      status: 'success',
      title: 'Success',
      description: t('working_hours_updated'),
      isClosable: true,
    })
  }

  return (
    <Form form={form} handleSubmit={onSubmit}>
      <Box>
        <SliderInput
          name="maxHours"
          label={t('max_working_hours_per_week')}
          min={0}
          max={100}
          step={1}
          defaultValue={maxHoursPerWeek ?? 10}
          onChange={(value: number) => {
            form.setValue('maxHoursPerWeek', value)
          }}
        />
        <Box mt={10} />
        <FormControl>
          <FormLabel htmlFor="schedule" textAlign="center">
            {t('change_start_end_time')}
          </FormLabel>
          <Schedule name="schedule" />
        </FormControl>
      </Box>
      <Box textAlign="center">
        <Button
          mt={4}
          colorScheme="teal"
          isLoading={form.formState.isSubmitting}
          type="submit"
        >
          {t('save')}
        </Button>
      </Box>
    </Form>
  )
}

const CoachSettings = ({ coach }: Props) => {
  const { t } = useLocale()
  const [schedule, setSchedule] = useState<any>(null)
  const [maxHoursPerWeek, setMaxHoursPerWeek] = useState<any>(0)

  const tab = useMemo(() => {
    const urlParams = new URLSearchParams(window.location.search)
    const tabNo = parseInt(urlParams.get('tab') || '0')
    if (Number.isNaN(tabNo)) return 0
    return tabNo
  }, [])

  const [getSchedule] = useGetScheduleMutation()

  useEffect(() => {
    const setOutputs = (data: GetScheduleResponse) => {
      setSchedule(data.schedule)
      setMaxHoursPerWeek(data.maxHoursPerWeek)
    }

    const loadSchedule = async () => {
      try {
        const resp: any = await getSchedule({})
        resp && resp?.data
          ? setOutputs(resp.data)
          : setSchedule(DEFAULT_SCHEDULE)
      } catch (err) {
        console.log(err)
        setSchedule(DEFAULT_SCHEDULE)
      }
    }
    if (coach) loadSchedule()
  }, [getSchedule, coach])

  if (!schedule || !maxHoursPerWeek) return null

  return (
    <Flex w="full" flexDirection="column" bg={'white'}>
      <Flex px={6} overflowY="auto" flexDirection="column" flex={1}>
        <Tabs
          defaultIndex={tab}
          mt={8}
          minH="100vh"
          isFitted
          variant="enclosed"
        >
          <TabList mb="1em">
            <Tab>{t('working_hours')}</Tab>
            <Tab>{t('calendar_connections')}</Tab>
          </TabList>
          <TabPanels>
            <TabPanel>
              <AvailabilityForm
                schedule={schedule}
                maxHoursPerWeek={maxHoursPerWeek}
              />
            </TabPanel>
            <TabPanel>
              <CalendarConnection />
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Flex>
    </Flex>
  )
}

export default CoachSettings
