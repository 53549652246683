import { createSlice } from '@reduxjs/toolkit'

interface SurveyState {
  questions: any
  language: string
  surveyData: any
}

const initialState: SurveyState = {
  questions: {},
  surveyData: {},
  language: '',
}

const slice = createSlice({
  name: 'feedback/survey',
  initialState: initialState,
  reducers: {
    itemSelect: (state, { payload }) => {
      state.questions[payload.questionId] = { answers: payload.answers || [] }

      if (payload.other) {
        state.questions[payload.questionId].other = payload.answer
      }

      console.log('itemSelect(after) state:', state)
    },
    setPreSurveyData: (state, { payload }) => {
      state.language = payload.language
      console.log('setPreSurveyData(after) state:', state)
    },
    setSurveyData: (state, { payload }) => {
      state.surveyData = payload
      console.log("setSurveyData() state:", state)
      console.log("setSurveyData() payload:", payload)
      state.questions = payload.questions.reduce((acc: any, question: any) => {
        acc[question._id] = question.answers
        return acc
      }, {})
      console.log('setSurveyQuestions() state:', state)
    },
    clearSurveyData: (state) => {
      Object.assign(state, initialState)
    },
  },
})

export default slice.reducer

export const { itemSelect, setPreSurveyData, clearSurveyData, setSurveyData } =
  slice.actions
