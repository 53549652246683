import {createSlice} from '@reduxjs/toolkit';

const initialState = {
  socketId: undefined,
};

const slice = createSlice({
  name: 'sockets',
  initialState: initialState,
  reducers: {
    updateSocket: (state, {payload}) => {
      console.log('updateSocket reducer', payload);
      state.socketId = payload;
    },
  },
});

export default slice.reducer;

export const {updateSocket} = slice.actions;
