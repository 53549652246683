import {
  Box,
  Button,
  Center,
  Flex,
  Heading,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Spinner,
  Stack,
  Text,
  useDisclosure,
  useToast,
} from '@chakra-ui/react'
import { Step, Steps, useSteps } from 'chakra-ui-steps'
import addMinutes from 'date-fns/addMinutes'
import moment from 'moment-timezone'
import {
  MutableRefObject,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react'
import { AiOutlineClockCircle } from 'react-icons/ai'
import { FiCheckCircle } from 'react-icons/fi'
import { MdDateRange } from 'react-icons/md'
import { useHistory } from 'react-router-dom'

import {
  BookingFlow,
  DURATION_LABEL_MAP,
  DURATION_OPTIONS,
} from '../../app/constants'
import { ErrorResponse } from '../../types/error'
import {
  useCreateEventMutation,
  useLogUserMutation,
  usePostBookingCoachRequestMutation,
  useUpdateEventMutation,
} from '../../app/services/api'

import { useLocale } from '../../hooks/useLocale'
import { useMixpanel } from '../../utils/MixpanelContext'
import Signup from '../auth/Signup'
import Datetime from './Datetime'
import Duration from './Duration'
import { FlowA } from './FlowA'
import { useFlags } from 'flagsmith/react'

type Flow = 'flowA' | 'flowB' | 'flowC' | 'flowD'

export const Book = ({
  user,
  coach,
  invitation = null,
  event = null,
  onEventUpdate = null,
}: any) => {
  const { t } = useLocale()
  const { nextStep, reset, activeStep, setStep } = useSteps({
    initialStep: 0,
  })
  const { booking_flow: bookingFlowExperiment } = useFlags(['booking_flow'])

  const [bookingSuccess, setBookingSuccess] = useState(false)
  const [attempts, setAttempts] = useState(0)
  const attemptsRef = useRef(attempts)
  const [isExtraSlotSelected, setIsExtraSlotSelected] = useState(false)
  const [mergeAllCoachSlots, setMergeAllCoachSlots] = useState(true)
  const [anotherCoachSlot, setAnotherCoachSlot] = useState<any>(null)

  const [duration, setDuration] = useState<number>(30)
  const [confirming, setConfirming] = useState<boolean>(false)
  const [date, setDate] = useState<Date | null>(null)
  const [availableDatesCount, setAvailableDatesCount] = useState<number | null>(
    null
  )
  const [slotsCount, setSlotsCount] = useState<Number>(0)
  const [time, setTime] = useState<Date | null>(null)
  const history = useHistory()
  const toast = useToast()
  const { isOpen, onOpen, onClose } = useDisclosure()
  const mixpanel = useMixpanel()

  const initialRef = useRef() as MutableRefObject<HTMLDivElement>
  const finalRef = useRef() as MutableRefObject<HTMLDivElement>

  const isPublic = invitation && !user
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone
  const language = invitation ? invitation.language : user.language

  const [createEvent] = useCreateEventMutation()
  const [updateEvent] = useUpdateEventMutation()
  const [postBookingCoachRequest] = usePostBookingCoachRequestMutation()
  const [logUser] = useLogUserMutation()

  useEffect(() => {
    console.log('bookingFlowExperiment', bookingFlowExperiment)
    mixpanel.track(event ? 'appointment_update' : 'appointment_create', {
      bookingExperimentEnabled: bookingFlowExperiment.enabled,
      bookingFlow: bookingFlowExperiment.value,
    })

    // Booking component unmounted
    return () => {
      const attempts = attemptsRef.current
      // only log if booking was not successful
      if (!bookingSuccess && attempts > 0) {
        mixpanel.track(
          event ? 'appointment_update_exit' : 'appointment_create_exit',
          {
            bookingExperimentEnabled: bookingFlowExperiment.enabled,
            bookingFlow: bookingFlowExperiment.value,
            attempts,
          }
        )

        logUser({
          action: 'event_create_attempted',
          payload: { attempts },
        })
          .unwrap()
          .catch(() => {})
      }
    }
  }, [mixpanel, bookingFlowExperiment, event, bookingSuccess, logUser])

  useEffect(() => {
    attemptsRef.current = attempts
  }, [attempts])

  let content = null

  const flow = bookingFlowExperiment.enabled
    ? (bookingFlowExperiment.value as Flow)
    : null
  switch (flow) {
    case BookingFlow.FlowA:
      content = (
        <FlowA
          coach={coach}
          user={user}
          duration={duration}
          attempts={attempts}
          setAttempts={setAttempts}
          onNext={nextStep}
          setTime={setTime}
          setIsExtraSlotSelected={setIsExtraSlotSelected}
          date={date}
          setDate={setDate}
          language={language}
          timezone={timezone}
        />
      )
      break
    case BookingFlow.FlowB:
      content = (
        <Datetime
          user={user}
          coach={coach}
          duration={duration}
          date={date}
          event={event}
          setDate={setDate}
          setAvailableDatesCount={setAvailableDatesCount}
          setSlotsCount={setSlotsCount}
          setTime={setTime}
          onNext={nextStep}
          attempts={attempts}
          setAttempts={setAttempts}
          timezone={timezone}
          language={language}
          isAskPopUpEnabled={false}
          flowId="flowB"
          mergeAllCoachSlots={mergeAllCoachSlots}
          setMergeAllCoachSlots={setMergeAllCoachSlots}
          anotherCoachSlot={anotherCoachSlot}
          setAnotherCoachSlot={setAnotherCoachSlot}
          isSearchThroughAllCoachesEnabled
        />
      )
      break
    case BookingFlow.FlowC:
      content = (
        <Datetime
          user={user}
          coach={coach}
          duration={duration}
          date={date}
          event={event}
          setDate={setDate}
          setAvailableDatesCount={setAvailableDatesCount}
          setSlotsCount={setSlotsCount}
          setTime={setTime}
          onNext={nextStep}
          attempts={attempts}
          setAttempts={setAttempts}
          timezone={timezone}
          language={language}
          flowId="flowC"
          isAskPopUpEnabled
        />
      )
      break
    case BookingFlow.FlowD:
      content = (
        <Datetime
          user={user}
          coach={coach}
          duration={duration}
          date={date}
          event={event}
          setDate={setDate}
          setAvailableDatesCount={setAvailableDatesCount}
          setSlotsCount={setSlotsCount}
          setTime={setTime}
          onNext={nextStep}
          attempts={attempts}
          setAttempts={setAttempts}
          timezone={timezone}
          language={language}
          flowId="flowD"
          isAskPopUpEnabled
          mergeAllCoachSlots={mergeAllCoachSlots}
          setMergeAllCoachSlots={setMergeAllCoachSlots}
          anotherCoachSlot={anotherCoachSlot}
          setAnotherCoachSlot={setAnotherCoachSlot}
          isSearchThroughAllCoachesEnabled
        />
      )
      break
    default:
      content = (
        <Datetime
          user={user}
          coach={coach}
          duration={duration}
          date={date}
          event={event}
          setDate={setDate}
          setAvailableDatesCount={setAvailableDatesCount}
          setSlotsCount={setSlotsCount}
          setTime={setTime}
          onNext={nextStep}
          attempts={attempts}
          setAttempts={setAttempts}
          timezone={timezone}
          language={language}
          isAskPopUpEnabled={false}
        />
      )
      break
  }

  const steps = [
    {
      label: t('choose_duration'),
      content: (
        <Duration
          options={DURATION_OPTIONS}
          setDuration={setDuration}
          onNext={nextStep}
        />
      ),
    },
    {
      label: t('choose_date_time'),
      content,
    },
  ]

  useEffect(() => {
    if (duration && availableDatesCount) {
      mixpanel.track(
        event
          ? 'appointment_update_duration_select'
          : 'appointment_create_duration_select',
        {
          duration,
          availableDatesCount,
          bookingExperimentEnabled: bookingFlowExperiment.enabled,
          bookingFlow: bookingFlowExperiment.value,
        }
      )
    }
  }, [duration, availableDatesCount, mixpanel, event, bookingFlowExperiment])

  useEffect(() => {
    if (date && slotsCount) {
      console.log('date', date)
      mixpanel.track(
        event
          ? 'appointment_update_date_select'
          : 'appointment_create_date_select',
        {
          selectedDate: moment(date).format('YYYY-MM-DD'),
          slotsCount,
          bookingExperimentEnabled: bookingFlowExperiment.enabled,
          bookingFlow: bookingFlowExperiment.value,
        }
      )
    }
  }, [date, mixpanel, event, slotsCount, bookingFlowExperiment])

  const onConfirm = useCallback(async () => {
    if (isExtraSlotSelected) {
      const params = {
        coachId: coach._id,
        start: time,
        end: addMinutes(
          Date.parse(
            typeof time === 'object' ? time?.toISOString() || '' : time
          ),
          duration
        ),
      }
      try {
        await postBookingCoachRequest(params).unwrap()
        mixpanel.track('appointment_booking_coach_request_success', {
          bookingExperimentEnabled: bookingFlowExperiment.enabled,
          bookingFlow: bookingFlowExperiment.value,
        })
        toast({
          title: t('appointment_booking_coach_request_title'),
          description: t('appointment_booking_coach_request_description'),
          status: 'success',
          duration: 5000,
          isClosable: true,
        })

        setBookingSuccess(true)
        history.push('/bookings')
      } catch (error) {
        mixpanel.track('appointment_booking_coach_request_fail')
      }
      return
    }
    try {
      const newEvent = {
        eventId: event?.id,
        userId: coach._id,
        start: time as Date,
        end: addMinutes(Date.parse(time as any), duration),
        experimentEnabled: bookingFlowExperiment.enabled,
        experimentFlowId: bookingFlowExperiment.value,
      } as any
      if (
        mergeAllCoachSlots &&
        anotherCoachSlot &&
        typeof anotherCoachSlot === 'object'
      ) {
        newEvent['coachId'] = anotherCoachSlot._id
      }
      setConfirming(true)
      if (event) {
        await updateEvent(newEvent).unwrap()
        onEventUpdate()
        mixpanel.track('appointment_update_success', {
          bookingExperimentEnabled: bookingFlowExperiment.enabled,
          bookingFlow: bookingFlowExperiment.value,
        })
      } else {
        await createEvent(newEvent).unwrap()
        setBookingSuccess(true)

        if (mergeAllCoachSlots && typeof anotherCoachSlot === 'object') {
          // hard refresh to fetch all updated data
          window.location.href = '/bookings'
        } else {
          history.push('/bookings')
        }
        mixpanel.track('appointment_create_success', {
          attempts,
          bookingExperimentEnabled: bookingFlowExperiment.enabled,
          bookingFlow: bookingFlowExperiment.value,
        })
      }
      setConfirming(false)
    } catch (err) {
      console.log(err)
      const errorResponse = err as ErrorResponse
      toast({
        status: 'error',
        title: t('error'),
        description: errorResponse?.data?.message ?? t('there_was_an_error'),
        isClosable: true,
      })
      mixpanel.track(
        event ? 'appointment_update_fail' : 'appointment_create_fail',
        { attempts }
      )
    }
  }, [
    time,
    duration,
    coach,
    event,
    createEvent,
    updateEvent,
    history,
    toast,
    onEventUpdate,
    t,
    mixpanel,
    attempts,
    isExtraSlotSelected,
    postBookingCoachRequest,
    anotherCoachSlot,
    mergeAllCoachSlots,
    bookingFlowExperiment,
  ])

  const onModalClose = useCallback(async () => {
    console.log('onModalClose')
    await onClose()
    onConfirm()
  }, [onClose, onConfirm])

  return (
    <Flex flexDir="column" width="100%">
      <Steps
        onClickStep={(step: any) => setStep(step)}
        orientation="vertical"
        checkIcon={FiCheckCircle}
        activeStep={activeStep}
      >
        {steps.map(({ label, content }, index) => (
          <Step label={label} key={label}>
            {content}
          </Step>
        ))}
      </Steps>
      {activeStep === steps.length ? (
        <Center py={6}>
          <Box
            maxW={'360px'}
            w={'full'}
            bg="white"
            boxShadow={'2xl'}
            rounded={'lg'}
            p={6}
            textAlign={'center'}
          >
            <Box textAlign={'center'}>
              <Heading size="md" fontWeight={600} mb={2}>
                {t('duration_call_with_person', {
                  duration: t(DURATION_LABEL_MAP[duration]),
                  coachName:
                    anotherCoachSlot?.profile?.name ?? coach.profile.name,
                })}
                {isExtraSlotSelected && (
                  <Text fontSize="sm" color="gray.500">
                    {t('pending_coach_confirmation')}
                  </Text>
                )}
                {anotherCoachSlot && (
                  <Text fontSize="sm" color="primary.600" mt={2}>
                    {t('another_coach_switch_note', {
                      coachName: anotherCoachSlot.profile.name,
                    })}
                  </Text>
                )}
              </Heading>
              <Stack direction={'row'} justify={'center'} spacing={0} my={6}>
                <Stack spacing={0} align={'center'}>
                  <Flex alignItems="center">
                    <Text fontSize="23px">
                      <MdDateRange />
                    </Text>
                    <Text fontSize="md" pl={2} mr={5}>
                      {moment(date).format('DD MMM')}
                    </Text>
                    <Text fontSize="23px">
                      <AiOutlineClockCircle />
                    </Text>
                    <Text fontSize="md" pl={2}>
                      {moment(time)
                        .tz(user?.timezone ?? timezone)
                        .format('HH:mm ([GMT]Z)')}
                    </Text>
                  </Flex>
                </Stack>
              </Stack>
            </Box>
            <Stack spacing={6} direction={['column', 'row']}>
              <Button
                bg={'red.400'}
                color={'white'}
                onClick={reset}
                w="full"
                _hover={{
                  bg: 'red.500',
                }}
                disabled={confirming}
              >
                {t('reset')}
              </Button>
              <Button
                bg={'blue.400'}
                color={'white'}
                onClick={isPublic ? onOpen : onConfirm}
                w="full"
                _hover={{
                  bg: 'blue.500',
                }}
                disabled={confirming}
              >
                {confirming ? <Spinner /> : t('confirm')}
              </Button>
            </Stack>
          </Box>
        </Center>
      ) : (
        <Flex width="100%" justify="flex-end"></Flex>
      )}
      <Modal
        initialFocusRef={initialRef}
        finalFocusRef={finalRef}
        isOpen={isOpen}
        onClose={onClose}
        size="lg"
      >
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton />
          <ModalBody pb={6}>
            <Signup
              invitation={invitation}
              isModal={true}
              onModalClose={onModalClose}
            />
          </ModalBody>
        </ModalContent>
      </Modal>
    </Flex>
  )
}

export default Book
