import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

import { Message } from '../../features/chat/chatSlice'
import { Event } from '../../features/scheduler/schedulerSlice'
import {
  AcceptEventRequest,
  AcceptEventResponse,
  Coach,
  CoachLoginRequest,
  CoachProfile,
  CoachResponse,
  Company,
  ConnectionUser,
  CreateBlockEventRequest,
  CreateEventRequest,
  DeleteBlockEventRequest,
  DeleteEventRequest,
  DeleteEventResponse,
  DeleteMessageResponse,
  EventsWithNotes,
  GetAvailabilityRequest,
  GetAvailabilityResponse,
  GetCoachBioResponse,
  GetCompaniesResponse,
  GetCompanyRequest,
  GetEventRequest,
  GetExcludedDatesRequest,
  GetExcludedDatesResponse,
  GetInvitationRequest,
  GetRTCTokenRequest,
  GetRTCTokenResponse,
  GetScheduleRequest,
  GetScheduleResponse,
  GetUnifiedLinkRequest,
  GetUserResponse,
  GetUsersRequest,
  GetUsersResponse,
  Invitation,
  ListResponse,
  LoginRequest,
  MessagesRequest,
  Note,
  PasswordForgotRequest,
  PasswordForgotResponse,
  PasswordResetRequest,
  PasswordResetResponse,
  PostNoteRequest,
  PostProfileRequest,
  PostReviewRequest,
  PostScheduleRequest,
  PostSessionNoteRequest,
  PostSessionUserFeedbackRequest,
  PutCoachProfileRequest,
  SessionNote,
  SignupRequest,
  UpdateEventRequest,
  UpdateNoteRequest,
  UpdateSessionNoteRequest,
  User,
  UserNote,
  UserNoteResponse,
  UserResponse,
} from '../../types/api'
import { API_ENDPOINT } from '../constants'
import { RootState } from '../store'

export const api = createApi({
  tagTypes: [
    'coach',
    'coachBlockEvents',
    'coachEvents',
    'coachProfile',
    'messages',
    'sessionNotes',
    'userNotes',
  ],
  refetchOnMountOrArgChange: true,
  baseQuery: fetchBaseQuery({
    baseUrl: API_ENDPOINT,
    prepareHeaders: (headers, { getState }) => {
      // By default, if we have a token in the store, let's use that for authenticated requests
      const token = (getState() as RootState).auth.token
      if (token) {
        headers.set('authorization', `Bearer ${token}`)
      }
      return headers
    },
  }),
  endpoints: (builder) => ({
    login: builder.mutation<UserResponse, LoginRequest>({
      query: (credentials) => ({
        url: 'user/login',
        method: 'POST',
        body: credentials,
      }),
    }),
    signup: builder.mutation<UserResponse, SignupRequest>({
      query: (credentials) => ({
        url: 'user/signup',
        method: 'POST',
        body: credentials,
      }),
    }),
    passwordForgot: builder.mutation<
      PasswordForgotResponse,
      PasswordForgotRequest
    >({
      query: (body) => ({
        url: '/user/password_forgot',
        method: 'POST',
        body,
      }),
    }),
    passwordReset: builder.mutation<
      PasswordResetResponse,
      PasswordResetRequest
    >({
      query: (body) => ({
        url: '/user/password_reset',
        method: 'POST',
        body,
      }),
    }),
    profile: builder.mutation<User | Coach, PostProfileRequest>({
      query: (body) => ({
        url: 'users/profile',
        method: 'POST',
        body,
      }),
    }),
    getCoachProfile: builder.query<CoachProfile, void>({
      query: () => ({
        url: 'coaches/profile',
        method: 'GET',
      }),
    }),
    putCoachProfile: builder.mutation<Coach, PutCoachProfileRequest>({
      query: (body) => ({
        url: 'coaches/profile',
        method: 'PUT',
        body,
      }),
      invalidatesTags: ['coachProfile'],
    }),
    getCoach: builder.mutation<Coach, void>({
      query: () => ({
        url: 'users/coach',
        method: 'GET',
      }),
    }),
    loginCoach: builder.mutation<CoachResponse, CoachLoginRequest>({
      query: (credentials) => ({
        url: 'coach/login',
        method: 'POST',
        body: credentials,
      }),
    }),
    coachPasswordForgot: builder.mutation<
      PasswordForgotResponse,
      PasswordForgotRequest
    >({
      query: (body) => ({
        url: '/coach/password_forgot',
        method: 'POST',
        body,
      }),
    }),
    coachPasswordReset: builder.mutation<
      PasswordResetResponse,
      PasswordResetRequest
    >({
      query: (body) => ({
        url: '/coach/password_reset',
        method: 'POST',
        body,
      }),
    }),
    getCustomers: builder.mutation<ConnectionUser[], void>({
      query: () => ({
        url: 'coaches/customers',
        method: 'GET',
      }),
    }),
    getMessages: builder.query<ListResponse<Message>, MessagesRequest>({
      query: (user) => ({
        url: 'chat/messages',
        method: 'GET',
        params: user,
      }),
      providesTags: ['messages'],
    }),
    deleteMessage: builder.mutation<DeleteMessageResponse, string>({
      query: (id) => ({
        url: `chat/messages/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['messages'],
    }),
    postMessagesRead: builder.mutation<Message, string>({
      query: (id) => ({
        url: `chat/messages/${id}/read`,
        method: 'POST',
      }),
    }),
    getUpcomingEvents: builder.query<ListResponse<Event>, void>({
      query: () => ({
        url: '/scheduler/upcoming_events',
        method: 'GET',
      }),
      providesTags: ['coachEvents'],
    }),
    getCoachEvents: builder.query<ListResponse<Event>, void>({
      query: (event) => ({
        url: 'scheduler/events',
        method: 'GET',
      }),
      providesTags: ['coachEvents'],
    }),
    getEvent: builder.mutation<Event, GetEventRequest>({
      query: ({ eventId, date }: GetEventRequest) => ({
        url: 'scheduler/event',
        method: 'GET',
        params: { eventId, date },
      }),
    }),
    deleteEvent: builder.mutation<DeleteEventResponse, DeleteEventRequest>({
      query: (eventId) => ({
        url: 'scheduler/event',
        method: 'DELETE',
        body: { eventId },
      }),
      invalidatesTags: ['coachEvents', 'sessionNotes'],
    }),
    createEvent: builder.mutation<Event, CreateEventRequest>({
      query: (event) => ({
        url: 'scheduler/events',
        method: 'POST',
        body: event,
      }),
      invalidatesTags: ['coachEvents', 'sessionNotes'],
    }),
    updateEvent: builder.mutation<Event, UpdateEventRequest>({
      query: (event) => ({
        url: 'scheduler/events',
        method: 'POST',
        body: event,
      }),
      invalidatesTags: ['coachEvents', 'sessionNotes'],
    }),
    createBlockEvent: builder.mutation<Event, CreateBlockEventRequest>({
      query: (event) => ({
        url: 'scheduler/block',
        method: 'POST',
        body: event,
      }),
      invalidatesTags: ['coachEvents', 'sessionNotes', 'coachBlockEvents'],
    }),
    getCoachBlockEvents: builder.query<ListResponse<Event>, void>({
      query: () => ({
        url: 'coach/scheduler/block_events',
        method: 'GET',
      }),
      providesTags: ['coachBlockEvents'],
    }),
    getCoachAvailability: builder.query({
      query: (params) => ({
        url: 'coach/scheduler/availability',
        method: 'GET',
        params,
      }),
    }),
    deleteBlockEvent: builder.mutation<
      DeleteEventResponse,
      DeleteBlockEventRequest
    >({
      query: (eventId) => ({
        url: 'coach/scheduler/block_event',
        method: 'DELETE',
        body: { eventId },
      }),
      invalidatesTags: ['coachEvents', 'coachBlockEvents'],
    }),
    getCompany: builder.query<Company, GetCompanyRequest>({
      query: (companyId) => ({
        url: 'company',
        method: 'GET',
        params: { companyId },
      }),
    }),
    getInvitation: builder.mutation<Invitation, GetInvitationRequest>({
      query: (invitationId) => ({
        url: 'invitation',
        method: 'GET',
        params: { invitationId },
      }),
    }),
    getCompanies: builder.mutation<ListResponse<GetCompaniesResponse>, void>({
      query: () => ({
        url: 'coaches/companies',
        method: 'GET',
      }),
    }),
    getUsers: builder.mutation<ListResponse<GetUsersResponse>, GetUsersRequest>(
      {
        query: (companyId) => ({
          url: 'coaches/users',
          method: 'GET',
          params: { companyId },
        }),
      }
    ),
    getUser: builder.mutation<GetUserResponse, string>({
      query: (userId) => ({
        url: 'coaches/user',
        method: 'GET',
        params: { userId },
      }),
    }),
    acceptEvent: builder.mutation<AcceptEventResponse, AcceptEventRequest>({
      query: (body) => ({
        url: '/scheduler/accept',
        method: 'POST',
        body,
      }),
    }),
    getRTCToken: builder.mutation<GetRTCTokenResponse, GetRTCTokenRequest>({
      query: (params) => ({
        url: '/call/token',
        method: 'GET',
        params: params,
      }),
    }),
    getAvailability: builder.mutation<
      GetAvailabilityResponse,
      GetAvailabilityRequest
    >({
      query: (params) => ({
        url: '/scheduler/availability',
        method: 'GET',
        params,
      }),
    }),
    getSchedule: builder.mutation<GetScheduleResponse, GetScheduleRequest>({
      query: (params) => ({
        url: '/scheduler/schedule',
        method: 'GET',
        params,
      }),
    }),
    getExcludedDates: builder.mutation<
      GetExcludedDatesResponse,
      GetExcludedDatesRequest
    >({
      query: (params) => ({
        url: '/scheduler/excluded_dates',
        method: 'GET',
        params,
      }),
    }),
    getUnifiedLink: builder.query<any, GetUnifiedLinkRequest>({
      query: (params) => ({
        url: '/links/unified',
        method: 'GET',
        params,
      }),
    }),
    getOnboardingLink: builder.query<any, GetUnifiedLinkRequest>({
      query: (params) => ({
        url: '/links/onboarding',
        method: 'GET',
        params,
      }),
    }),
    postSchedule: builder.mutation<GetScheduleResponse, PostScheduleRequest>({
      query: (body) => ({
        url: '/scheduler/schedule',
        method: 'POST',
        body,
      }),
    }),
    getNotes: builder.mutation<Note[], string>({
      query: (userId) => ({
        url: '/notes',
        method: 'GET',
        params: { userId },
      }),
    }),
    postNote: builder.mutation<Note, PostNoteRequest>({
      query: (body) => ({
        url: '/notes',
        method: 'POST',
        body,
      }),
    }),
    updateNote: builder.mutation<Note, UpdateNoteRequest>({
      query: ({ id, body }) => ({
        url: `/notes/${id}`,
        method: 'PUT',
        body: { body },
      }),
    }),
    deleteNote: builder.mutation<Note, string>({
      query: (id) => ({
        url: `/notes/${id}`,
        method: 'DELETE',
      }),
    }),
    getSessionNotes: builder.mutation<SessionNote[], any>({
      query: ({ userId, eventId, coachId }) => ({
        url: '/session-notes',
        method: 'GET',
        params: { userId, eventId, coachId },
      }),
    }),
    getCoachSessionNotesForUser: builder.query<any, any>({
      query: () => ({
        url: '/user/session-notes',
        method: 'GET',
      }),
      providesTags: ['sessionNotes'],
    }),
    postSessionNote: builder.mutation<Note, PostSessionNoteRequest>({
      query: (body) => ({
        url: '/session-notes',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['sessionNotes'],
    }),
    updateSessionNote: builder.mutation<Note, UpdateSessionNoteRequest>({
      query: ({ id, body }) => ({
        url: `/session-notes/${id}`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: ['sessionNotes'],
    }),
    deleteSessionNote: builder.mutation<Note, string>({
      query: (id) => ({
        url: `/session-notes/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['sessionNotes'],
    }),
    getEventsWithNotes: builder.query<EventsWithNotes, any>({
      query: (userId) => ({
        url: 'scheduler/events_notes',
        method: 'GET',
        params: { userId },
      }),
      providesTags: ['sessionNotes'],
    }),
    postReview: builder.mutation<any, PostReviewRequest>({
      query: (body) => ({
        url: '/reviews',
        method: 'POST',
        body,
      }),
    }),
    postSessionUserFeedback: builder.mutation<
      any,
      PostSessionUserFeedbackRequest
    >({
      query: (body) => ({
        url: '/feedback/user',
        method: 'POST',
        body,
      }),
    }),
    postMatch: builder.mutation<UserResponse, string>({
      query: (coachId) => ({
        url: '/users/match',
        method: 'POST',
        body: { coachId },
      }),
    }),
    getCoachBio: builder.mutation<GetCoachBioResponse, void>({
      query: (params) => ({
        url: '/coaches/bio',
        method: 'GET',
      }),
    }),
    getCoachBioById: builder.query<GetCoachBioResponse, string>({
      query: (coachId) => ({
        url: '/coaches/bio',
        method: 'GET',
        params: { coachId },
      }),
    }),
    protected: builder.mutation<{ message: string }, void>({
      query: () => 'protected',
    }),
    getUserNotes: builder.query<UserNoteResponse[], void>({
      query: () => ({
        url: 'user/note',
        method: 'GET',
      }),
      providesTags: ['userNotes'],
    }),
    postUserNote: builder.mutation<UserNoteResponse, UserNote>({
      query: (body) => ({
        url: 'user/note',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['userNotes'],
    }),
    deleteUserNote: builder.mutation<UserNoteResponse, string>({
      query: (noteId) => ({
        url: `user/note/${noteId}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['userNotes'],
    }),
    updateUserNote: builder.mutation<UserNoteResponse, any>({
      query: ({ id, body }) => ({
        url: `user/note/${id}`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: ['userNotes'],
    }),
    getOnboardingQuestions: builder.query<any, any>({
      query: ({ companyId, language }) => ({
        url: `/onboarding/questions?companyId=${companyId}&lang=${language}&version=2`,
        method: 'GET',
      }),
    }),
    getSurveyAnswers: builder.mutation<any, string>({
      query: (userId) => ({
        url: '/onboarding/answers',
        method: 'GET',
        params: { userId },
      }),
    }),
    getMe: builder.mutation<any, any>({
      query: (userId) => ({
        url: `/user?userId=${userId}`,
        method: 'GET',
      }),
    }),
    postOnboardingData: builder.mutation<any, any>({
      query: (body) => ({
        url: '/onboarding',
        method: 'POST',
        body,
      }),
    }),
    userExists: builder.query<any, any>({
      query: (email) => ({
        url: `/user/exists?email=${encodeURIComponent(email)}`,
        method: 'GET',
      }),
    }),
    getCoachOptions: builder.query<any, any>({
      query: () => ({
        url: '/user/coach_options',
        method: 'GET',
      }),
    }),
    matchWithCoach: builder.mutation<any, any>({
      query: ({ coachId }) => ({
        url: '/users/match',
        method: 'POST',
        body: { coachId },
      }),
    }),
    getSurvey: builder.query<any, any>({
      query: (params) => ({
        url: 'feedback-survey',
        method: 'GET',
        params,
      }),
    }),
    postSurveyAnswers: builder.mutation<any, any>({
      query: (body) => ({
        url: '/feedback-survey',
        method: 'POST',
        body,
      }),
    }),
    postSurveySession: builder.mutation<any, any>({
      query: (body) => ({
        url: '/feedback-survey/session',
        method: 'POST',
        body,
      }),
    }),
    getCoachInbox: builder.query<any[], any>({
      query: () => ({
        url: '/chat/coach/inbox',
        method: 'GET',
      }),
    }),
    getOnboardingCoachOptions: builder.query({
      query: (params) => ({
        url: '/onboarding/coach_options',
        method: 'GET',
        params,
      }),
    }),
    getBookingRequest: builder.query({
      query: (params) => ({
        url: '/booking_coach_request/',
        method: 'GET',
        params,
      }),
    }),
    postBookingRequest: builder.mutation<any, any>({
      query: (body) => ({
        url: '/booking_coach_request/',
        method: 'POST',
        body,
      }),
    }),
    postUserBooking: builder.mutation<any, any>({
      query: (body) => ({
        url: '/user/booking_request',
        method: 'POST',
        body,
      }),
    }),
    postBookingCoachRequest: builder.mutation({
      query: (body) => ({
        url: '/user/booking_coach_request',
        method: 'POST',
        body,
      }),
    }),
    deleteBookingCoachRequest: builder.mutation({
      query: (body) => ({
        url: '/user/booking_coach_request',
        method: 'DELETE',
        body,
      }),
    }),
    logUser: builder.mutation({
      query: (body) => ({
        url: '/user/log',
        method: 'POST',
        body,
      }),
    }),
    getCoachFeedback: builder.query({
      query: () => ({
        url: 'coach/feedback',
        method: 'GET',
      }),
    }),
    getCalendars: builder.query({
      query: () => ({
        url: '/coach/external_calendars',
        method: 'GET',
      }),
    }),
    patchExternalCalendar: builder.mutation({
      query: ({ calendarId, ...body }) => ({
        url: `/coach/external_calendars/${calendarId}`,
        method: 'PATCH',
        body,
      }),
    }),
    deleteExternalCalendar: builder.mutation({
      query: (id) => ({
        url: `/coach/external_calendars/${id}`,
        method: 'DELETE',
      }),
    }),
    deleteUser: builder.mutation({
      query: () => ({
        url: '/user/',
        method: 'DELETE',
      }),
      // invalidatesTags: ['user'],
    }),
  }),
})

export const {
  useGetMeMutation,
  useLoginMutation,
  useSignupMutation,
  useProfileMutation,
  useGetCoachProfileQuery,
  usePutCoachProfileMutation,
  useGetCoachMutation,
  useLoginCoachMutation,
  usePasswordForgotMutation,
  usePasswordResetMutation,
  useCoachPasswordForgotMutation,
  useCoachPasswordResetMutation,
  useGetCustomersMutation,
  useGetMessagesQuery,
  useDeleteMessageMutation,
  usePostMessagesReadMutation,
  useGetCoachEventsQuery,
  useGetEventMutation,
  useDeleteEventMutation,
  useCreateEventMutation,
  useUpdateEventMutation,
  useCreateBlockEventMutation,
  useGetCoachBlockEventsQuery,
  useDeleteBlockEventMutation,
  useGetCompanyQuery,
  useGetInvitationMutation,
  useGetCompaniesMutation,
  useGetUsersMutation,
  useGetUserMutation,
  useAcceptEventMutation,
  useGetRTCTokenMutation,
  useGetAvailabilityMutation,
  useGetScheduleMutation,
  usePostScheduleMutation,
  useGetExcludedDatesMutation,
  useGetNotesMutation,
  usePostNoteMutation,
  useUpdateNoteMutation,
  useDeleteNoteMutation,
  useGetSessionNotesMutation,
  usePostSessionNoteMutation,
  useUpdateSessionNoteMutation,
  useDeleteSessionNoteMutation,
  useGetCoachSessionNotesForUserQuery,
  useGetEventsWithNotesQuery,
  usePostReviewMutation,
  usePostSessionUserFeedbackMutation,
  usePostMatchMutation,
  useGetCoachBioMutation,
  useProtectedMutation,
  useGetUserNotesQuery,
  usePostUserNoteMutation,
  useDeleteUserNoteMutation,
  useUpdateUserNoteMutation,
  useLazyGetOnboardingQuestionsQuery,
  useGetSurveyAnswersMutation,
  usePostOnboardingDataMutation,
  useGetCoachOptionsQuery,
  useMatchWithCoachMutation,
  useGetUnifiedLinkQuery,
  useGetOnboardingLinkQuery,
  useGetSurveyQuery,
  usePostSurveyAnswersMutation,
  usePostSurveySessionMutation,
  useGetCoachInboxQuery,
  useGetOnboardingCoachOptionsQuery,
  useGetBookingRequestQuery,
  usePostBookingRequestMutation,
  usePostUserBookingMutation,
  usePostBookingCoachRequestMutation,
  useDeleteBookingCoachRequestMutation,
  useGetUpcomingEventsQuery,
  useLazyGetCoachBioByIdQuery,
  useLogUserMutation,
  useGetCoachAvailabilityQuery,
  useGetCoachFeedbackQuery,
  useGetCalendarsQuery,
  usePatchExternalCalendarMutation,
  useDeleteExternalCalendarMutation,
  useDeleteUserMutation,
} = api
