import { Grid, GridItem } from '@chakra-ui/react'
import { FC, useEffect, useState } from 'react'
import Notes from './Notes'

import {
  Box, Divider,
  Flex, Heading, Spinner,
  StackDivider,
  Text, VStack
} from '@chakra-ui/react'
import moment from 'moment-timezone'
import { useTranslation } from 'react-i18next'
import { MdDateRange } from 'react-icons/md'

import {
  useGetCoachSessionNotesForUserQuery
} from '../../app/services/api'
import { parseText } from '../../utils/string'

const SessionNotesForUser = ({ user }: any) => {
  const { t } = useTranslation()

  const {
    data,
    isLoading,
  } = useGetCoachSessionNotesForUserQuery(user)

  const [sessionNotes, setSessionNotes] = useState([])
  useEffect(() => {
    if (data) {
      let availableSessionNotes = data.filter((note: any) => note.session?.userShowedUp);
      availableSessionNotes.sort((a: any, b: any) => new Date(b.session.start).getTime() - new Date(a.session.start).getTime());
      setSessionNotes(availableSessionNotes)
    }
  }, [data])

  // Component to render session date with duration
  const SessionDate = ({ event, user }: { event: any; user: any }) => {
    // Get session duration using start and end in minutes
    const duration = moment(event.end).diff(moment(event.start), 'minutes')
    const startDate = moment(event.start)
      .tz(user.timezone)
      .format('MMM DD, YYYY h:mm A')
    return (
      <Flex my={2} color="gray">
        <MdDateRange size="18px" />
        <Text fontSize={'sm'} ml={1}>
          {startDate} ({duration}min)
        </Text>
      </Flex>
    )
  }

  // Show Spinner component if isLoading
  if (isLoading) {
    return <Spinner />
  }

  if (!data) {
    return null
  }
  return (
    <Flex w="full" flexDirection="column" bg={'white'} borderRadius={10}>
      <Box width={'full'} my={6} pl={6} pr={4}>
        <Flex width={'full'} my={1} justifyContent={'space-between'} mb={5}>
          <Box>
            {/* Add heading with icon on left */}
            <Heading as="h3" size="md" fontWeight={'bold'}>
              {t('coach_notes')}
            </Heading>
          </Box>
        </Flex>
        <Divider />
        {isLoading && (
          <Box textAlign="center" py={10} px={6}>
            <Spinner />
          </Box>
        )}
        {
            sessionNotes && sessionNotes.length > 0 ? (
              <VStack
                divider={<StackDivider borderColor="gray.200" />}
                spacing={4}
                align="stretch"
              >
                {sessionNotes.map((sessionNote: any) =>
                  sessionNote.session && (
                  <Grid
                    key={sessionNote._id}
                    templateColumns="repeat(5, 1fr)"
                    gap={4}
                    bg={'white'}
                  >
                    <GridItem colSpan={7} my={2}>
                      <SessionDate event={sessionNote.session} user={user} />
                      <Text>{parseText(sessionNote.body.text)}</Text>
                    </GridItem>
                  </Grid>
                  )
                )}
              </VStack>
            ) : (
              <Box textAlign="center" py={10} px={6}>
              <Text fontSize="18px" mt={3} mb={2}>
                {t('no_notes')}
              </Text>
            </Box>
            )
        }
      </Box>
    </Flex>
  )
}

const UserNotesWithSessionNotes: FC<any> = ({ user }) => {
  return (
    <Grid templateColumns={['1fr', '1fr', '1fr', '1fr 1fr']} gap={5}>
      <GridItem>
        <SessionNotesForUser user={user} />
      </GridItem>
      <GridItem>
        <Notes user={user} />
      </GridItem>
    </Grid>
  )
}
export default UserNotesWithSessionNotes
