import {
  Box,
  Button,
  Heading,
  HStack,
  Icon,
  Text,
  useToast,
} from '@chakra-ui/react'
import { useCallback, useEffect } from 'react'
import { FcGoogle } from 'react-icons/fc'
import { SiGooglecalendar } from 'react-icons/si'

import { useLocale } from '../../hooks/useLocale'
import { Card } from './Card'


const GOOGLE_CALENDAR_SCOPES = [
  'https://www.googleapis.com/auth/calendar.readonly',
  'https://www.googleapis.com/auth/calendar.events',
]

export const SelectCalendar = ({ coach }: any) => {
  const secondaryTextColor = '#999'
  const { t } = useLocale()
  const toast = useToast()

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search)
    const status = searchParams.get('status')
    const message = searchParams.get('message')

    if (status && message) {
      toast({
        title: t(status),
        description: message,
        status: status === 'error' ? 'error' : 'success',
        duration: 3000,
        isClosable: true,
      })
    }
  }, [toast, t])

  const syncGoogleCalendar = useCallback(() => {
    const coachId = coach?.id || coach?._id

    const params = {
      client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID,
      redirect_uri: process.env.REACT_APP_GOOGLE_CALLBACK_URL,
      scope: GOOGLE_CALENDAR_SCOPES.join(' '),
      state: coachId,
      access_type: 'offline',
      prompt: 'consent',
      response_type: 'code',
    }
  
    const form = document.createElement('form')
    form.setAttribute('action', 'https://accounts.google.com/o/oauth2/v2/auth')
    form.setAttribute('method', 'GET')
  
    for (const p in params) {
      const input = document.createElement('input')
      input.setAttribute('type', 'hidden')
      input.setAttribute('name', p)
      input.setAttribute('value', params[p as keyof typeof params])
      form.appendChild(input)
    }
  
    document.body.appendChild(form)
    form.submit()
  }, [coach])

  return (
    <Box>
      <Heading mb="3" size="lg">
        {t('calendar_connections')}
      </Heading>

      <Card maxW="4xl" px={0}>
        <Text px="4" fontSize={18}>
          {t('select_calendar')}
        </Text>
        <Text px="4" fontSize={15} color={secondaryTextColor}>
          {t('connect_calendar_description')}
        </Text>

        <HStack
          mt="6"
          mb="3"
          px="2"
          py="3"
          backgroundColor="#eee"
          border={'1px solid #eee'}
        >
          <Icon as={FcGoogle} fontSize={24} />
          <Text as="b">Google</Text>
        </HStack>

        <Item
          name="Google Calendar"
          desc="Gmail, G Suite"
          onConnect={syncGoogleCalendar}
        />
      </Card>
    </Box>
  )
}

const Item = ({ name, desc, onConnect }: any) => {
  const { t } = useLocale()
  const secondaryTextColor = '#999'

  return (
    <HStack p="4" justify="space-between">
      <HStack spacing="4">
        <Icon fontSize={24} as={SiGooglecalendar} />
        <Text>{name}</Text>
      </HStack>
      <Text fontSize={14} color={secondaryTextColor}>
        {desc}
      </Text>

      <Button onClick={onConnect}>{t('connect')}</Button>
    </HStack>
  )
}
