import { useState, useCallback, useRef, useEffect } from 'react'
import {
  Box,
  Heading,
  IconButton,
  Spinner,
  Text,
  useColorModeValue,
  useRadioGroup,
  VStack,
} from '@chakra-ui/react'
import moment from 'moment'
import { addMonths } from 'date-fns'

import DatePicker from './DatePicker'
import { useGetAvailabilityMutation } from '../../app/services/api'
import { TimeItem } from './Datetime'
import { useLocale } from '../../hooks/useLocale'
import { InfoPopover } from './components/InfoPopover'
import { BiInfoCircle } from 'react-icons/bi'

export const FlowA: React.FC<any> = ({
  user,
  coach,
  duration,
  timezone,
  date,
  language,
  setDate,
  setAttempts,
  onNext,
  setIsExtraSlotSelected,
  setTime,
}) => {
  const { t } = useLocale()
  const timesEndRef = useRef<HTMLDivElement>(null)

  const [times, setTimes] = useState<any[]>()
  const start = new Date()

  const [getAvailability, { isLoading }] = useGetAvailabilityMutation()

  useEffect(() => {
    setAttempts(0)
  }, [setAttempts])

  const scrollToBottom = () => {
    if (timesEndRef.current) {
      timesEndRef.current.scrollIntoView({ behavior: 'smooth' })
    }
  }

  const onSelectedDay = useCallback(
    async (date: any) => {
      if (!date) return
      const params = {
        userId: coach._id,
        duration: duration,
        date: moment(date).toISOString(),
        timeZone: user?.timezone ?? timezone,
        bookingFlow: 'flowA',
      }

      const { data }: any = await getAvailability(params)
      const slots = data?.data || []
      const extraSlots = data?.extraSlots || []
      let allSlots: any[] = []
      // Slots
      slots.forEach((slot: string) => {
        allSlots.push({
          label: moment(slot)
            .tz(user?.timezone ?? timezone)
            .format('HH:mm'),
          value: slot,
          extra: false,
        })
      })
      // Extra slots
      extraSlots.forEach((slot: string) => {
        allSlots.push({
          label: moment(slot)
            .tz(user?.timezone ?? timezone)
            .format('HH:mm'),
          value: slot,
          extra: true,
        })
      })

      allSlots.sort((a, b) => a.value.localeCompare(b.value))
      setTimes(allSlots)

      setDate(date)
      scrollToBottom()
      setAttempts((prev: number) => prev + 1)
    },
    [setDate, getAvailability, coach, duration, user, timezone, setAttempts]
  )

  const onTimeChange = useCallback(
    (time: string) => {
      console.log('time', time)

      setTime(time)
      onNext()
    },
    [setTime, onNext]
  )

  const { getRootProps, getRadioProps } = useRadioGroup({
    name: 'time',
    onChange: onTimeChange,
  })
  const group = getRootProps()

  return (
    <VStack spacing={4} {...group}>
      <DatePicker
        language={language}
        selectedDate={date}
        onSelect={onSelectedDay}
        dateFormat="P"
        minDate={start}
        maxDate={addMonths(new Date(), 1)}
        inline
      />
      <Box
        bg={useColorModeValue('white', 'gray.900')}
        borderRight="1px"
        borderRightColor={useColorModeValue('gray.200', 'gray.700')}
        h="full"
      >
        {isLoading && (
          <Box w="240px" py={3}>
            <Spinner size="xl" />
          </Box>
        )}
        {date && !isLoading && times && (
          <>
            <Heading p="4" as="h5" size="sm" w="240px">
              {moment(date).format('dddd, D MMMM')}
            </Heading>
            {times && times.length > 0 ? (
              times.map(({ value, label, extra }) => {
                const radio = getRadioProps({ value })
                if (extra) {
                  return (
                    <Box
                      key={value}
                      bg="gray.200"
                      _hover={{ bg: 'blue.500' }}
                      m="1"
                      p="4"
                      borderRadius="lg"
                      pos="relative"
                      onClick={() => setIsExtraSlotSelected(true)}
                    >
                      <InfoPopover
                        title={t('extra_slot')}
                        trigger={
                          <IconButton
                            bg="transparent"
                            _hover={{ bg: 'transparent' }}
                            aria-label="Information"
                            icon={<BiInfoCircle />}
                            pos="absolute"
                            top="15%"
                            right="10%"
                          />
                        }
                      >
                        <Text>{t('unconfirmed_slot')}</Text>
                      </InfoPopover>
                      <Box flex="1">
                        <TimeItem {...radio} noStyleCenter>
                          {label}
                        </TimeItem>
                      </Box>
                    </Box>
                  )
                }

                return (
                  <TimeItem
                    {...radio}
                    key={value}
                    onClick={() => setIsExtraSlotSelected(false)}
                  >
                    {label}
                  </TimeItem>
                )
              })
            ) : (
              <Text mb={2}>{t('no_slots_available')}</Text>
            )}
            <span ref={timesEndRef}></span>
          </>
        )}
      </Box>
    </VStack>
  )
}
