import { useCallback, useEffect, useState, useMemo } from 'react'
import { Calendar, Views, momentLocalizer } from 'react-big-calendar'
import {
  Box,
  Button,
  CloseButton,
  Icon,
  Square,
  Stack,
  Text,
  useDisclosure,
  useBreakpointValue,
} from '@chakra-ui/react'
import moment from 'moment'
import 'react-big-calendar/lib/css/react-big-calendar.css'
import 'moment/locale/en-gb'

import {
  useGetCoachAvailabilityQuery,
  useGetCoachBlockEventsQuery,
  useGetCoachEventsQuery,
} from '../../app/services/api'
import Event from './Event'
import NewEvent from './NewEvent'
import BlockEvent from './BlockEvent'
import { Link, useLocation } from 'react-router-dom'
import { useFlags } from 'flagsmith/react'
import { FiInfo } from 'react-icons/fi'
import { SiGooglecalendar } from 'react-icons/si'
import { useLocale } from '../../hooks/useLocale'

const currentDate = new Date()
const startOfWeek = moment(currentDate).startOf('week').weekday(0).toDate()
const endOfWeek = moment(currentDate).endOf('week').weekday(6).toDate()

const Scheduler = ({ user, coach }: any) => {
  const [range, setRange] = useState<any>({
    start: startOfWeek,
    end: endOfWeek,
  })
  const { t } = useLocale()

  const [event, setEvent] = useState<any>(null)
  const [start, setStart] = useState(null)
  const [end, setEnd] = useState(null)
  const [guest, setGuest] = useState<any>(null)
  const { isOpen, onOpen, onClose } = useDisclosure()
  const {
    isOpen: isCallOpen,
    onOpen: onCallOpen,
    onClose: onCallClose,
  } = useDisclosure()
  const {
    isOpen: isNewOpen,
    onOpen: onNewOpen,
    onClose: onNewClose,
  } = useDisclosure()
  const {
    isOpen: isBlockOpen,
    onOpen: onBlockOpen,
    onClose: onBlockClose,
  } = useDisclosure()
  const {
    isOpen: isAnnouncementOpen,
    onOpen: onAnnouncementOpen,
    onClose: onAnnouncementClose,
  } = useDisclosure()
  const [bookingFlow, setBookingFlow] = useState('')
  const location: any = useLocation()

  const isMobile = useBreakpointValue({ base: true, md: false })

  useEffect(() => {
    moment.locale('en-gb')
  }, [])

  useEffect(() => {
    const bookingFlowId = new URLSearchParams(location.search).get(
      'bookingFlow'
    )
    if (bookingFlowId) {
      setBookingFlow(bookingFlowId)
    }
  }, [location])

  const { show_calendar_announcement_coaches: showAnnouncement } = useFlags([
    'show_calendar_announcement_coaches',
  ])
  useEffect(() => {
    if (showAnnouncement) {
      onAnnouncementOpen()
    }
  }, [showAnnouncement, onAnnouncementOpen])

  const { data, error } = useGetCoachEventsQuery()
  const { data: blockData, error: blockError } = useGetCoachBlockEventsQuery()
  const { data: availability, error: availabilityError } =
    useGetCoachAvailabilityQuery({
      start: moment(range.start).format('YYYY-MM-DD'),
      end: moment(range.end).format('YYYY-MM-DD'),
    })

  const username = user ? user.id : coach?.id

  const handleSelect = ({ start, end }: any) => {
    setEvent(null)
    setStart(start)
    setEnd(end)
    onNewOpen()
  }

  const handleEventSelect = useCallback(
    (event: any) => {
      if (event?.title.toLowerCase() === 'availability') {
        return
      }
      setEvent(event)
      event.isBlock ? onBlockOpen() : onOpen()
    },
    [onBlockOpen, onOpen]
  )

  const handleReschedule = useCallback(() => {
    console.log('handleReschedule', event)
    setStart(event.start)
    setEnd(event.end)
    onNewOpen()
  }, [event, onNewOpen])

  const eventStyleGetter = (
    event: any,
    start: any,
    end: any,
    isSelected: any
  ) => {
    let backgroundColor = '#039be5'
    if (event?.title.toLowerCase() === 'availability')
      backgroundColor = '#01B574'
    if (event?.isBlock) backgroundColor = '#f44336'
    const style = {
      backgroundColor: backgroundColor,
      borderRadius: '0px',
      opacity: 0.8,
      color: 'black',
      border: '1px solid #039be5',
      display: 'block',
    }
    return {
      style: style,
    }
  }

  useEffect(() => {
    moment.tz.setDefault(coach.timezone)
  }, [coach.timezone])

  const { myEvents, localizer, getNow, scrollToTime } = useMemo(() => {
    const events =
      data?.data.map(({ start, end, ...rest }) => {
        return {
          start: moment(start).toDate(),
          end: moment(end).toDate(),
          ...rest,
        }
      }) || []

    const blockEvents =
      blockData?.data.map(({ start, end, ...rest }) => {
        return {
          start: moment(start).toDate(),
          end: moment(end).toDate(),
          ...rest,
        }
      }) || []

    const availabilityEvents =
      availability?.map(({ start, end, ...rest }: any) => {
        return {
          start: moment(start).toDate(),
          end: moment(end).toDate(),
          ...rest,
        }
      }) || []

    return {
      myEvents: events.concat(blockEvents).concat(availabilityEvents),
      getNow: () => moment().toDate(),
      localizer: momentLocalizer(moment),
      scrollToTime: moment().toDate(),
    }
  }, [data, blockData, availability])

  if (error || !data || blockError || !blockData || availabilityError) {
    return null
  }

  if (!coach) return null

  return (
    <Box as="section">
      <Box
        bg="bg-surface"
        boxShadow="sm"
        bgColor={'white'}
        mb={3}
        mx={0}
        p={2}
        borderRadius={8}
        display={isAnnouncementOpen ? 'block' : 'none'}
      >
        <CloseButton
          display={{ sm: 'none' }}
          position="absolute"
          right="2"
          top="2"
          onClick={onAnnouncementClose}
        />
        <Stack
          direction={{ base: 'column', sm: 'row' }}
          justify="space-between"
          spacing={{ base: '3', md: '2' }}
        >
          <Stack
            spacing="1"
            direction={{ base: 'column', md: 'row' }}
            align={{ base: 'start', md: 'center' }}
          >
            {!isMobile && (
              <Square size="12" bg="bg-subtle" borderRadius="md">
                <Icon as={FiInfo} boxSize="6" />
              </Square>
            )}
            <Stack
              direction={{ base: 'column', md: 'row' }}
              spacing={{ base: '0.5', md: '1.5' }}
              pe={{ base: '4', sm: '0' }}
            >
              <Text fontWeight="medium">
                <b>{t('feature_alert')}</b>{' '}
                {t('new_calendar_sync_feature_desc')}
              </Text>
            </Stack>
          </Stack>
          <Stack
            direction={{ base: 'column', sm: 'row' }}
            spacing={{ base: '3', sm: '2' }}
            align={{ base: 'stretch', sm: 'center' }}
          >
            <Button
              as={Link}
              to="/coach/settings?tab=1"
              colorScheme="blue"
              variant="outline"
              size="sm"
              leftIcon={<Icon as={SiGooglecalendar} />}
            >
              {t('add_calendars')}
            </Button>
            <CloseButton
              display={{ base: 'none', sm: 'inline-flex' }}
              onClick={onAnnouncementClose}
            />
          </Stack>
        </Stack>
      </Box>

      <Calendar
        selectable
        events={myEvents}
        getNow={getNow}
        localizer={localizer}
        scrollToTime={scrollToTime}
        defaultView={Views.WEEK}
        onSelectEvent={handleEventSelect}
        onSelectSlot={handleSelect}
        eventPropGetter={eventStyleGetter}
        formats={{
          dayRangeHeaderFormat: (
            { start, end }: any,
            culture: any,
            localizer: any
          ) =>
            `${localizer.format(start, 'MMMM D', culture)}
            -
            ${localizer.format(end, 'MMMM D', culture)} (${coach.timezone})`,
        }}
        onRangeChange={(range) => {
          if (Array.isArray(range)) {
            setRange({
              start: moment(range[0]).format('YYYY-MM-DD'),
              end: moment(range[range.length - 1]).format('YYYY-MM-DD'),
            })
          }
          if ('start' in range && 'end' in range) {
            setRange({
              start: moment(range.start).format('YYYY-MM-DD'),
              end: moment(range.end).format('YYYY-MM-DD'),
            })
          }
        }}
      />
      <Event
        userId={username}
        isOpen={isOpen}
        onClose={onClose}
        event={event}
        onCallOpen={onCallOpen}
        handleReschedule={handleReschedule}
        guest={guest}
        setGuest={setGuest}
        timezone={coach.timezone}
      />
      <BlockEvent isOpen={isBlockOpen} onClose={onBlockClose} event={event} />
      <NewEvent
        isOpen={isNewOpen}
        onClose={onNewClose}
        start={start}
        end={end}
        setStart={(time: any) => {
          const duration = moment(end).diff(moment(start), 'minutes')
          setStart(time)
          setEnd(moment(time).add(duration, 'minutes').toDate() as any)
        }}
        setEnd={setEnd}
        event={event}
        onEventModalClose={onClose}
        guest={guest}
        bookingFlow={bookingFlow}
        setBookingFlow={setBookingFlow}
      />
    </Box>
  )
}

export default Scheduler
