import { createSlice } from '@reduxjs/toolkit'

export interface Message {
  id: string
  message: string
  dateSent: string
  from: string
  read: string
}

interface chatState {
  messages: Array<Message>
}

const initialState: chatState = {
  messages: []
}

const slice = createSlice({
  name: 'chat',
  initialState: initialState,
  reducers: {
    addMessages: (state, action) => {
      console.log('Adding messages')
      console.log(action.payload)
      state.messages = action.payload
    },
    addMessage: (state, action) => {
      console.log('Adding a message')
      console.log(action.payload)
      state.messages.push(action.payload)
    }
  },
})

export default slice.reducer

export const { addMessages, addMessage } = slice.actions