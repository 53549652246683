import { Avatar } from '@chakra-ui/react'

const CoachAvatar = (props: any) => {
  const { src, coach } = props

  if (coach) {
    const size = props.size || 'md'
    const pictureSrc = coach.profile?.picture
    const name = coach.profile?.name

    return (
      <Avatar
        title={name}
        size={size}
        name={name}
        src={pictureSrc}
        {...props.styles}
      />
    )
  }

  return (
    <Avatar
      size={'xl'}
      src={src}
      mb={4}
      pos={'relative'}
      _after={{
        content: '""',
        w: 4,
        h: 4,
        bg: 'green.300',
        border: '2px solid white',
        rounded: 'full',
        pos: 'absolute',
        bottom: 0,
        right: 3,
      }}
    />
  )
}

export default CoachAvatar
