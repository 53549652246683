import {
  Box,
  Center,
  Flex,
  Heading,
  Text,
  useColorModeValue,
} from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'

import {
  useGetSurveyQuery,
  usePostSurveyAnswersMutation,
  usePostSurveySessionMutation,
} from '../../app/services/api'
import { RootState } from '../../app/store'
import { useLocale } from '../../hooks/useLocale'
import { Logo } from '../../utils/Logo'
import ProgressComponent from '../onboarding/components/ProgressLine'
import FeedbackSurveyQuestionsStep from './FeedbackSurveyQuestionsStep'
import { setSurveyData } from './surveySlice'

export const FeedbackSurvey: React.FC = () => {
  const { sessionId } = useParams<any>()
  const dispatch = useDispatch()
  const { t } = useLocale()

  const { data: surveySession, isLoading: isFetching } = useGetSurveyQuery({ sessionId })
  const [survey, setSurvey] = useState<any>(null)
  const [submit] = usePostSurveyAnswersMutation()
  const [submitSession, { isLoading }] = usePostSurveySessionMutation()
  const { surveyData, questions } = useSelector(
    (state: RootState) => state.survey
  )

  useEffect(() => {
    console.log(surveySession, 'surveySession is here', isFetching);
    if (!isFetching) {
      console.clear()
      setSurvey(surveySession.survey)
    }
  }, [dispatch, isFetching, surveySession])

  const [step, setStep] = useState(1)
  const [totalSteps, setTotalSteps] = useState(2)

  const onProceedNextStep = (step = 1) => {
    if (step === 1) {
      const data: any = {
        sessionId,
        answers: Object.entries(questions).map(([id, value]: any) => {
          if (!value) return null

          return {
            id,
            selectedOptions: value.answers,
            otherAnswer: value.other,
          }
        }),
      }

      submitSession(data).unwrap()
    }
    setStep((prev: any) => prev + step)
  }

  useEffect(() => {
    if (step === totalSteps && !isLoading) {
      submit({ sessionId })
    }
  }, [step, totalSteps, questions, sessionId, submit, isLoading])

  useEffect(() => {
    console.log('survey', survey)
    if (!survey || !survey.questions || survey.questions.length === 0) return
    if (surveySession?.status === 'COMPLETED') {
      setStep(totalSteps)
    }
    setTotalSteps(survey.questions.length + 1)
    dispatch(setSurveyData(survey))
  }, [dispatch, surveySession, survey, totalSteps])

  return (
    <Box
      flexDirection={'column'}
      flex={1}
      overflow="auto"
      bg={useColorModeValue('gray.50', 'gray.800')}
    >
      <Flex
        px={{ base: 4, md: 4 }}
        height="20"
        bg={useColorModeValue('white', 'gray.900')}
        borderBottomWidth="1px"
        borderBottomColor={useColorModeValue('gray.200', 'gray.700')}
        alignItems="center"
        justifyContent={{ base: 'space-between', md: 'center' }}
      >
        <Logo md={5} />
      </Flex>
      <ProgressComponent currentPage={step} totalPages={totalSteps} />
      {isFetching && <div>{t('loading')}</div>}
      {step === totalSteps ? (
        <Center flexDirection="column" gridGap={5} h="89vh">
          <Heading as="h2" size="2xl">
            {t('survey.thankYou')}
          </Heading>
          <Text fontSize="lg">{t('survey.thankYouDescription')}</Text>
        </Center>
      ) : (
        surveyData?.questions && (
          <FeedbackSurveyQuestionsStep
            key={`step#${step}`}
            step={step}
            canGoBack={step > 1}
            onProceedNextStep={onProceedNextStep}
          />
        )
      )}
    </Box>
  )
}
