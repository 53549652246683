import { Box, Flex } from "@chakra-ui/react";

interface Props {
    currentPage: number,
    totalPages: number,
}

const ProgressComponent = (props: Props) => {
  const progress = Math.round(props.currentPage / props.totalPages * 100);
  const remaining = 100 - progress;
  const leftWidth = progress + '%';
  const rightWidth = remaining + '%';
  return (
    <Flex width={'full'} direction={'row'} height={1}>
        <Box bgColor={'primary.500'} width={leftWidth}></Box>
        <Box bgColor={'primary.100'} width={rightWidth}></Box>
    </Flex>
  );
};

export default ProgressComponent;
