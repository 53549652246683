import { Center, Flex, Spinner, useToast } from '@chakra-ui/react'
import { FC, useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { useGetOnboardingLinkQuery } from '../../app/services/api'
import { useLocale } from '../../hooks/useLocale'

const OnboardingLink: FC<any> = () => {
  const { t } = useLocale()
  const toast = useToast()
  const { replace } = useHistory()

  const { companyFriendlyId } = useParams() as any
  const [errorMessage, setErrorMessage] = useState()

  const { data, isSuccess, isLoading, error } = useGetOnboardingLinkQuery({
    companyFriendlyId,
  })
  console.log(
    'OnboardingLink, companyFriendlyId:',
    companyFriendlyId,
    ', data:',
    data,
    ', isLoading:',
    isLoading,
    ', isSuccess:',
    isSuccess
  )
  useEffect(() => {
    if (error) {
      setErrorMessage((error as any).data?.message)
    }
  }, [error])

  useEffect(() => {
    if (errorMessage) {
      toast({
        status: 'error',
        title: t('error'),
        description: errorMessage,
        isClosable: true,
      })
    }
  }, [t, toast, errorMessage])

  useEffect(() => {
    if (data?.redirect) {
      replace(data.redirect)
    }
  }, [data, replace])

  return (
    <Flex
      alignItems="center"
      justifyContent="center"
      padding={10}
      direction="column"
    >
      {isLoading ? (
        <Center>
          <Spinner />
        </Center>
      ) : (
        <></>
      )}
    </Flex>
  )
}

export default OnboardingLink
