import {
  Box,
  Button,
  Checkbox,
  Divider,
  Flex,
  FormControl,
  Grid,
  GridItem,
  Heading,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spinner,
  StackDivider,
  Text,
  Textarea,
  useDisclosure,
  useToast,
  VStack,
} from '@chakra-ui/react'
import moment from 'moment-timezone'
import { ChangeEvent, useCallback, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { MdDateRange } from 'react-icons/md'
import { DEFAULT_MOOD_SCORE } from '../../app/constants'

import {
  useGetEventsWithNotesQuery,
  usePostSessionNoteMutation,
  useUpdateSessionNoteMutation,
} from '../../app/services/api'
import { PostSessionNoteRequest } from '../../types/api'
import { parseText } from '../../utils/string'
import MoodInput from '../profile/MoodInput'

const SessionNotes = ({ coach, userId }: any) => {
  const {
    isOpen: isNoteOpen,
    onOpen: onNoteOpen,
    onClose: onNoteClose,
  } = useDisclosure()
  const { t } = useTranslation()
  const [updating, setUpdating] = useState(false)
  const [noteId, setNoteId] = useState<string | null>(null)
  const [postSessionNotes] = usePostSessionNoteMutation()
  const [updateSessionNotes] = useUpdateSessionNoteMutation()
  const {
    data: events,
    isLoading,
    isFetching,
    isError,
    isSuccess,
  } = useGetEventsWithNotesQuery(userId)

  const { data } = events ? events : { data: [] }

  const [formState, setFormState] = useState<PostSessionNoteRequest>({
    userId,
    eventId: '',
    body: '',
    userShowedUp: true,
    userMoodScore: DEFAULT_MOOD_SCORE,
  })

  const toast = useToast()
  const initialRef = useRef() as any

  const handleNoteClose = useCallback(() => {
    setNoteId(null)
    setFormState((prev) => ({
      ...prev,
      eventId: '',
      body: '',
      userMoodScore: DEFAULT_MOOD_SCORE,
      userShowedUp: true,
    }))
    setUpdating(false)
    onNoteClose()
  }, [onNoteClose, setUpdating, setFormState, setNoteId])

  const onNoteSubmit = useCallback(
    async (event) => {
      event.preventDefault()
      try {
        if (updating) {
          const updatedNote = {
            id: noteId!,
            body: formState,
          }
          await updateSessionNotes(updatedNote).unwrap()
        } else {
          await postSessionNotes(formState).unwrap()
        }
        handleNoteClose()
        toast({
          status: 'success',
          description:
            'The note is successfully ' + (updating ? 'updated' : 'added'),
          isClosable: true,
        })
        setUpdating(false)
      } catch (err) {
        console.log(err)
        toast({
          status: 'error',
          title: 'Error',
          description: 'Error while saving a note',
          isClosable: true,
        })
      }
    },
    [
      noteId,
      setUpdating,
      toast,
      formState,
      updating,
      postSessionNotes,
      updateSessionNotes,
      handleNoteClose,
    ]
  )

  const handleChange = ({ target: { name, value } }: ChangeEvent<any>) =>
    setFormState((prev) => ({ ...prev, [name]: value }))

  const onShowedUpChange = useCallback(
    (ev: ChangeEvent<any>) => {
      setFormState((prev) => ({ ...prev, userShowedUp: ev.target.checked }))
    },
    [setFormState]
  )

  const onMoodChange = useCallback(
    (mood) => {
      setFormState((prev) => ({ ...prev, userMoodScore: mood }))
    },
    [setFormState]
  )
  const onAdd = useCallback(
    (e: any) => {
      const eventId = e.currentTarget.getAttribute('data-event')
      setFormState((prev) => ({
        ...prev,
        eventId,
      }))
      onNoteOpen()
    },
    [onNoteOpen, setFormState]
  )

  const onEdit = useCallback(
    (e: any) => {
      const noteId = e.currentTarget.getAttribute('data-id')
      const eventId = e.currentTarget.getAttribute('data-event')
      const idx = e.currentTarget.getAttribute('data-idx')
      setFormState((prev: any) => ({
        ...prev,
        eventId,
        userShowedUp: data[idx].userShowedUp,
        userMoodScore: data[idx].note.userMoodScore,
        body: data[idx].note.body.text,
      }))
      setNoteId(noteId)
      setUpdating(true)
      onNoteOpen()
    },
    [onNoteOpen, data, setFormState, setNoteId, setUpdating]
  )

  // Component to render session date with duration
  const SessionDate = ({ event, coach }: { event: any; coach: any }) => {
    // Get session duration using start and end in minutes
    const duration = moment(event.end).diff(moment(event.start), 'minutes')
    const startDate = moment(event.start)
      .tz(coach.timezone)
      .format('MMM DD, YYYY h:mm A')
    return (
      <Flex my={2} color="gray">
        <MdDateRange size="18px" />
        <Text fontSize={'sm'} ml={1}>
          {startDate} ({duration}min)
        </Text>
      </Flex>
    )
  }

  console.log('isFetching', isFetching)
  console.log('isSuccess', isSuccess)
  console.log('isError', isError)
  console.log('data', data)

  // Show Spinner component if isLoading
  if (isLoading) {
    return <Spinner />
  }

  if (!events) {
    return null
  }

  return (
    <Flex w="full" flexDirection="column" bg={'white'} borderRadius={10}>
      <Box width={'full'} my={6} pl={6} pr={4}>
        <Flex width={'full'} my={1} justifyContent={'space-between'} mb={5}>
          <Box>
            {/* Add heading with icon on left */}
            <Heading as="h3" size="md" fontWeight={'bold'}>
              {`${t('session_notes')} (shared with users)`}
            </Heading>
          </Box>
        </Flex>
        <Divider />
        {isLoading && (
          <Box textAlign="center" py={10} px={6}>
            <Spinner />
          </Box>
        )}
        {data && data.length > 0 ? (
          <VStack
            divider={<StackDivider borderColor="gray.200" />}
            spacing={4}
            align="stretch"
          >
            {data.map((event: any, index: number) => (
              <Grid
                key={event._id}
                templateColumns="repeat(5, 1fr)"
                gap={4}
                bg={event.note ? 'white' : 'red.100'}
              >
                <GridItem colSpan={7} my={2}>
                  <SessionDate event={event} coach={coach} />
                  {event.note ? (
                    <Text>{parseText(event.note.body.text)}</Text>
                  ) : (
                    <Text>{t('no_notes_yet')}</Text>
                  )}
                </GridItem>
                <GridItem colStart={8} colEnd={11} my={2} alignSelf={'center'}>
                  <Button
                    mr={0}
                    variant="outline"
                    onClick={event.note ? onEdit : onAdd}
                    data-id={event.note ? event.note._id : ''}
                    data-event={event._id}
                    data-idx={index}
                  >
                    {event.note ? 'Edit' : 'Add'}
                  </Button>
                </GridItem>
              </Grid>
            ))}
          </VStack>
        ) : (
          <Box textAlign="center" py={10} px={6}>
            <Text fontSize="18px" mt={3} mb={2}>
              No notes yet.
            </Text>
          </Box>
        )}
      </Box>
      <Modal
        initialFocusRef={initialRef}
        isOpen={isNoteOpen}
        onClose={handleNoteClose}
        size="lg"
      >
        <form onSubmit={onNoteSubmit}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>
              {updating
                ? t('update_session_note')
                : t('create_new_session_note')}
            </ModalHeader>
            <ModalCloseButton />
            <ModalBody pb={6}>
              <FormControl>
                <Checkbox
                  name="userShowedUp"
                  checked={formState.userShowedUp}
                  defaultChecked={formState.userShowedUp}
                  onChange={onShowedUpChange}
                >
                  User showed up
                </Checkbox>
              </FormControl>
              {formState.userShowedUp && (
                <>
                  <FormControl my={5}>
                    <MoodInput
                      name="userMoodScore"
                      label="How was the user's mood?"
                      min={1}
                      max={5}
                      step={1}
                      defaultValue={
                        formState.userMoodScore || DEFAULT_MOOD_SCORE
                      }
                      onChange={onMoodChange}
                    />
                  </FormControl>
                  <FormControl>
                    <Textarea
                      ref={initialRef}
                      value={formState.body}
                      placeholder={`Session notes (shared with client) ...`}
                      name="body"
                      onChange={handleChange}
                      size="lg"
                      height={'full'}
                      rows={8}
                    />
                  </FormControl>
                </>
              )}
            </ModalBody>

            <ModalFooter>
              <Button onClick={handleNoteClose} mr={3}>
                Cancel
              </Button>
              <Button colorScheme="green" type="submit" onClick={onNoteSubmit}>
                {updating ? 'Update' : 'Save'}
              </Button>
            </ModalFooter>
          </ModalContent>
        </form>
      </Modal>
    </Flex>
  )
}

export default SessionNotes
