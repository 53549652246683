import { createContext, FC, useContext, useMemo } from 'react'
import mixpanel, { OverridedMixpanel } from 'mixpanel-browser'

import { MIXPANEL_TOKEN } from '../app/constants'

const MixpanelContext = createContext<OverridedMixpanel>(mixpanel)

mixpanel.init(MIXPANEL_TOKEN, {
  debug: process.env.NODE_ENV === 'development',
})

export const useMixpanel = () => {
  const mixpanel = useContext(MixpanelContext)
  return useMemo(() => {
    return mixpanel
  }, [mixpanel])
}

const MixpanelProvider: FC = ({ children }) => {
  return (
    <MixpanelContext.Provider value={mixpanel}>
      {children}
    </MixpanelContext.Provider>
  )
}

export default MixpanelProvider
