import {
  Button,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Input,
  Select,
  Stack,
  VStack,
  Divider,
  Box,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  MenuDivider,
  useToast,
  useDisclosure,
} from '@chakra-ui/react'
import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import TimezoneSelect from 'react-timezone-select'

import {
  useGetUserMutation,
  useProfileMutation,
  useDeleteUserMutation,
} from '../../app/services/api'
import { updateUser } from '../../features/auth/authSlice'
import { changeLocale, useLocale } from '../../hooks/useLocale'
import { PostProfileRequest, User } from '../../types/api'
import { useMixpanel } from '../../utils/MixpanelContext'
import { useLogout } from '../../hooks/useLogout'

const UserProfile = ({ user }: any) => {
  const { t } = useLocale()
  const [getUser, { data: getUserData }] = useGetUserMutation()
  const [profile] = useProfileMutation()
  const [isButtonDisabled, setIsButtonDisabled] = useState<boolean>(true)
  const [selectedTimezone, setSelectedTimezone] = useState<any>(
    Intl.DateTimeFormat().resolvedOptions().timeZone
  )
  const mixpanel = useMixpanel()
  const toast = useToast()
  const dispatch = useDispatch()
  const [deleteUser] = useDeleteUserMutation()
  const {
    isOpen: isDeleteAlertOpen,
    onOpen: onDeleteAlertOpen,
    onClose: onDeleteAlertClose,
  } = useDisclosure()
  const onLogout = useLogout()

  useEffect(() => {
    mixpanel.track('profile_edit_open')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onDelete = async () => {
    try {
      await deleteUser({}).unwrap()
      toast({
        status: 'success',
        title: t('success'),
        description: t('account_delete_success'),
        isClosable: true,
      })
      mixpanel.track('account_delete_success')
      onLogout()
    } catch (error: any) {
      mixpanel.track('account_delete_fail', { error: JSON.stringify(error) })
      console.error(error)
      toast({
        status: 'error',
        title: t('error'),
        description: error?.data?.message || t('there_was_an_error'),
        isClosable: true,
      })
    }
  }

  const [formState, setFormState] = useState<PostProfileRequest>({
    name: '',
    language: '',
    timezone: '',
  })

  useEffect(() => {
    const loadUser = async () => {
      await getUser(user.id)
    }
    loadUser()
  }, [user, getUser])

  useEffect(() => {
    setIsButtonDisabled(
      !(formState?.name && formState?.language && formState?.timezone)
    )
  }, [formState])

  const onReset = useCallback(() => {
    const changes = {
      name: getUserData?.profile.name,
      timezone: getUserData?.timezone ?? selectedTimezone,
      language: getUserData?.language,
    }
    Object.entries(changes).forEach(([key, value]) =>
      setFormState((prev) => ({ ...prev, [key]: value }))
    )
    if (getUserData?.timezone) setSelectedTimezone(getUserData?.timezone)
  }, [getUserData, selectedTimezone])

  useEffect(() => {
    onReset()
  }, [onReset])

  const handleChange = (event: any) => {
    const {
      target: { name, value },
    } = event
    setFormState((prev) => ({ ...prev, [name]: value }))
  }

  const handleTimezoneChange = (timezone: any) => {
    const name = 'timezone'
    const value = timezone.value
    setFormState((prev) => ({ ...prev, [name]: value }))
  }

  const onSubmit = useCallback(
    async (event) => {
      event.preventDefault()
      try {
        const user = await profile(formState).unwrap()
        toast({
          status: 'success',
          title: t('success'),
          description: t('your_profile_is_updated'),
          isClosable: true,
        })
        changeLocale(user.language)
        dispatch(updateUser(user as User))
        mixpanel.track('profile_edit_success')
      } catch (err: any) {
        toast({
          status: 'error',
          title: t('error'),
          description: t('there_was_an_error'),
          isClosable: true,
        })
        mixpanel.track('profile_edit_fail', { message: err?.data?.message })
      }
    },
    [formState, profile, t, dispatch, mixpanel, toast]
  )

  if (!getUserData) return null

  return (
    <>
      <form onSubmit={onSubmit}>
        <Flex minH={'50vh'} align={'top'} justify={'center'} bg={'gray.50'}>
          <Stack
            spacing={4}
            w={'full'}
            maxW={'md'}
            bg={'white'}
            rounded={'xl'}
            boxShadow={'lg'}
            p={6}
            my={12}
          >
            <Heading lineHeight={1.1} fontSize={{ base: '2xl', sm: '3xl' }}>
              {t('edit_profile')}
            </Heading>
            <FormControl id="name">
              <FormLabel>{t('name')}</FormLabel>
              <Input
                value={formState.name}
                name="name"
                _placeholder={{ color: 'gray.500' }}
                type="text"
                onChange={handleChange}
              />
            </FormControl>
            <FormControl id="timezone">
              <FormLabel>{t('timezone')}</FormLabel>
              <TimezoneSelect
                name="timezone"
                value={formState.timezone}
                onChange={handleTimezoneChange}
              />
            </FormControl>
            <FormControl id="language">
              <FormLabel>{t('language')}</FormLabel>
              <Select
                name="language"
                value={formState.language}
                onChange={handleChange}
              >
                <option value="en">{t('english')}</option>
                <option value="ko">{t('korean')}</option>
              </Select>
            </FormControl>
            <Stack spacing={6} direction={['column', 'row']}>
              <Button colorScheme="red" w="full" onClick={onReset}>
                {t('reset')}
              </Button>
              <Button
                colorScheme="green"
                w="full"
                disabled={isButtonDisabled}
                type="submit"
              >
                {t('save')}
              </Button>
            </Stack>
            <VStack>
              <Divider mb={4} />
              <FormLabel>{t('delete_account_description')}</FormLabel>
              <Box style={{ paddingTop: 10 }}>
                <Button
                  onClick={onDeleteAlertOpen}
                  bg={'primary.500'}
                  color={'white'}
                >
                  {t('delete_account')}
                </Button>
              </Box>
            </VStack>
          </Stack>
        </Flex>
      </form>
      <Modal isCentered isOpen={isDeleteAlertOpen} onClose={onDeleteAlertClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader> {t('account_deletion')} </ModalHeader>
          <MenuDivider />
          <ModalBody>
            <Text fontSize="18px">{t('delete_account_confirmation')}</Text>
          </ModalBody>
          <ModalFooter>
            <Button onClick={onDeleteAlertClose} mr={5}>
              {t('cancel')}
            </Button>
            <Button onClick={onDelete} colorScheme="primary" type="submit">
              {t('confirm_deletion')}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}

export default UserProfile
