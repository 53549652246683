import { useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

import { RootState } from '../../../app/store'
import { useLocale } from '../../../hooks/useLocale'
import { Question } from '../components/Question'
import { itemSelect } from '../onboardingSlice'

const OnboardingQuestionsStep = (props: any) => {
  const { step, onProceedNextStep, onGoBack } = props

  const { t } = useLocale()
  const [errorMessage, setErrorMessage] = useState('')
  const [showError, setShowError] = useState(false)

  const onboardingData = useSelector(
    (state: RootState) => state.onboarding.onboardingData
  )
  const onboardingQuestions = useSelector(
    (state: RootState) => state.onboarding.questions
  )
  const question = onboardingData.questions[step - 1]
  const answer = onboardingQuestions[question.friendlyID]
  const minSelectionLimit = question.minSelectionLimit ?? 1
  const maxSelectionLimit = question.maxSelectionLimit

  const selectedQuestions = useSelector(
    (state: RootState) => state.onboarding.questions
  )
  const selectedCount = selectedQuestions[question.friendlyID]?.length ?? 0

  useEffect(() => {
    if (
      maxSelectionLimit &&
      (selectedCount < minSelectionLimit || selectedCount > maxSelectionLimit)
    ) {
      if (minSelectionLimit < maxSelectionLimit) {
        setErrorMessage(
          t('select_between_x_y_items', {
            min: minSelectionLimit,
            max: maxSelectionLimit,
          })
        )
      } else if (minSelectionLimit === maxSelectionLimit) {
        setErrorMessage(t('select_x_items', { count: minSelectionLimit }))
      }
    } else if (selectedCount < minSelectionLimit) {
      setErrorMessage(
        t('select_at_least_x_items', { count: minSelectionLimit })
      )
    } else {
      setErrorMessage('')
      setShowError(false)
    }
  }, [selectedCount, minSelectionLimit, maxSelectionLimit, t])

  const onProceed = useCallback(() => {
    if (errorMessage) {
      setShowError(true)
    } else {
      onProceedNextStep()
      console.log('onProceedNextStep')
    }
  }, [errorMessage, onProceedNextStep])

  return (
    <Question
      type="onboarding"
      question={question}
      answer={Object.keys(answer || {}).length > 0 ? answer : null}
      itemSelect={itemSelect}
      onProceed={onProceed}
      errorMessage={errorMessage}
      showError={showError}
      setErrorMessage={setErrorMessage}
      setShowError={setShowError}
      onGoBack={onGoBack}
      canGoBack
    />
  )
}

export default OnboardingQuestionsStep
