import { combineReducers, configureStore } from '@reduxjs/toolkit'
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from 'redux-persist'
import storage from 'redux-persist/lib/storage'

import { api } from './services/api'
import authReducer from '../features/auth/authSlice'
import chatReducer from '../features/chat/chatSlice'
import onboardingReducer from '../features/onboarding/onboardingSlice'
import feedbackSurveyReducer from '../features/survey/surveySlice'
import socketSlice from '../features/connection/socketSlice'

const persistConfig = {
  key: 'root',
  version: 1,
  storage,
  blacklist: ['onboarding', 'feedback/survey', api.reducerPath],
}

const combinedReducer = combineReducers({
  [api.reducerPath]: api.reducer,
  auth: authReducer,
  chat: chatReducer,
  onboarding: onboardingReducer,
  socket: socketSlice,
  survey: feedbackSurveyReducer,
})

const rootReducer = (state: any, action: any) => {
  if (action.type === 'auth/logout') {
    state = undefined
  }
  return combinedReducer(state, action)
}

const persistedReducer = persistReducer(persistConfig, rootReducer)

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }).concat(api.middleware),
})

export const persistor = persistStore(store)

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
