import { StepsStyleConfig as Steps } from 'chakra-ui-steps'
import { extendTheme } from '@chakra-ui/react'

export const theme = extendTheme({
  components: {
    Steps,
  },
  styles: {
    global: {
      // styles for the `body`
      body: {
        color: '#292F36',
      },
    },
  },
  colors: {
    primary: {
      50: '#f6aeb0',
      100: '#FFE8CE',
      200: '#FFCB9C',
      300: '#FFA76C',
      400: '#FF8447',
      500: '#FF4B0A',
      600: '#DB3007',
      700: '#B71B05',
      800: '#930B03',
      900: '#7A0103',
    },
    teal: {
      50: '#e6f7ff',
      100: '#F5FDD9',
      200: '#EAFBB4',
      300: '#D6F38D',
      400: '#C1E76E',
      500: '#A3D841',
      600: '#84B92F',
      700: '#679B20',
      800: '#4D7D14',
      900: '#3A670C',
    },
    green: {
      50: '#e6f7ff',
      100: '#F5FDD9',
      200: '#EAFBB4',
      300: '#D6F38D',
      400: '#C1E76E',
      500: '#A3D841',
      600: '#84B92F',
      700: '#679B20',
      800: '#4D7D14',
      900: '#3A670C',
    },
    blue: {
      100: '#D3FCFF',
      200: '#A8F4FF',
      300: '#7CE6FF',
      400: '#5CD5FF',
      500: '#26BAFF',
      600: '#1B91DB',
      700: '#136DB7',
      800: '#0C4E93',
      900: '#07377A',
    },
    red: {
      100: '#FEEAE8',
      200: '#FED2D3',
      300: '#FEBCC3',
      400: '#FDABBB',
      500: '#FC8FB0',
      600: '#D86895',
      700: '#B5487E',
      800: '#922D69',
      900: '#781B5C',
    },
    primary2: {
      50: '#fff1da',
      100: '#D7D9F6',
      200: '#B1B4ED',
      300: '#7D81C9',
      400: '#4E5194',
      500: '#1D1F4E',
      600: '#151643',
      700: '#0E0F38',
      800: '#090A2D',
      900: '#050625',
    },
    primary3: {
      50: '#e7fae5',
      100: '#c6ebc4',
      200: '#a4dda0',
      300: '#81cf7b',
      400: '#5ec157',
      500: '#45a83e',
      600: '#35832f',
      700: '#245d20',
      800: '#133912',
      900: '#001500',
    },
    primary4: {
      50: '#ffe3e7',
      100: '#ffb6bc',
      200: '#f8888f',
      300: '#f35864',
      400: '#ef2a38',
      500: '#d5101e',
      600: '#a70a17',
      700: '#770510',
      800: '#4a0007',
      900: '#200000',
    },
  },
})
