import {
  Badge,
  Box,
  Breadcrumb,
  BreadcrumbItem,
  Button,
  Flex,
  List,
  ListIcon,
  ListItem,
  SimpleGrid,
  Text,
} from '@chakra-ui/react'
import parsePhoneNumber from 'libphonenumber-js'
import React, { useEffect } from 'react'
import { BsChevronRight } from 'react-icons/bs'
import { MdCall, MdChat, MdEmail } from 'react-icons/md'
import { NavLink, useHistory, useParams } from 'react-router-dom'

import { useGetUserMutation } from '../../app/services/api'
import Notes from './Notes'
import OnboardingInfo from './OnboardingInfo'
import SessionNotes from './SessionNotes'

const Customer = ({ user, coach }: any) => {
  const history = useHistory()
  let { userId } = useParams() as any
  const [getUser, { data: getUserData, isLoading: getUserLoading }] =
    useGetUserMutation()

  useEffect(() => {
    const loadUser = async () => {
      await getUser(userId)
    }
    loadUser()
  }, [userId, getUser])

  if (!getUserData || getUserLoading) {
    return null
  }

  const phoneNumber = getUserData?.profile.fullPhone
  let phoneNumberFormatted
  if (phoneNumber) {
    const phone = parsePhoneNumber(phoneNumber)
    phoneNumberFormatted = phone && phone.formatInternational()
  }

  return (
    <Box>
      <Breadcrumb
        spacing="8px"
        separator={<BsChevronRight color="gray.500" />}
        mb={2}
      >
        <BreadcrumbItem>
          <NavLink to="/coach">
            <Text
              fontFamily={'heading'}
              fontWeight={700}
              fontSize={'xl'}
              _hover={{ color: 'primary.400' }}
            >
              Users
            </Text>
          </NavLink>
        </BreadcrumbItem>

        <BreadcrumbItem isCurrentPage>
          <NavLink to={`/coach/customer/${getUserData._id}`}>
            <Text
              fontFamily={'heading'}
              fontWeight={700}
              fontSize={'xl'}
              _hover={{ color: 'primary.400' }}
            >
              {getUserData?.profile.name}
            </Text>
          </NavLink>
        </BreadcrumbItem>
      </Breadcrumb>
      <Flex
        w="full"
        flexDirection="column"
        bg={'white'}
        borderRadius={10}
        mb={2}
      >
        <Flex px={0} overflowY="auto" flexDirection="column" flex={1}>
          {/* Add user name and phone number with action buttons at the right */}
          <Flex
            w="full"
            justifyContent="space-between"
            alignItems="center"
            px={6}
            py={4}
            borderBottom="1px solid"
            borderColor="gray.200"
          >
            <Flex flexDirection="column" alignItems="flex-start">
              <Box ml="3">
                <Text fontWeight="bold" fontSize="xl">
                  {getUserData?.profile.name}
                  <Badge color="blue" fontSize="sm" fontWeight="bold" ml={2}>
                    {getUserData?.company.name}
                  </Badge>
                </Text>
                <List spacing={0} mt={1}>
                  <ListItem fontSize="sm" color="gray.500">
                    <ListIcon as={MdEmail} />
                    {getUserData?.email}
                  </ListItem>
                  {phoneNumberFormatted && (
                    <ListItem fontSize="sm" color="gray.500">
                      <ListIcon as={MdCall} />
                      {phoneNumberFormatted}
                    </ListItem>
                  )}
                </List>
              </Box>
            </Flex>
            <Flex
              flexDirection="column"
              alignItems="flex-end"
              justifyContent={'flex-end'}
            >
              <Box>
                <Button
                  leftIcon={<MdChat />}
                  onClick={() => {
                    history.push(`/coach/chat/${getUserData._id}`)
                  }}
                  flex={1}
                  fontWeight={400}
                  fontSize={'md'}
                  rounded={'full'}
                  bg={'primary2.500'}
                  color={'white'}
                  _hover={{
                    bg: 'white',
                    color: 'primary2.500',
                    borderColor: 'primary2.500',
                  }}
                >
                  Chat
                </Button>
              </Box>
            </Flex>
          </Flex>
        </Flex>
      </Flex>
      <OnboardingInfo coach={coach} userId={userId} />
      <SimpleGrid columns={[1, 1, 2]} spacingX="15px" spacingY="10px">
        <SessionNotes coach={coach} userId={userId} />
        <Notes coach={coach} userId={userId} />
      </SimpleGrid>
    </Box>
  )
}

export default Customer
